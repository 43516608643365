import { CompanyFields } from "./CompanyFields";


export const getFields =(keys =[]) =>{
    let fields = [];
    keys.forEach((key)=>{
        const field = CompanyFields[key];
        //avoid adding duplicate data
        if(!fields.includes(field)) {
            fields.push(field);
        }        
    });
    return fields;
}