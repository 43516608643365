import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Popover,
  Typography,
} from "@mui/material";
import moment from "moment";

export default function TaskPopover(props) {
  const { el, handleClose, task } = props;
  const popOverOpen = Boolean(el);
  
  const valueFormatter = (p) => {
    if (p.value !== null) {
      return `${Math.floor(p.value / 3600)}:${Math.floor((p.value % 3600) / 60)}`;
    } else {
      return `0`;
    }
  };
  
  const formattedEstimatedHours = valueFormatter({ value: task.estimatedHours });
  

  return (
    <Popover
      id={"ae-roles-popover"}
      open={popOverOpen}
      anchorEl={el}
      onClose={handleClose}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Card sx={{ width: 400 }}>
        <CardHeader
          title={"Task Details"}
          sx={{
            textAlign: "center",
            color: "white",
            backgroundColor: "#d5ecec",
          }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4} sx={{ justifyContent: "center" }}>
              <Typography variant="body">Task Name </Typography>
            </Grid>
            <Grid item xs={1} sx={{ justifyContent: "center" }}>
              <Typography>:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>{task.taskName}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ justifyContent: "center" }}>
              StartDate
            </Grid>
            <Grid item xs={1} sx={{ justifyContent: "center" }}>
              <Typography>:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>
                {moment(task.startDate, "YYYY,MM,DD").format("DD MMMM YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ justifyContent: "center" }}>
              EndDate
            </Grid>
            <Grid item xs={1} sx={{ justifyContent: "center" }}>
              <Typography>:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>
                {moment(task.endDate, "YYYY,MM,DD").format("DD MMMM YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ justifyContent: "center" }}>
              Category
            </Grid>
            <Grid item xs={1} sx={{ justifyContent: "center" }}>
              <Typography>:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>{task.category}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ justifyContent: "center" }}>
              Description
            </Grid>
            <Grid item xs={1} sx={{ justifyContent: "center" }}>
              <Typography>:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>{task.description}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ justifyContent: "center" }}>
              Estimated Hours
            </Grid>
            <Grid item xs={1} sx={{ justifyContent: "center" }}>
              <Typography>:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>{formattedEstimatedHours}:00 hrs</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Popover>
  );
}
