import { MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import GenericForm from "../../../GenericForm";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import {
  isValidMobileNo,
  isValidTitle,
} from "../../edvenswa.emportal.common/validation/Validation";
import {
  COMPANY_DEFAULT_IMAGE,
  COMPANY_NAME_FIELD_ID,
  COMPANY_PHONE_FIELD_ID,
  COMPANY_STATUS_FIELD_ID,
  COMPANY_WEBSITE_FIELD_ID,
} from "../constants/Constants";
import { getFields } from "../fields/getFields";

const AddTenant = (props) => {
  const {
    setIsDrawerOpen,
    setFilteredElements,
    setElements,
    tenant,
    onSuccess,
    onError,
  } = props;
  const [formData, setFormData] = useState({});
  const [companyStatus, setCompanyStatus] = useState([]);
  const [image, setImage] = useState(null);
  const fields = [
    COMPANY_NAME_FIELD_ID,
    COMPANY_STATUS_FIELD_ID,
    COMPANY_PHONE_FIELD_ID,
    COMPANY_WEBSITE_FIELD_ID,
  ];
  const formFields = getFields(fields);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (companyStatus.length === 0) {
      axiosInstance.get("/project/status").then((res) => {
        setCompanyStatus(res.data);
      });
    }
  }, [companyStatus]);

  const handleChange = (field_name, value) => {
    switch (field_name) {
      case COMPANY_NAME_FIELD_ID: {
        if (!isValidTitle(value) && value !== "") {
          setErrors({
            [field_name]: {
              message:
                "Title should be at least 2 characters and first letter should be capital",
            },
          });
        } else if (
          tenant.some((data) => data["name"].includes(value) & (value !== ""))
        ) {
          setErrors({
            ...errors,
            [field_name]: {
              message: "Tenant with this titlename already existed",
            },
          });
        } else {
          setErrors((prevErrors) => {
            delete prevErrors[field_name];
            return { ...prevErrors };
          });
          setFormData({ ...formData, [field_name]: value });
        }
        break;
      }
      case COMPANY_STATUS_FIELD_ID: {
        setFormData({ ...formData, [field_name]: value });
        break;
      }
      case COMPANY_PHONE_FIELD_ID: {
        if (!isValidMobileNo(value) && value !== "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field_name]: { message: "Invalid Mobile Number" },
          }));
          setFormData({ ...formData, [field_name]: value });
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[field_name];
            return updatedErrors;
          });
          setFormData({ ...formData, [field_name]: value });
        }
        break;
      }
      case COMPANY_WEBSITE_FIELD_ID: {
        setFormData({ ...formData, [field_name]: value });
        break;
      }

      default: {
        console.error("Invalid field name: ", field_name);
      }
    }
  };

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const updatedFormData = {
      ...formData,
      logo: image ? image : COMPANY_DEFAULT_IMAGE,
    };
    if (Object.keys(errors).length === 0) {
      setLoading(!loading);
      axiosInstance
        .post("/tenant/register", updatedFormData)
        .then((response) => {
          setLoading(false);
          setImage("");
          setFormData({});
          localStorage.setItem("companies", JSON.stringify(response.data));
          setFilteredElements((prevElements) => [
            ...prevElements,
            updatedFormData,
          ]);
          setElements((prevElements) => [...prevElements, updatedFormData]);
          setIsDrawerOpen(false);
          onSuccess("Company Registered Succesfully");
        })
        .catch((error) => {
          setLoading(false);
          onError(error);
        });
    }
  };

  const renderCustomInput = (field, key) => {
    if (field.type !== "radio") {
      return (
        <>
          <TextField
            {...field}
            size="small"
            fullWidth
            required
            key={key}
            label={field.label}
            error={errors[field.name] ? true : false}
            helperText={errors?.[field.name]?.message}
            InputLabelProps={{ style: { font: 'small-caption',fontFamily:"Lucida Grande" } }}
                    inputProps={{ style: { font: 'small-caption',fontFamily:"Lucida Grande" } }}
            onInput={(e) => {
              field.name === COMPANY_PHONE_FIELD_ID
                ? (e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10))
                : console.log("null");
            }}
            defaultValue={
              formData && formData[field.name] ? formData[field.name] : ""
            }
            onChange={(event) => handleChange(field.name, event.target.value)}
          >
            {field.select && field.id === COMPANY_STATUS_FIELD_ID ? (
              companyStatus.map((status, idx1) => (
                <MenuItem sx={{ fontSize: "10px", fontFamily: "Lucida grande" }}   value={status} key={idx1}>
                  {status}
                </MenuItem>
              ))
            ) : (
              <React.Fragment />
            )}
          </TextField>
        </>
      );
    } else {
      return <React.Fragment />;
    }
  };

  return (
    <GenericForm
      formData={formData}
      formFields={formFields}
      errors={errors}
      handleChange={handleChange}
      handleSubmit={handleSave}
      renderCustomInput={renderCustomInput}
      setIsDrawerOpen={setIsDrawerOpen}
      image={image}
      setImage={setImage}
      setLoading={setLoading}
      loading={loading}
      onError={onError}
    />
  );
};

export default AddTenant;
