import { Avatar, Tooltip, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import saveAs from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { utils, write } from "xlsx";
import GenericTable from "../../../GenericTable";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import { PROJECT_DEFAULT_IMAGE } from "../constants/Constants";
import AddProject from "./AddProject";

const Projects = (props) => {
  const { user } = props;
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [projectTitle, setProjectTitle] = useState("");
  const [page, setPage] = useState(1);
  const pageSize = "";
  const totalPages = 0;
  const [searchTerm, setSearchTerm] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [sortBy, setSortBy] = useState("ACTIVE");
  const [associates, setAssociates] = useState([]);
  const [tenantId, setTenantId] = useState(user.tenantId);
  const type = "Projects";
  const displayAdd = true;
  const header = [
    {
      id: "title",
      label: "Project Title",
      image: "logo",
      src: PROJECT_DEFAULT_IMAGE,
      type: "text",
    },
    {
      id: "projectCategory",
      label: "Category",
      type: "text",
    },
    {
      id: "projectManager",
      label: "Manager",
      type: "text",
    },
    {
      id: "startDate",
      label: "Start Date",
      type: "date",
    },
    {
      id: "endDate",
      label: "End Date",
      type: "date",
    },
    {
      id: "targetMargin",
      label: "Value",
      type: "text",
    },
    {
      id: "status",
      label: "status",
      type: "text",
    },
  ];


  const CustomNameComponent = (row) => {
    const { data, value } = row;
    const { logo, projectManager } = data;

    return (
      <Tooltip title="Project Overview">
        <Link
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            color: projectManager === null ? "red" : "rgb(25, 118, 210)",
          }}
          underline="hover"
          onClick={() => {
            navigate("/home/projects/details", {
              state: {
                project: data,
                tenantId: tenantId,
                associates: associates,
                projects: projects,
              },
            });
          }}
        >
          <Avatar src={logo} alt="Project Logo" />
          <Typography variant="body1" sx={{ marginLeft: "8px" }} color="currentcolor">
            {value}
          </Typography>
        </Link>
      </Tooltip>
    );
  };

  const CustomStatusComponent = (row) => {
    if (row?.data) {
      const project = row?.data;
      return (
        <Typography
          sx={{ mt: "8px" }}
          color={project?.status === "ACTIVE" ? "teal" : "gray"}
        >
          {project?.status}
        </Typography>
      );
    }
  };

  const CustomManagerComponent = (row) => {
    if (row?.data) {
      const project = row?.data;
      return (
        <Typography
          sx={{ mt: "8px" }}
          color={project?.projectManager?.fullname ? "teal" : "red"}
        >
          {project?.projectManager
            ? project?.projectManager?.fullname
            : "Not assigned yet"}
        </Typography>
      );
    }
  };

  const colDefs = [
    // {
    //   field: "logo",
    //   type: "text",
    //   cellRenderer: CustomHeaderComponent,
    //   headerName: "Logo",
    // },
    {
      field: "title",
      type: "text",
      headerName: "Project Title",
      cellRenderer: CustomNameComponent,
      width: "280px",
    },
    {
      field: "projectCategory",
      headerName: "Category",
      filter: true,
      width: "220px",
    },
    {
      field: "projectManager",
      headerName: "Manager",
      filter: true,
      cellRenderer: CustomManagerComponent,
      width: "220px",
    },
    {
      field: "startDate",
      headerName: "Start Date",
      filter: true,
      valueFormatter: (startDate) =>
        moment(startDate.value, "YYYY,MM,DD").format("DD-MMM-YYYY"),
      width: "170px",
    },
    // {
    //   field: "endDate",
    //   headerName: "End Date",
    //   filter: true,
    //   valueFormatter: (endDate) =>
    //     moment(endDate.value, "YYYY,MM,DD").format("DD-MMM-YYYY"),
    //   width: "170px",
    // },
    // {
    //   field: "targetMargin",
    //   headerName: "Value",
    //   filter: true,
    //   width: "150px",
    // },
    {
      field: "status",
      headerName: "Status",
      filter: true,
      cellRenderer: CustomStatusComponent,
      width: "180px",
    },
  ];

  const header1 = [
    { id: "title", label: "Project Title", type: "text" },
    { id: "projectCategory", label: "Category", type: "text" },
    { id: "projectManager", label: "Manager", type: "text" },
    { id: "startDate", label: "Start Date", type: "date" },
    // { id: "endDate", label: "End Date", type: "date" },
    // { id: "targetMargin", label: "Value", type: "number" },
    { id: "status", label: "Status", type: "text" },
  ];

  const handleDownloadReport = () => {
    const pdfWidth = 210;
    const pdfHeight = 250;
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: [pdfWidth, pdfHeight],
    });
    const logoUrl =
      "https://tse3.mm.bing.net/th?id=OIP.xrnfPsu-nIEVf0HzuyWt3AAAAA&pid=Api&P=0&h=180";
    const centerX = pdf.internal.pageSize.width / 2;
    const imageWidth = 20;
    const imageHeight = 20;
    const imageY = 2;
    pdf.addImage(
      logoUrl,
      centerX - imageWidth / 2,
      imageY,
      imageWidth,
      imageHeight
    );

    const formattedData = projects.map((project) => {
      return {
        "Project Title": project.title,
        "Category": project.projectCategory,
        "Manager": project?.projectManager?.fullname,
        "Start Date": project.startDate
          ? moment(project.startDate, "YYYY,MM,DD").format("DD-MMM-yyyy")
          : " ",
        "End Date": project.endDate
          ? moment(project.endDate, "YYYY,MM,DD").format("DD-MMM-yyyy")
          : " ",
        "Value": project.targetMargin,
        "Status": project.status,
      };
    });

    const userData = formattedData.map((val) =>
      header1.map((header) => val[header.label])
    );
    const tableStartY = imageY + imageHeight + 20;

    pdf.autoTable({
      head: [header1.map((h) => h.label)],
      body: userData,
      startY: tableStartY,
      headStyles: {
        fillColor: "#008080",
        textColor: "#ffffff",
        fontStyle: "bold",
      },
      didDrawPage: function (data) {
        if (data.pageNumber === 1) {
          pdf.setFont("Times New Roman, Times, serif");
          pdf.text(
            `Dear Admin, Following is the Project Details Report`,
            12,
            tableStartY - 6
          );
          pdf.setFontSize(13);
        }
      },
      didParseCell: function (data) {
        data.cell.styles.lineWidth = 0.1;
        data.cell.styles.lineColor = [0, 0, 0];
      },
    });
    pdf.save(`All Projects Report.pdf`);
  };

  const handleDownloadReportExcel = () => {
    const fileName = "All_Projects_Report.xlsx";
    const formattedData = projects.map((project) => {
      return {
        "Project Title": project.title,
        Category: project.projectCategory,
        Manager: project.projectManager,
        "Start Date": project.startDate
          ? moment(project.startDate, "YYYY,MM,DD").format("DD-MMM-yyyy")
          : " ",
        "End Date": project.endDate
          ? moment(project.endDate, "YYYY,MM,DD").format("DD-MMM-yyyy")
          : " ",
        Value: project.targetMargin,
        Status: project.status,
      };
    });

    const header1 = [
      { label: "Project Title", key: "Project Title" },
      { label: "Category", key: "Category" },
      { label: "Manager", key: "Manager" },
      { label: "Start Date", key: "Start Date" },
      { label: "End Date", key: "End Date" },
      { label: "Value", key: "Value" },
      { label: "Status", key: "Status" },
    ];

    const worksheet = utils.json_to_sheet(formattedData, {
      header: header1.map((h) => h.label),
    });
    const workbook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer = write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName);
  };

  useEffect(() => {
    setLoading(true);
    if (searchTerm.length === 0 && projects.length === 0) {
      axiosInstance
        .get(`/project/projectLists?sortBy=${sortBy}&tenantId=${tenantId}`)
        .then((res) => {
          setProjects(res.data);
          setFilteredProjects(res.data);
          setLoading(false);
        });
    } else {
      const searchedProjects = projects.filter((data) =>
        data["title"].toLowerCase().includes(searchTerm)
      );
      setFilteredProjects(searchedProjects);
      setLoading(false);
    }
    if (associates.length === 0) {
      axiosInstance
        .get(
          `/user/associatesListbyTenant?tenantId=${tenantId}&type=projectOverview`
        )
        .then((res) => {
          setAssociates(res.data);
        });
    }
  }, [page, pageSize, sortBy, searchTerm, projectTitle, tenantId]);

  const toggleData = (item) => {
    setProjectTitle(item.title);
  };

  const handleDelete = (selectedProjectForDeletion) => {
    axiosInstance
      .delete(`/project/delete?title=${selectedProjectForDeletion.title}`)
      .then(() => {
        const updatedProjects = projects.filter(
          (project) => project.title !== selectedProjectForDeletion.title
        );
        setProjects(updatedProjects);
        setFilteredProjects(updatedProjects);
      })
      .catch((error) => {
        console.error("Error deleting project:", error);
      });
  };

  return (
    <GenericTable
      Companies={props.Companies}
      setCompanies={props.setCompanies}
      sortBy={sortBy}
      setSortBy={setSortBy}
      colDefs={colDefs}
      type={type}
      elements={projects}
      setProjects={setProjects}
      filteredElements={filteredProjects}
      setFilteredElements={setFilteredProjects}
      fields={header}
      setTenantId={setTenantId}
      setSearchTerm={setSearchTerm}
      searchTerm={searchTerm}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
      toggleData={toggleData}
      snackbarOpen={snackbarOpen}
      setSnackbarOpen={setSnackbarOpen}
      handleDownloadReport={handleDownloadReport}
      handleDownloadReportExcel={handleDownloadReportExcel}
      errorMessage={errorMessage}
      loading={loading}
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      drawerContent={
        <AddProject
          tenantId={tenantId}
          companies={props.Companies}
          projects={projects}
          onError={props.onError}
          onSuccess={props.onSuccess}
          setIsDrawerOpen={setIsDrawerOpen}
          setFilteredElements={setFilteredProjects}
          setElements={setProjects}
        />
      }
      handleDeleteItem={handleDelete}
      displayAdd={displayAdd}
      displayHeader={displayAdd}
    />
  );
};

export default Projects;
