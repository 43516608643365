import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../../../guards/PrivateRoute";
import Layout from "../../edvenswa.emportal.layout/Layout";
import { TIMECARDS_PATH, TIMESHEET_PATH } from "../constants/Constants";
import TimesheetComponent from "../pages/Timesheet";
import TaskComponent from "../pages/Task";
import TimeCardComponent from "../pages/TimeCard";

// const TimesheetComponent = React.lazy(() => import("../pages/Timesheet"));
// const TaskComponent = React.lazy(() => import("../pages/Task"));
// const TimeCardComponent = React.lazy(() => import("../pages/TimeCard"));
const TaskUpdate = React.lazy(() => import("../pages/TaskOverview"));

export const TIMESHEET_ROUTES = [
  <Route
    path="/home/task"
    element={
      <PrivateRoute>
        <Layout component={TaskComponent} path="/home/task" title="Tasks"></Layout>
      </PrivateRoute>
    }
    key={"TASK_ROUTE"}
  ></Route>,
  <Route
    path={TIMECARDS_PATH}
    element={
      <PrivateRoute>
        <Layout component={TimeCardComponent} path={TIMECARDS_PATH} title="Timecards"></Layout>
      </PrivateRoute>
    }
    key={"TIMECARD_ROUTE"}
  ></Route>,
  <Route
    path={TIMESHEET_PATH}
    element={
      <PrivateRoute>
        <Layout component={TimesheetComponent} path={TIMESHEET_PATH} title="Timesheet"></Layout>
      </PrivateRoute>
    }
    key={"TIMESHEET_ROUTE"}
  ></Route>,
  <Route
    path="/home/task/taskUpdate"
    element={
      <PrivateRoute>
        <Layout component={TaskUpdate} path="/home/task/taskUpdate" title="Edit Task"></Layout>
      </PrivateRoute>
    }
  ></Route>,
];
