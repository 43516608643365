import React, { useEffect, useState } from "react";
import GenericTable from "../../../GenericTable";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import { doGetTenants } from "../actions/Action";
import AddTenant from "./AddTenant";
import { useNavigate } from "react-router-dom";
import { COMPANY_DEFAULT_IMAGE } from "../constants/Constants";
import { Avatar, Chip, Link, Tooltip, Typography } from "@mui/material";
import { AUTH_SECURE_USER_DETAILS_LS_LEY } from "../../edvenswa.emportal.auth/constants/Constants";

const Tenant = (props) => {
  const { setCompanies, onError, onSuccess, user } = props;
  const navigate = useNavigate();
  const [tenant, setTenant] = useState([]);
  const [filteredTenant, setFilteredTenant] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const totalPages  = 0;
  const [searchTerm, setSearchTerm] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState("ACTIVE");
  const type = "Companies";
  const displayAdd = true;
  const header = [
    {
      id: "name",
      label: "name",
      image: "logo",
      type: "text",
      src: COMPANY_DEFAULT_IMAGE,
    },
    {
      id: "website",
      label: "website",
      type: "text",
    },
    {
      id: "phone",
      label: "phone",
      type: "text",
    },
  ];

  const CustomButtonComponent = (props) => {
    return (
      user.tenant !== props.data.name && (
        <Chip
          sx={{
            fontSize: 17,
            color: "white",
            fontFamily: "Lucida Grande",
            backgroundColor:
              props.data.status === "ACTIVE" ? "#029a9c" : "#e53935",
          }}
          label={
            <Typography variant="caption" style={{ color: "white", fontFamily: "Lucida Grande" }}>
              {"Set as Default"}
            </Typography>
          }
          variant="contained"
          size="small"
          onClick={() => {
            setLoading(true);
            if (user.tenant === props.data.name) {
              setSnackbarOpen(true);
              onError({
                response: { data: "The Company is Already Default Company" },
              });
              setLoading(false);
            } else if (
              props.data.status === "ACTIVE" ||
              props.data.status === "All"
            ) {
              axiosInstance
                .get(`/tenant/default?tenantId=${props.data.id}`)
                .then((res) => {
                  user["tenantId"] = props.data.id;
                  user["tenant"] = props.data.name;
                  sessionStorage.setItem(
                    AUTH_SECURE_USER_DETAILS_LS_LEY,
                    JSON.stringify(user)
                  );
                  setLoading(false);
                  onSuccess("Default Company Changed successfully");
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              setSnackbarOpen(true);
              onError({
                response: { data: "Can not make Inactive company as Default" },
              });
              setLoading(false);
            }
          }}
        ></Chip>
      )
    );
  };

  const CustomNameComponent = (props) => {
    const { name, logo } = props.data;
    return (
      <Tooltip title="Tenant Overview">
        <Link
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            color: user.tenant === props.data.name ? "green" : "",
          }}
          underline="hover"
          onClick={() => {
            navigate("/home/company/details", {
              state: { tenant: props.data },
            });
          }}
        >
          <Avatar src={props.data.logo} />
          <Typography
            variant="body2"
            sx={{ marginLeft: "8px" }}
            color="currentcolor"
          >
            {name}
          </Typography>
        </Link>
      </Tooltip>
    );
  };

  const colDefs = [
    {
      field: "Name",
      headerName: "Company Name",
      type: "text",
      flex: 1,
      cellRenderer: CustomNameComponent,
    },
    { field: "website", headerName: "Website", type: "text", flex: 1 },

    { field: "phone", headerName: "Contact", type: "number", flex: 1 },
    {
      field: "Action",
      headerName: "Action",
      filter: true,
      cellRenderer: CustomButtonComponent,
      flex: 1,
    },
  ];

  useEffect(() => {
    setLoading(true);
    // axiosInstance
    //   .get(`/tenant/count?searchTerm=${searchTerm}&sortBy=${sortBy}`)
    //   .then((res) => {
    //     const totalCount = res.data;
    //     const totalPages = Math.ceil(totalCount / pageSize);
    //     setTotalPages(totalPages);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching total count:", error);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });

    // Fetch tenants based on search term if present
    if (searchTerm.length === 0) {
      doGetTenants(
        page,
        sortBy,
        pageSize,
        handleSuccess,
        handleFailure,
        handleLoading
      );
    } else {
      const searchedTenants = tenant.filter((data) =>
        data["name"].toLowerCase().includes(searchTerm)
      );
      setFilteredTenant(searchedTenants);
      setLoading(false);
    }

    // Fetch selected company based on companyTitle
    // if (companyTitle.length > 0) {
    //   axiosInstance
    //     .get(`/tenant/title?title=${companyTitle}`)
    //     .then((res) => {
    //       setSelectedCompany(res.data);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching selected company:", error);
    //     });
    // }
  }, [page, pageSize, sortBy, searchTerm]);



  const handleSuccess = (data) => {
    setTenant(data);
    setFilteredTenant(data);
    setLoading(false)
  };

  const handleFailure = (error) => {
    setSnackbarOpen(!snackbarOpen);
    setErrorMessage(error);
  };

  const handleLoading = (state) => { };


  return (
    <GenericTable
      sortBy={sortBy}
      setSortBy={setSortBy}
      colDefs={colDefs}
      type={type}
      elements={tenant}
      filteredElements={filteredTenant}
      setFilteredElements={setFilteredTenant}
      fields={header}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
      snackbarOpen={snackbarOpen}
      setSnackbarOpen={setSnackbarOpen}
      errorMessage={errorMessage}
      loading={loading}
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      drawerContent={
        <AddTenant
          onError={props.onError}
          onSuccess={props.onSuccess}
          setIsDrawerOpen={setIsDrawerOpen}
          setFilteredElements={setFilteredTenant}
          setCompanies={setCompanies}
          setElements={setTenant}
          tenant={tenant}
        />
      }
      displayAdd={displayAdd}
      displayHeader={displayAdd}
    // displayPagination={displayAdd}
    />
  );
};

export default Tenant;
