import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import {
  isValidDescription,
  isValidNumber,
  isValidTitle,
} from "../../edvenswa.emportal.common/validation/Validation";
import {
  PROJECT_CATEGORY_FIELD_ID,
  PROJECT_CREATED_BY_FIELD_ID,
  PROJECT_CREATED_ON_FIELD_ID,
  PROJECT_DESCRIPTION_FIELD_ID,
  PROJECT_END_DATE_FIELD_ID,
  PROJECT_ESTIMATED_HOURS_FIELD_ID,
  PROJECT_MANAGER_FIELD_ID,
  PROJECT_START_DATE_FIELD_ID,
  PROJECT_STATUS_FIELD_ID,
  PROJECT_TITLE_FIELD_ID,
  PROJECT_VALUE_FIELD_ID,
} from "../constants/Constants";
import { getProjectFields } from "../fields/GetFields";

const ProjectOverview = (props) => {
  const { errors, setErrors, formData, setFormData, associates } = props;
  const location = useLocation();
  const [projectStatus, setProjectStatus] = useState([]);
  const [projectCategories, setProjectCategories] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState(location?.state?.project || {});
  const { onError, onSuccess } = props;

  useEffect(() => {
    if (projectStatus.length === 0) {
      axiosInstance.get("/project/status").then((res) => {
        setProjectStatus(res.data);
      });
    }
    if (projectCategories.length === 0) {
      axiosInstance.get("/project/category").then((res) => {
        setProjectCategories(res.data);
      });
    }
  }, [projectStatus, editedData]);

  useEffect(() => {
    axiosInstance
      .get(`/project/title?title=${location.state?.project?.title}`)
      .then((projectData) => {
        setEditedData(projectData.data);
      })
      .catch((error) => {
        console.error("Error fetching project data:", error);
      });
  }, []);

  const fields = [
    PROJECT_TITLE_FIELD_ID,
    // PROJECT_MANAGER_FIELD_ID,
    PROJECT_STATUS_FIELD_ID,
    PROJECT_CATEGORY_FIELD_ID,
    PROJECT_START_DATE_FIELD_ID,
    PROJECT_END_DATE_FIELD_ID,
    PROJECT_ESTIMATED_HOURS_FIELD_ID,
    PROJECT_VALUE_FIELD_ID,
    PROJECT_CREATED_BY_FIELD_ID,
    PROJECT_CREATED_ON_FIELD_ID,
    PROJECT_DESCRIPTION_FIELD_ID,
  ];
  const formFields = getProjectFields(fields);

  const handleEditClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEditMode(!editMode);
  };

  const handleUpdateClick = (e) => {
    e.preventDefault();

    if (Object.keys(errors).length === 0) {
      axiosInstance
        .post("/user/updateProject", editedData)
        .then((response) => {
          setEditMode(false);
          setFormData(response.data);
          onSuccess("Project updated successfully");
        })
        .catch((error) => {
          onError(error);
        });
    } else {
      setEditMode(true);
      onError({ response: { data: "Please fix the errors." } });
    }

  };

  const handleCancelClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEditMode(false);
    setEditedData(formData);
    setErrors({});
  };

  const handleChange = (field_name, value) => {
    switch (field_name) {
      case PROJECT_TITLE_FIELD_ID: {
        if (!isValidTitle(value)) {
          setErrors({
            [field_name]: {
              message:
                "Title must consist of at least 2 characters and first letter should be capital.",
            },
          });
          setEditedData({ ...editedData, [field_name]: value });
        } else {
          setErrors((prevErrors) => {
            delete prevErrors[field_name];
            return { ...prevErrors };
          });
          setEditedData({ ...editedData, [field_name]: value });
        }
        break;
      }
      case PROJECT_DESCRIPTION_FIELD_ID: {
        if (!isValidDescription(value) & (value !== "")) {
          setErrors({
            [field_name]: {
              message:
                "The Project Description must consist of at least ten characters and first letter should be capital.",
            },
          });
          setEditedData({ ...editedData, [field_name]: value });
        } else {
          setErrors((prevErrors) => {
            delete prevErrors[field_name];
            return { ...prevErrors };
          });
          setEditedData({ ...editedData, [field_name]: value });
        }
        break;
      }
      case PROJECT_STATUS_FIELD_ID: {
        let count = 0;
        formData.users.forEach((data) => {
          if (data.status === "INACTIVE") {
            count++;
          }
        });
        if (value === "INACTIVE" && count !== formData.users.length) {
          console.log(count, formData.users.length);
          setErrors({
            [field_name]: {
              message: `${formData.users.length
                } Associate in this Project are still active.`,
            },
          });
        } else {
          setErrors((prevErrors) => {
            delete prevErrors[field_name];
            return { ...prevErrors };
          });
          setEditedData({ ...editedData, [field_name]: value });
        }
        break;
      }
      case PROJECT_CATEGORY_FIELD_ID: {
        setEditedData({ ...editedData, [field_name]: value });
        break;
      }
      case PROJECT_START_DATE_FIELD_ID: {
        const startDate = value;
        const endDate = formData[PROJECT_END_DATE_FIELD_ID];

        if (!startDate) {
          // Show error if startDate is empty
          setErrors({
            ...errors,
            [PROJECT_START_DATE_FIELD_ID]: {
              message: "Start Date is required.",
            },
          });
        } else if (endDate && new Date(endDate) < new Date(startDate)) {
          // Show error if endDate is before startDate
          setErrors({
            ...errors,
            [PROJECT_START_DATE_FIELD_ID]: {
              message: "Start Date must be before End Date.",
            },
            [PROJECT_END_DATE_FIELD_ID]: {
              message: "End Date must be after Start Date.",
            },
          });
        } else {
          // Clear specific errors if the dates are valid
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[PROJECT_START_DATE_FIELD_ID];
            delete updatedErrors[PROJECT_END_DATE_FIELD_ID];
            return updatedErrors;
          });
        }

        setEditedData({ ...editedData, [field_name]: value });
        break;
      }

      case PROJECT_END_DATE_FIELD_ID: {
        const startDate = formData[PROJECT_START_DATE_FIELD_ID];
        const endDate = value;

        if (startDate && new Date(endDate) < new Date(startDate)) {
          // Show error if endDate is before startDate
          setErrors({
            ...errors,
            [PROJECT_START_DATE_FIELD_ID]: {
              message: "Start Date must be before End Date.",
            },
            [PROJECT_END_DATE_FIELD_ID]: {
              message: "End Date must be after Start Date.",
            },
          });
        } else {
          // Clear specific errors if the dates are valid
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[PROJECT_START_DATE_FIELD_ID];
            delete updatedErrors[PROJECT_END_DATE_FIELD_ID];
            return updatedErrors;
          });
        }

        setEditedData({ ...editedData, [field_name]: value });
        break;
      }
      case PROJECT_VALUE_FIELD_ID: {
        if (!isValidNumber(value)) {
          setErrors({
            [field_name]: { message: "Value should be in Numbers" },
          });
        } else {
          setErrors((prevErrors) => {
            delete prevErrors[field_name];
            return { ...prevErrors };
          });
        }
        setEditedData({ ...editedData, [field_name]: value });
        break;
      }
      case PROJECT_MANAGER_FIELD_ID: {
        console.log(value, associates.find((data) => data.username === value));
        setEditedData({ ...editedData, [field_name]: associates.find((data) => data.username === value) });
        break;
      }
      case PROJECT_ESTIMATED_HOURS_FIELD_ID: {
        if (!isValidNumber(value)) {
          setErrors({
            [field_name]: { message: "Value should be in Numbers" },
          });
        } else {
          setErrors((prevErrors) => {
            delete prevErrors[field_name];
            return { ...prevErrors };
          });
        }
        setEditedData({ ...editedData, [field_name]: value });
        break;
      }
      case PROJECT_CREATED_BY_FIELD_ID: {
        setEditedData({ ...editedData, [field_name]: value });
        break;
      }
      case PROJECT_CREATED_ON_FIELD_ID: {
        setEditedData({ ...editedData, [field_name]: value });
        break;
      }
      default: {
        console.error("Invalid field name: ", field_name);
      }
    }
  };

  return (
    <Box
      component="form"
      onSubmit={editMode ? handleUpdateClick : () => { }}
    >
      <Grid
        item
        sx={{
          justifyContent: "flex-end",
          display: "flex",
          marginBottom: "1rem",
        }}
      >
        <Grid item>
          <Grid style={{ display: "flex", gap: "10px" }}>
            <Button
              size="small"
              variant="contained"
              type={editMode ? "submit" : ""}
              disabled={Object.keys(errors).length !== 0}
              onClick={editMode ? () => { } : handleEditClick}
            >
              {editMode ? "Save" : "Edit"}
            </Button>
            {editMode ? (
              <Button
                size="small"
                variant="contained"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {formFields.map((field, index) => {
          return (
            <Fragment key={index}>
              {field && field.type !== "radio" ? (
                <Grid item xs={field?.sx ? field?.sx : 6}>
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label={field.label}
                    // disabled={field.name === PROJECT_MANAGER_FIELD_ID ? true : false}
                    error={errors[field.name] ? true : false}
                    helperText={errors?.[field.name]?.message}
                    InputLabelProps={{
                      style: { font: "small-caption" ,fontFamily:"Lucida Grande"},
                      shrink: true,
                    }}
                    InputProps={{ style: { font: "small-caption",fontFamily:"Lucida Grande" } }}
                    inputProps={{
                      readOnly:
                        field.name === PROJECT_CREATED_BY_FIELD_ID ||
                          field.name === PROJECT_CREATED_ON_FIELD_ID
                          ? true
                          : !editMode,
                      style: { pointerEvents: "none" },
                    }}
                    value={
                      editedData
                        ? field.name === PROJECT_START_DATE_FIELD_ID
                          ? moment(editedData[field.name], "YYYY,MM,DD").format(
                            "YYYY-MM-DD"
                          )
                          : field.name === PROJECT_END_DATE_FIELD_ID
                            ? moment(editedData[field.name], "YYYY,MM,DD").format(
                              "YYYY-MM-DD"
                            )
                            : field.name === PROJECT_CREATED_ON_FIELD_ID
                              ? moment(editedData[field.name], "YYYY,MM,DD").format(
                                "YYYY-MM-DD"
                              )
                              :
                              field.name === PROJECT_CREATED_ON_FIELD_ID
                                ? formData.projectManager.fullname :
                                editedData[field.name]
                        : ""
                    }
                    defaultValue={
                      location && location.state
                        ? location.state && location.state.project
                          ? field.name === PROJECT_START_DATE_FIELD_ID
                            ? moment(
                              location.state.project[field.name],
                              "YYYY,MM,DD"
                            ).format("YYYY-MM-DD")
                            : field.name === PROJECT_END_DATE_FIELD_ID
                              ? moment(
                                location.state.project[field.name],
                                "YYYY,MM,DD"
                              ).format("YYYY-MM-DD")
                              : field.name === PROJECT_CREATED_ON_FIELD_ID
                                ? moment(
                                  location.state.project[field.name],
                                  "YYYY,MM,DD"
                                ).format("YYYY-MM-DD")
                                : field.name === PROJECT_MANAGER_FIELD_ID
                                  ? location.state.project[field.name] :
                                  location.state.project[field.name]
                          : ""
                        : formData
                          ? field.name === PROJECT_START_DATE_FIELD_ID
                            ? moment(formData[field.name], "YYYY,MM,DD").format(
                              "YYYY-MM-DD"
                            )
                            : field.name === PROJECT_END_DATE_FIELD_ID
                              ? moment(formData[field.name], "YYYY,MM,DD").format(
                                "YYYY-MM-DD"
                              )
                              : field.name === PROJECT_CREATED_ON_FIELD_ID
                                ? moment(formData[field.name], "YYYY,MM,DD").format(
                                  "YYYY-MM-DD"
                                )
                                : field.name === PROJECT_MANAGER_FIELD_ID
                                  ? formData[field.name] :
                                  formData[field.name]
                          : ""
                    }
                    onChange={(event) =>
                      handleChange(field.name, event.target.value)
                    }
                  >
                    {field.select && field.id === PROJECT_STATUS_FIELD_ID ? (
                      projectStatus.map((status, idx1) => {
                        return (
                          <MenuItem
                            style={{ font: "small-caption",fontFamily: "Lucida Grande" }}
                            value={status}
                            key={idx1}
                          >
                            {status}
                          </MenuItem>
                        );
                      })
                    ) : field.id === PROJECT_CATEGORY_FIELD_ID ? (
                      projectCategories.map((categories, idx2) => {
                        return (
                          <MenuItem
                            style={{ font: "small-caption",fontFamily: "Lucida Grande" }}
                            value={categories}
                            key={idx2}
                          >
                            {categories}
                          </MenuItem>
                        );
                      })
                    ) : field.id === PROJECT_MANAGER_FIELD_ID ? (
                      associates.map((categories, idx2) => {
                        return (
                          <MenuItem
                            style={{ font: "small-caption",fontFamily: "Lucida Grande" }}
                            value={categories.username}
                            key={idx2}
                          >
                            {categories.fullname}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </TextField>
                </Grid>
              ) : (
                <></>
              )}
            </Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};
export default ProjectOverview;
