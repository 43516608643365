import React from 'react'
import ReportComponent from '../../edvenswa.emportal.reports/pages/ReportComponent'

const AdminScreenAppraisal = (props) => {
    return (
        <div>
            <ReportComponent {...props}
                type="Appraisals"
                displayAdd={false}
            />
        </div>
    )
}

export default AdminScreenAppraisal
