import { AccountBox, Category, Countertops, Description, Event, HourglassTopSharp, Image, Note, Person, PriorityHigh } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { PROJECT_CATEGORY_FIELD_ID, PROJECT_COMPANY_FIELD_ID, PROJECT_CREATED_BY_FIELD_ID, PROJECT_CREATED_ON_FIELD_ID, PROJECT_DESCRIPTION_FIELD_ID, PROJECT_END_DATE_FIELD_ID, PROJECT_ESTIMATED_HOURS_FIELD_ID, PROJECT_IMAGE_FIELD_ID, PROJECT_KEY_FIELD_ID, PROJECT_MANAGER_FIELD_ID, PROJECT_RESOURCES_COUNT_FIELD_ID, PROJECT_RESOURCES_FIELD_ID, PROJECT_START_DATE_FIELD_ID, PROJECT_STATUS_FIELD_ID, PROJECT_TITLE_FIELD_ID, PROJECT_VALUE_FIELD_ID } from "../constants/Constants";
import moment from "moment";


export const projectFields = {
    [PROJECT_TITLE_FIELD_ID]: {
        id: PROJECT_TITLE_FIELD_ID,
        name: PROJECT_TITLE_FIELD_ID,
        type: "text",
        label: "Project Title",
        placeholder: "Enter Project Title",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <Note fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [PROJECT_KEY_FIELD_ID]: {
        id: PROJECT_KEY_FIELD_ID,
        name: PROJECT_KEY_FIELD_ID,
        type: "text",
        label: "Project Key",
        placeholder: "Enter Project Key",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <Note fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [PROJECT_RESOURCES_COUNT_FIELD_ID]: {
        id: PROJECT_RESOURCES_COUNT_FIELD_ID,
        name: PROJECT_RESOURCES_COUNT_FIELD_ID,
        type: "text",
        label: "Resources Count",
        placeholder: "Enter Resources Count",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <Countertops fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [PROJECT_ESTIMATED_HOURS_FIELD_ID]: {
        id: PROJECT_ESTIMATED_HOURS_FIELD_ID,
        name: PROJECT_ESTIMATED_HOURS_FIELD_ID,
        type: "text",
        label: "Estimated Hours",
        placeholder: "Enter Estimated hours",
        variant: "outlined",
        autoFocus: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <HourglassTopSharp fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [PROJECT_DESCRIPTION_FIELD_ID]: {
        id: PROJECT_DESCRIPTION_FIELD_ID,
        sx: 12,
        rows: 3,
        name: PROJECT_DESCRIPTION_FIELD_ID,
        type: "text",
        multiline: true,
        label: "Project Description",
        placeholder: "Enter Description",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <Description fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [PROJECT_STATUS_FIELD_ID]: {
        id: PROJECT_STATUS_FIELD_ID,
        name: PROJECT_STATUS_FIELD_ID,
        select: true,
        label: "Project Status",
        placeholder: "Enter Status",
        variant: "outlined",
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <PriorityHigh fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [PROJECT_CATEGORY_FIELD_ID]: {
        id: PROJECT_CATEGORY_FIELD_ID,
        name: PROJECT_CATEGORY_FIELD_ID,
        select: true,
        label: "Project Category",
        placeholder: "Enter Project Category",
        variant: "outlined",
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <Category fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [PROJECT_COMPANY_FIELD_ID]: {
        id: PROJECT_COMPANY_FIELD_ID,
        name: PROJECT_COMPANY_FIELD_ID,
        select: true,
        label: "Comapny",
        placeholder: "Enter Company",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <Category fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [PROJECT_MANAGER_FIELD_ID]: {
        id: PROJECT_MANAGER_FIELD_ID,
        name: PROJECT_MANAGER_FIELD_ID,
        select: true,
        label: "Project Manager",
        placeholder: "Enter Project Manager",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <Category fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [PROJECT_VALUE_FIELD_ID]: {
        id: PROJECT_VALUE_FIELD_ID,
        name: PROJECT_VALUE_FIELD_ID,
        type: "text",
        label: "Project Value",
        placeholder: "Enter Project Value",
        variant: "outlined",
        autoFocus: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <Event fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [PROJECT_START_DATE_FIELD_ID]: {
        id: PROJECT_START_DATE_FIELD_ID,
        name: PROJECT_START_DATE_FIELD_ID,
        type: "date",
        label: 'Start Date ',
        variant: "outlined",
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <Event fontSize="small" />
                </InputAdornment>
            )
        },
        inputProps: {
            max: moment(new Date()).endOf('week').format('yyyy-MM-DD')
        }
    },
    [PROJECT_END_DATE_FIELD_ID]: {
        id: PROJECT_END_DATE_FIELD_ID,
        name: PROJECT_END_DATE_FIELD_ID,
        type: "date",
        label: 'End Date ',
        variant: "outlined",
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <Event fontSize="small" />
                </InputAdornment>
            )
        },
        inputProps: { max: moment(new Date()).endOf('week').format('yyyy-MM-DD') }
    },
    [PROJECT_CREATED_ON_FIELD_ID]: {
        id: PROJECT_CREATED_ON_FIELD_ID,
        name: PROJECT_CREATED_ON_FIELD_ID,
        type: "date",
        label: 'Created On',
        variant: "outlined",
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <Event fontSize="small" />
                </InputAdornment>
            )
        },
        inputProps: { max: moment(new Date()).endOf('week').format('yyyy-MM-DD') }
    },
    [PROJECT_RESOURCES_FIELD_ID]: {
        id: PROJECT_RESOURCES_FIELD_ID,
        name: PROJECT_RESOURCES_FIELD_ID,
        type: "button",
        label: "Add Associate",
        variant: "outlined",
        required: true,
        InputLabelProps: { shrink: true },
        InputProps: {
            startAdornment: (
                <InputAdornment position="start">
                    <Person fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [PROJECT_IMAGE_FIELD_ID]: {
        id: PROJECT_IMAGE_FIELD_ID,
        name: PROJECT_IMAGE_FIELD_ID,
        type: "File",
        label: 'Upload Logo',
        variant: "standard",
        autoFocus: false,
        required: false,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <Image fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [PROJECT_CREATED_BY_FIELD_ID]: {
        id: PROJECT_CREATED_BY_FIELD_ID,
        name: PROJECT_CREATED_BY_FIELD_ID,
        type: "text",
        label: "Created by",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <AccountBox fontSize="small" />
                </InputAdornment>
            )
        },

    },


}