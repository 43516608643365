import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";

export default function RolePopover(props) {
  const { el, handleClose, user, onSuccess } = props;
  const popOverOpen = Boolean(el);
  const [authorities, setAuthorities] = useState([]);
  const [selectedAuthority, setSelectedAuthority] = useState(null);
  const [userRole, setUserRole] = useState(null);
  useEffect(() => {
    axiosInstance.get("/user/roles").then((res) => {
      handleSuccess(res.data, "GET_ROLES");
    });
  }, []);
  console.log(authorities);
  const handleSuccess = (data, type) => {
    if (type === "GET_ROLES") {
      setAuthorities(data);
    }
  };

  const handleChange = (event) => {
    setSelectedAuthority(event.target.value);
    setUserRole(event.target.value);
  };

  const handleApplyRoles = (event) => {
    if (selectedAuthority) {
      const role = [
        authorities.find((authority) => authority.name === selectedAuthority),
      ];
      axiosInstance
        .post("/user/update/role", { associateId: user?.associateId, roles: role })
        .then((res) => {
          setSelectedAuthority(null);
          onSuccess("User role updated successfully");
          handleClose();
        });
    }
    else {
      handleClose();
    }
  };

  return (
    <Popover
      id={"ae-roles-popover"}
      open={popOverOpen}
      anchorEl={el}
      onClose={handleClose}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Card>
        <CardHeader
          title={"Roles"}
          sx={{
            textAlign: "center",
            color: "white",
            backgroundColor: "#029a9c",
          }} />
        <CardContent>
          <FormControl component="fieldset">
            <RadioGroup value={selectedAuthority} onChange={handleChange}>
              {authorities &&
                authorities.map((authority, index) => (
                  <FormControlLabel
                    key={index}
                    value={authority.name}
                    control={<Radio />}
                    label={authority.name}
                    checked={authority.name === (userRole ? userRole : user?.roles?.[0]?.name)}
                  />
                ))}
            </RadioGroup>
          </FormControl>
        </CardContent>
        <CardActions sx={{ textAlign: "center", justifyContent: "center" }}>
          <Button size="small" variant="contained" onClick={handleApplyRoles}>
            Apply
          </Button>
        </CardActions>
      </Card>
    </Popover>
  );
}
