import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
// import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
// import "ag-grid-community/styles/ag-theme-quartz.css";
import DownloadIcon from "@mui/icons-material/Download";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import saveAs from "file-saver";
import { useLocation } from "react-router-dom";
import { utils, write } from "xlsx";
import { AUTH_SECURE_USER_DETAILS_LS_LEY } from "../../edvenswa.emportal.auth/constants/Constants";
import { END_DATE_FORMAT, START_DATE_FORMAT } from "../constants/Constants";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ConsolidateTimesheetReport = (props) => {
  const location = useLocation();
  // const { Companies } = props;
  const Companies = JSON.parse(localStorage.getItem("companies"))
  const user = JSON.parse(sessionStorage.getItem(AUTH_SECURE_USER_DETAILS_LS_LEY))
  const [menuanchorEl, setMenuAnchorEl] = useState(null);
  const [res, setRes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredElements, setFilteredElements] = useState([]);

  const [startDate, setStartDate] = useState(START_DATE_FORMAT);
  const [endDate, setEndDate] = useState(END_DATE_FORMAT);
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  // const [Companies, setCompanies] = useState(props.Companies);
  const [companyId, setCompanyId] = useState(user.tenantId);
  const SORT_TYPES1 = [
    { name: "Active", value: "ACTIVE" },
    { name: "Inactive", value: "INACTIVE" }
  ];
  const [sortBy, setSortBy] = useState("ACTIVE");
  const [selectedCompany, setSelectedCompany] = useState(
    location?.state?.user?.tenant || ""
  );

  const handleStartDateChange = (e, type) => {
    if (type === "icon") {
      const newStartDate = moment(startDate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      const newEndDate = moment(endDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
    else {
      const value = e.target.value;
      setStartDate(value);
      setEndDateError("");
      if (value && endDate && value > endDate) {
        setStartDateError("Start date cannot be after end date");
      } else {
        setStartDateError("");
      }
    }
  };

  const handleEndDateChange = (e, type) => {
    if (type === "icon") {
      const newStartDate = moment(startDate).add(1, 'month').startOf('month').format('YYYY-MM-DD');
      const newEndDate = moment(endDate).add(1, 'month').endOf('month').format('YYYY-MM-DD');
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
    else {
      const value = e.target.value;
      setEndDate(value);
      setStartDateError("");
      if (value && startDate && value < startDate) {
        setEndDateError("End date cannot be before start date");
      } else {
        setEndDateError("");
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    if (startDateError === "" && endDateError === "") {
      axiosInstance
        .post(
          `/task/consolidatedTimesheetReports?tenantId=${companyId}&sortBy=${sortBy}`,
          {
            startDate,
            endDate,
          }
        )
        .then((res) => {
          setFilteredElements(res.data.filter((user) => user.role !== "Admin"));
          setRes(res.data.filter((user) => user.role !== "Admin"));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {
      setFilteredElements([]);
      setRes([]);
      setLoading(false);
    }
  }, [sortBy, startDate, endDate]);

  const colDefs = [
    { field: "associateId", headerName: "Associate Id", filter: true, flex: 1 },
    { field: "fullName", headerName: "Associate Name", filter: true, flex: 1 },
    {
      field: "totalWorkingDays",
      headerName: "Total Working Hours",
      filter: true,
      flex: 0.8,
      valueFormatter: (p) => {
        if (p.value !== null) {
          return `${Math.floor(p.value / 3600)}:${Math.floor((p.value % 3600) / 60).toString().padStart(2, '0')}`;
        }
        else {
          return `0`
        }
      }
    },
    {
      field: "totalEnteredHours",
      headerName: "Entered Hours",
      filter: true,
      flex: 1,
      valueFormatter: (p) => {
        if (p.value !== null) {
          return `${Math.floor(p.value / 3600)}:${Math.floor((p.value % 3600) / 60).toString().padStart(2, '0')}`;
        }
        else {
          return `0`
        }
      }
    },
    {
      field: "totalOvertimeHours",
      headerName: "Overtime Hours",
      filter: true,
      flex: 1,
      valueFormatter: (p) => {
        if (p.value !== null) {
          return `${Math.floor(-p.value / 3600)}:${Math.floor((-p.value % 3600) / 60).toString().padStart(2, '0')}`;
        }
        else {
          return `0`
        }
      }
    },
    {
      field: "totalShortHours",
      headerName: "Short Hours",
      filter: true,
      flex: 0.8,
      valueFormatter: (p) => {
        if (p.value !== null) {
          return `${Math.floor(p.value / 3600)}:${Math.floor((p.value % 3600) / 60).toString().padStart(2, '0')}`;
        }
        else {
          return `0`
        }
      }
    },
    {
      field: "totalHolidays", headerName: "Holidays", filter: true, flex: 0.8,
      valueFormatter: (p) => {
        if (p.value !== null) {
          return `${Math.floor(p.value / 3600)}:${Math.floor((p.value % 3600) / 60).toString().padStart(2, '0')}`;
        }
        else {
          return `0`
        }
      }
    },
    {
      field: "totalLeaves", headerName: "Leaves", filter: true, flex: 0.8,
      valueFormatter: (p) => {
        if (p.value !== null) {
          return `${Math.floor(p.value / 3600)}:${Math.floor((p.value % 3600) / 60).toString().padStart(2, '0')}`;
        }
        else {
          return `0`
        }
      }
    },
  ];

  const handleDownloadReport = () => {
    setMenuAnchorEl(null);
    if (filteredElements.length > 0) {
      const fileName = `AllTimesheetReport.xlsx`;
      const formattedData = filteredElements.map((task) => {
        const TotalWorkingHours = task.totalWorkingDays ? `${Math.floor(task.totalWorkingDays / 3600)}:${Math.floor((task.totalWorkingDays % 3600) / 60).toString().padStart(2, '0')}` : "0:00";
        const TotalEnteredHours = task.totalEnteredHours ? `${Math.floor(task.totalEnteredHours / 3600)}:${Math.floor((task.totalEnteredHours % 3600) / 60).toString().padStart(2, '0')}` : "0:00";
        const TotalOvertimeHours = task.totalOvertimeHours ? `${Math.floor(task.totalOvertimeHours / 3600)}:${Math.floor((task.totalOvertimeHours % 3600) / 60).toString().padStart(2, '0')}` : "0:00";
        const TotalShorttimeHours = task.totalShortHours ? `${Math.floor(task.totalShortHours / 3600)}:${Math.floor((task.totalShortHours % 3600) / 60).toString().padStart(2, '0')}` : "0:00";
        const TotalHolidays = task?.totalHolidays ? `${Math.floor(task.totalHolidays / 3600)}:${Math.floor((task.totalHolidays % 3600) / 60).toString().padStart(2, '0')}` : "0:00";
        const TotalLeaves = task?.totalLeaves ? `${Math.floor(task.totalLeaves / 3600)}:${Math.floor((task.totalLeaves % 3600) / 60).toString().padStart(2, '0')}` : "0:00";
        return {
          "Associate Id": task.associateId ? task.associateId : "",
          "Associate Name": task.fullName ? task.fullName : "",
          "Start Date": task.startDate
            ? moment(task.startDate, "YYYY,MM,DD").format("DD-MMM-yyyy")
            : " ",
          "End Date": task.endDate
            ? moment(task.endDate, "YYYY,MM,DD").format("DD-MMM-yyyy")
            : " ",
          "Total Working Hours": TotalWorkingHours,
          "Total Entered Hours": TotalEnteredHours,
          "Total Overtime Hours": TotalOvertimeHours,
          "Total Shorttime Hours": TotalShorttimeHours,
          "Total Holidays": TotalHolidays,
          "Total Leaves": TotalLeaves
        };
      });

      const header = [
        "Associate Id",
        "Associate Name",
        "Start Date",
        "End Date",
        "Total Working Hours",
        "Total Entered Hours",
        "Total Overtime Hours",
        "Total Shorttime Hours",
        "Total Holidays",
        "Total Leaves",
      ];

      const worksheet = utils.json_to_sheet(formattedData, { header });
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"],
      };
      const excelBuffer = write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName);
    }
  };

  const handleDownloadPDF = () => {
    if (filteredElements.length > 0) {
      const pdfWidth = 210;
      const pdfHeight = 250;
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [pdfWidth, pdfHeight],
      });
      const logoUrl =
        "https://tse3.mm.bing.net/th?id=OIP.xrnfPsu-nIEVf0HzuyWt3AAAAA&pid=Api&P=0&h=180";
      const centerX = pdf.internal.pageSize.width / 2;
      const imageWidth = 20;
      const imageHeight = 20;
      const imageY = 2;
      pdf.addImage(
        logoUrl,
        centerX - imageWidth / 2,
        imageY,
        imageWidth,
        imageHeight
      );

      const formattedData = filteredElements.map((task) => {
        const TotalWorkingHours = task.totalWorkingDays ? `${Math.floor(task.totalWorkingDays / 3600)}:${Math.floor((task.totalWorkingDays % 3600) / 60).toString().padStart(2, '0')}` : "0:00";
        const TotalEnteredHours = task.totalEnteredHours ? `${Math.floor(task.totalEnteredHours / 3600)}:${Math.floor((task.totalEnteredHours % 3600) / 60).toString().padStart(2, '0')}` : "0:00";
        const TotalOvertimeHours = task.totalOvertimeHours ? `${Math.floor(task.totalOvertimeHours / 3600)}:${Math.floor((task.totalOvertimeHours % 3600) / 60).toString().padStart(2, '0')}` : "0:00";
        const TotalShorttimeHours = task.totalShortHours ? `${Math.floor(task.totalShortHours / 3600)}:${Math.floor((task.totalShortHours % 3600) / 60).toString().padStart(2, '0')}` : "0:00";
        const TotalHolidays = task?.totalHolidays ? `${Math.floor(task.totalHolidays / 3600)}:${Math.floor((task.totalHolidays % 3600) / 60).toString().padStart(2, '0')}` : "0:00";
        const TotalLeaves = task?.totalLeaves ? `${Math.floor(task.totalLeaves / 3600)}:${Math.floor((task.totalLeaves % 3600) / 60).toString().padStart(2, '0')}` : "0:00";
        return {
          "Associate Id": task.associateId ? task.associateId : "",
          "Associate Name": task.fullName ? task.fullName : "",
          "Start Date": task.startDate
            ? moment(task.startDate, "YYYY,MM,DD").format("DD-MMM-yyyy")
            : " ",
          "End Date": task.endDate
            ? moment(task.endDate, "YYYY,MM,DD").format("DD-MMM-yyyy")
            : " ",
          "Total Working Hours": TotalWorkingHours,
          "Total Entered Hours": TotalEnteredHours,
          "Total Overtime Hours": TotalOvertimeHours,
          "Total Shorttime Hours": TotalShorttimeHours,
          "Total Holidays": TotalHolidays,
          "Total Leaves": TotalLeaves
        };
      });

      const header = [
        "Associate Id",
        "Associate Name",
        "Start Date",
        "End Date",
        "Total Working Hours",
        "Total Entered Hours",
        "Total Overtime Hours",
        "Total Shorttime Hours",
        "Total Holidays",
        "Total Leaves",
      ];
      const userData = formattedData.map((val) =>
        header.map((header) => val[header])
      );
      const tableStartY = imageY + imageHeight + 20; //44
      // const formattedPercentage = `${((percentageUsedsum / filteredElements.length) * 100).toFixed(2)}`;

      pdf.autoTable({
        head: [header],
        body: userData,
        startY: tableStartY,
        headStyles: {
          fillColor: "#008080",
          textColor: "#ffffff",
          fontStyle: "bold",
        },
        didDrawPage: function (data) {
          if (data.pageNumber === 1) {
            pdf.setFont("Times New Roman, Times, serif");
            pdf.text(
              `Dear Admin, Following is the Associates Working Details Report`,
              12,
              tableStartY - 6
            );
            // pdf.text((`For the time period of ${moment(startDate).format('DD-MMM-yyyy')} TO ${moment(endDate).format('DD-MMM-yyyy')}`) , 12, 46);
            pdf.setFontSize(13);
          }
        },
        didParseCell: function (data) {
          data.cell.styles.lineWidth = 0.1;
          data.cell.styles.lineColor = [0, 0, 0];
        },
      });

      pdf.save(`AllTimesheetReport.pdf`);
    }
    setMenuAnchorEl(null);
  };

  const handleSearchChange = (e) => {
    const searchItem = e.target.value;
    setSearchTerm(searchItem);

    const filteredRes = res.filter((item) =>
      `${item.fullName}`.toLowerCase().includes(searchItem.toLowerCase())
    );
    setFilteredElements(filteredRes);

    if (filteredRes.length === 0) {
      setFilteredElements(
        res.filter((item) =>
          `${item.associateId}`.toLowerCase().includes(searchItem.toLowerCase())
        )
      );
    }
  };

  const handleDownloadClose = () => {
    setMenuAnchorEl(null);
  };

  const handleDownloadMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  console.log(user.tenant);

  return (
    <>
      {(
        <Box>
          <Card
            elevation={0}
            style={{ backgroundColor: "#FFFFFF", padding: "34px" }}
          >
            <Grid container item xs={12}>
              {
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                  }}
                >
                  <Typography sx={{ fontFamily: "Lucida Grande", color: '#016466', fontSize: '17px' }}>
                    Associate Reports
                  </Typography>
                  &nbsp; &nbsp;
                  <TextField
                    size="small"
                    select
                    defaultValue={user?.tenant}
                    value={selectedCompany.name}
                    InputProps={{
                      style: {
                        font: "small-caption",
                        fontFamily: "Lucida Grande",
                      },
                    }}
                    onChange={(e) => {
                      setFilteredElements([]);
                      // setCompanyId(e.target.value);
                      const company = Companies.find(
                        (data) => data.name === e.target.value
                      );
                      setCompanyId(
                        Companies.find((data) => data.name === e.target.value).id
                      );
                      setSearchTerm("")
                      setStartDate(START_DATE_FORMAT)
                      setEndDate(END_DATE_FORMAT)
                      setSortBy("ACTIVE")
                      axiosInstance
                        .post(
                          `/task/consolidatedTimesheetReports?tenantId=${company.id}&sortBy=${sortBy}`,
                          {
                            startDate: startDate,
                            endDate: endDate,
                          }
                        )
                        .then((res) => {
                          setFilteredElements(res.data);
                          setRes(res.data);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                      setSelectedCompany(company);
                    }}
                  >
                    {Companies &&
                      Companies?.length > 0 &&
                      Companies.map((val) => (
                        <MenuItem
                          style={{
                            font: "small-caption",
                            fontFamily: "Lucida Grande",
                          }}
                          key={val}
                          value={val.name}
                        >
                          {val.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  &nbsp; &nbsp;
                  <TextField
                    size="small"
                    select
                    value={sortBy}
                    onChange={(e) => {
                      setSortBy(e.target.value);
                    }}
                    InputProps={{
                      style: {
                        font: "small-caption",
                        fontFamily: "Lucida Grande",
                      },
                    }}
                  >
                    {SORT_TYPES1 &&
                      SORT_TYPES1?.length > 0 &&
                      SORT_TYPES1.map((val) => (
                        <MenuItem
                          style={{
                            font: "small-caption",
                            fontFamily: "Lucida Grande",
                          }}
                          key={val}
                          value={val.value}
                        >
                          {val.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  &nbsp; &nbsp;
                  <TextField
                    value={searchTerm}
                    label="Search by name or Id"
                    size="small"
                    inputProps={{ style: { font: "small-caption", fontFamily: "Lucida Grande" } }}
                    placeholder="Type to search"
                    onChange={handleSearchChange}
                    InputLabelProps={{ font: "small-caption", style: { fontSize: 13, fontFamily: "Lucida Grande" } }}
                  />
                  &nbsp; &nbsp;
                  <Tooltip title="click to go to previous month">
                    <IconButton onClick={(e) => handleStartDateChange(e, "icon")}>
                      <ArrowBackIosIcon
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                  <TextField
                    type="date"
                    value={startDate}
                    label="Start Date"
                    size="small"
                    // error={!!startDateError}
                    // helperText={startDateError}
                    error={
                      startDate &&
                        new Date(startDate) > new Date(endDate)
                        ? "Start date cannot be after end date"
                        : ""
                    }
                    helperText={
                      startDate &&
                        new Date(startDate) > new Date(endDate)
                        ? "Start date cannot be after end date"
                        : ""
                    }
                    inputProps={{
                      style: { font: "small-caption", fontFamily: "Lucida Grande" },
                      max: moment().format("YYYY-MM-DD"),
                    }}
                    onChange={handleStartDateChange}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '16px', fontFamily: "Lucida Grande" },
                    }}

                  />
                  &nbsp; &nbsp;
                  <TextField
                    type="date"
                    value={endDate}
                    label="End Date"
                    size="small"
                    // error={!!endDateError}
                    // helperText={endDateError}
                    error={
                      startDate &&
                        new Date(endDate) < new Date(startDate)
                        ? "End date cannot be before start date"
                        : ""
                    }
                    helperText={
                      startDate &&
                        new Date(endDate) < new Date(startDate)
                        ? "End date cannot be before start date"
                        : ""
                    }
                    inputProps={{
                      style: { font: "small-caption", fontFamily: "Lucida Grande" },
                      max: moment(new Date()).endOf('month').format("YYYY-MM-DD"),

                    }}
                    onChange={handleEndDateChange}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '16px', fontFamily: "Lucida Grande" },
                    }}
                  />
                  <Tooltip title="click to go to next month">
                    <IconButton onClick={(e) => handleEndDateChange(e, "icon")}>
                      <ArrowForwardIosIcon
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                  &nbsp; &nbsp;
                  {/* <Button
                    variant="contained"
                    size="small"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      axiosInstance
                        .post(
                          `/task/consolidatedTimesheetReports?tenantId=${companyId}`,
                          {
                            startDate,
                            endDate,
                          }
                        )
                        .then((res) => {
                          setFilteredElements(res.data);
                          setRes(res.data);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                  >
                    Generate
                  </Button> */}
                  <Tooltip title={props.downloadTitle}>
                    <IconButton
                      disabled={startDateError !== "" || endDateError !== "" || filteredElements.length === 0}
                      onClick={handleDownloadMenuClick}>
                      <DownloadIcon />
                    </IconButton>
                    <Menu
                      anchorEl={menuanchorEl}
                      open={Boolean(menuanchorEl)}
                      onClose={handleDownloadClose}
                    >
                      <MenuItem
                        sx={{ font: "small-caption" }}
                        onClick={() => handleDownloadPDF()}
                      >
                        Download PDF
                      </MenuItem>
                      <MenuItem
                        sx={{ font: "small-caption" }}
                        onClick={() => handleDownloadReport()}
                      >
                        Download Excel
                      </MenuItem>
                    </Menu>
                  </Tooltip>
                </Grid>
              }
            </Grid>
            {loading ? (
              <Grid sx={{ textAlignLast: "center" }} item xs={12}>
                <Box style={{ paddingTop: "50px", paddingBottom: "40px" }}>
                  <CircularProgress />
                </Box>
              </Grid>
            ) : (
              filteredElements.length === 0 ?
                <Typography mt={3} display={'flex'} justifyContent={'center'} variant='body2'>No Data Found</Typography> :
                <div
                  className="ag-theme-alpine" // applying the grid theme
                  style={{ height: 420, marginTop: "1px", fontFamily: "Lucida Grande" }} // the grid will fill the size of the parent container
                >
                  <AgGridReact rowData={filteredElements} columnDefs={colDefs}
                    domLayout="normal" // Set domLayout to "normal" for fixed headers
                    // style="width: 500px; height: 500px;"
                    defaultColDef={{
                      filter: true,
                      width: 120,
                      headerClass: "custom-header",
                    }} />
                </div>
            )}
          </Card>
        </Box>
      )}
    </>
  );
};

export default ConsolidateTimesheetReport;
