import React from "react";

import { Route } from "react-router-dom";
import PrivateRoute from "../../../guards/PrivateRoute";
import Layout from "../../edvenswa.emportal.layout/Layout";
import UserHomeComponent from "../pages/UserHome";

// const UserHomeComponent = React.lazy(() => import("../pages/UserHome"));

export const HOME_ROUTES = [
    <Route path="/home"
        element={
            <PrivateRoute>
                <Layout component={UserHomeComponent}></Layout>
            </PrivateRoute>
        }
        key={"HOME_ROUTE"}
    />
];
