import { Event, MailOutline, ManageAccountsOutlined, Note, NoteAdd, PersonOutlineSharp, PhoneInTalkOutlined, PriorityHighOutlined } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import moment from "moment";
import { ASSOCIATE_COMPANY_FIELD_ID, ASSOCIATE_DATE_OF_BIRTH_FIELD_ID, ASSOCIATE_DATE_OF_JOINING_FIELD_ID, ASSOCIATE_DESIGNATION_FIELD_ID, ASSOCIATE_EMAIL_FIELD_ID, ASSOCIATE_FIRSTNAME_FIELD_ID, ASSOCIATE_ID_FIELD_ID, ASSOCIATE_LASTNAME_FIELD_ID, ASSOCIATE_MOBILE_FIELD_ID, ASSOCIATE_ROLES_FIELD_ID, ASSOCIATE_STATUS_FIELD_ID, ASSOCIATE_TYPE_FIELD_ID, ASSOCIATE_USERNAME_FIELD_ID } from "../constants/Constants";


export const associateFields = {
    [ASSOCIATE_ID_FIELD_ID]: {
        id: ASSOCIATE_ID_FIELD_ID,
        name: ASSOCIATE_ID_FIELD_ID,
        type: "number",
        label: "Associate Id",
        placeholder: "Enter Associate Id",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <Note fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [ASSOCIATE_USERNAME_FIELD_ID]: {
        id: ASSOCIATE_USERNAME_FIELD_ID,
        name: ASSOCIATE_USERNAME_FIELD_ID,
        type: "text",
        label: "Email",
        placeholder: "Enter Company Email",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <MailOutline fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [ASSOCIATE_STATUS_FIELD_ID]: {
        id: ASSOCIATE_STATUS_FIELD_ID,
        name: ASSOCIATE_STATUS_FIELD_ID,
        select: true,
        label: "Status",
        placeholder: "Enter Associate Status",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <PriorityHighOutlined fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [ASSOCIATE_ROLES_FIELD_ID]: {
        id: ASSOCIATE_ROLES_FIELD_ID,
        name: ASSOCIATE_ROLES_FIELD_ID,
        select: true,
        label: "Role",
        placeholder: "Enter Role",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <ManageAccountsOutlined fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [ASSOCIATE_FIRSTNAME_FIELD_ID]: {
        id: ASSOCIATE_FIRSTNAME_FIELD_ID,
        name: ASSOCIATE_FIRSTNAME_FIELD_ID,
        type: "text",
        label: "First Name",
        placeholder: "Enter First Name",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <PersonOutlineSharp fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [ASSOCIATE_LASTNAME_FIELD_ID]: {
        id: ASSOCIATE_LASTNAME_FIELD_ID,
        name: ASSOCIATE_LASTNAME_FIELD_ID,
        type: "text",
        label: "Last Name",
        placeholder: "Enter Last Lame",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <PersonOutlineSharp fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [ASSOCIATE_DESIGNATION_FIELD_ID]: {
        id: ASSOCIATE_DESIGNATION_FIELD_ID,
        name: ASSOCIATE_DESIGNATION_FIELD_ID,
        select: true,
        label: "Designation",
        placeholder: "Enter Designation",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <NoteAdd fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [ASSOCIATE_TYPE_FIELD_ID]: {
        id: ASSOCIATE_TYPE_FIELD_ID,
        name: ASSOCIATE_TYPE_FIELD_ID,
        select: true,
        label: "Type",
        placeholder: "Enter Type",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <NoteAdd fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [ASSOCIATE_DATE_OF_JOINING_FIELD_ID]: {
        id: ASSOCIATE_DATE_OF_JOINING_FIELD_ID,
        name: ASSOCIATE_DATE_OF_JOINING_FIELD_ID,
        type: "date",
        label: 'Date of Joining',
        variant: "outlined",
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <Event fontSize="small" />
                </InputAdornment>
            )
        },
        // inputProps: { max: moment(new Date()).endOf('week').format('yyyy-MM-DD') }
    },
    [ASSOCIATE_DATE_OF_BIRTH_FIELD_ID]: {
        id: ASSOCIATE_DATE_OF_BIRTH_FIELD_ID,
        name: ASSOCIATE_DATE_OF_BIRTH_FIELD_ID,
        type: "date",
        label: 'Date of Birth',
        variant: "outlined",
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <Event fontSize="small" />
                </InputAdornment>
            )
        },
        inputProps: { max: moment(new Date()).endOf('week').format('yyyy-MM-DD') }
    },
    [ASSOCIATE_EMAIL_FIELD_ID]: {
        id: ASSOCIATE_EMAIL_FIELD_ID,
        name: ASSOCIATE_EMAIL_FIELD_ID,
        type: "text",
        label: "Personal Email",
        placeholder: "Enter Personal Email",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <MailOutline fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [ASSOCIATE_MOBILE_FIELD_ID]: {
        id: ASSOCIATE_MOBILE_FIELD_ID,
        name: ASSOCIATE_MOBILE_FIELD_ID,
        type: "number",
        label: "Mobile No",
        placeholder: "Enter Mobile No",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <PhoneInTalkOutlined fontSize="small" />&nbsp;
                    +91
                </InputAdornment>
            )
        }
    },
    [ASSOCIATE_COMPANY_FIELD_ID]: {
        id: ASSOCIATE_COMPANY_FIELD_ID,
        name: ASSOCIATE_COMPANY_FIELD_ID,
        select: true,
        label: "Company",
        placeholder: "Enter Company",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <PhoneInTalkOutlined fontSize="small" />
                </InputAdornment>
            )
        }
    },

}