import { AccountBalance, EventNote } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import { AUTH_SECURE_USER_DETAILS_LS_LEY } from "../../edvenswa.emportal.auth/constants/Constants";
import { END_DATE_FORMAT, START_DATE_FORMAT } from "../constants/Constants";
import MyTimeCards from "./MyTimeCards";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const TimeCards = (props) => {
  const [res, setRes] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState("error");
  const page = 1
  const [pageSize, setPageSize] = useState(10);

  const user = JSON.parse(
    sessionStorage.getItem(AUTH_SECURE_USER_DETAILS_LS_LEY)
  );
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredElements, setFilteredElements] = useState([]);
  const date = moment(new Date()).format("YYYY-MM-DD");

  const SORT_TASKS = [
    {
      name: "Approved",
      value: "APPROVED",
    },
    {
      name: "Pending At Associate",
      value: "PENDING",
    },
    {
      name: "Submitted",
      value: "SUBMITTED",
    },
    {
      name: "All",
      value: "ALL",
    },
  ];
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [sortBy, setSortBy] = useState("ALL");
  const role = user.roles[0];
  const [startDate, setStartDate] = useState(START_DATE_FORMAT);
  const [endDate, setEndDate] = useState(END_DATE_FORMAT);
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const projects = user.projects.length > 1 ? [{ title: "All" }, ...user.projects] : user.projects.length === 0 ? [{ title: "No Projects Assigned" }] : user.projects;
  const allProjects = user.projects.map(project => project.title);
  const [type, setType] = useState("All");
  const [project, setProject] = useState(() => {
    if (projects[0]?.title === "All") {
      return allProjects;
    }
    else {
      return [projects[0]?.title];
    }
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [rejectmessage, setRejectMessage] = useState('')
  const [timesheetToReject, setTimesheetToReject] = useState(null);


  const handleStartDateChange = (e, type) => {
    if (type === "icon") {
      const newStartDate = moment(startDate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      const newEndDate = moment(endDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
    else {
      const value = e.target.value;
      setStartDate(value);
      setEndDateError("");
      if (value && endDate && value > endDate) {
        setStartDateError("Start date cannot be after end date");
      } else {
        setStartDateError("");
      }
    }

  };

  const handleEndDateChange = (e, type) => {
    if (type === "icon") {
      const newStartDate = moment(startDate).add(1, 'month').startOf('month').format('YYYY-MM-DD');
      const newEndDate = moment(endDate).add(1, 'month').endOf('month').format('YYYY-MM-DD');
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
    else {
      const value = e.target.value;
      setEndDate(value);
      setStartDateError("");
      if (value && startDate && value < startDate) {
        setEndDateError("End date cannot be before start date");
      } else {
        setEndDateError("");
      }
    }

  };


  useEffect(() => {
    setLoading(true);
    if (startDateError === "" && endDateError === "") {
      if (user.projects !== null || user.projects !== "") {
        axiosInstance
          .put(
            `/timesheet/getbyUserbyProject?sortBy=${sortBy}&page=${page}&size=${pageSize}`,
            {
              projectTitles: project,
              startDate: startDate,
              endDate: endDate,
            }
          )
          .then((res) => {
            const sortedData = res.data.sort((a, b) => {
              return moment(b.startDate, "YYYY,MM,DD").diff(
                moment(a.startDate, "YYYY,MM,DD")
              );
            });
            handleTimecards(res.data, sortedData);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      }
    } else {
      setFilteredElements([]);
      setRes([]);
      setLoading(false);
    }
  }, [date, pageSize, sortBy, startDate, endDate, project]);

  const handleTimecards = (response, sortedData) => {
    if (type === "All") {
      const projectManagerprojectSet = new Set(allProjects);
      let arr = [];
      response.map((data) => {
        let userObj = { ...data };
        const userprojectSet = new Set(data.projectTitles);
        for (const item of projectManagerprojectSet) {
          if (userprojectSet.has(item)) {
            userObj = { ...userObj, projectShow: item }
            arr.push(userObj)
          }
        }
      })
      setRes(
        arr.filter((data) => data.associate !== user.associateId)
      );
      setFilteredElements(
        arr.filter((data) => data.associate !== user.associateId)
      );
    }
    else {
      setRes(
        sortedData.filter((data) => data.associate !== user.associateId)
      );
      setFilteredElements(
        sortedData.filter((data) => data.associate !== user.associateId)
      );
    }
  };

  const CustomStatusComponent = (row) => {
    const partially_approved_partially_rejects = ((row.data.status !== "APPROVED" && JSON.parse(row.data.tasks).some((data) => data.status === "SUBMITTED")) &&
      (row.data.status !== "PENDING" && JSON.parse(row.data.tasks).some((data) => data.status === "PENDING")))
    if (partially_approved_partially_rejects) {
      return "PARTIALLY APPROVED AND PARTIALLY REJECTED";
    }
    else if (row.data.status !== "APPROVED" && JSON.parse(row.data.tasks).some((data) => data.status === "SUBMITTED")) {
      return "PARTIALLY APPROVED";
    }
    else if (row.data.status !== "PENDING" && JSON.parse(row.data.tasks).some((data) => data.status === "PENDING")) {
      return "PARTIALLY REJECTED";
    }
    else {
      return `${row.data.status}`
    }
  };


  const CustomButtonComponent = (props) => {
    let sortedArrayByProject = []
    if (type === "All") {
      sortedArrayByProject = JSON.parse(
        props.data.tasks
      ).filter((data) => data.project === props.data.projectShow);
    }
    else {
      sortedArrayByProject = JSON.parse(
        props.data.tasks
      ).filter((data) => data.project === project[0]);
    }

    sortedArrayByProject.some((data) => data.status === "SUBMITTED")

    if (props.data.status !== "APPROVED" && sortedArrayByProject.some((data) => data.status === "SUBMITTED")) {
      return <></>;
    }
    else if (props.data.status !== "PENDING" && sortedArrayByProject.some((data) => data.status === "PENDING")) {
      return <></>;
    }
    else {
      return (
        <Stack
          direction={"row"}
          spacing={1}
          sx={{ display: "flex", alignItems: "center" }}
        >
          {
            props.data.status === "SUBMITTED" || (props.data.status === "SUBMITTED" && sortBy === "ALL") ?
              (
                <>
                  <Tooltip title="Approve">
                    <IconButton
                      onClick={() => {
                        handleApprove(props.data);
                      }}
                    >
                      <CheckCircleOutlineIcon
                        sx={{ color: "#1dbb68" }}
                        fontSize="15"
                        color="#029a9c"
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Reject">
                    <IconButton
                      onClick={() => {
                        // handleReject(props.data);
                        setIsDialogOpen(true);
                        setTimesheetToReject(props.data);
                      }}
                    >
                      <CancelIcon
                        sx={{ color: "#e74c3c" }}
                        fontSize="15"
                        color="#e74c3c"
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )
              : (
                <></>
              )
          }
        </Stack>
      );
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleApprove = (row) => {
    if (row.status === "SUBMITTED") {
      setLoading(true);
      let updatedFormData = []
      let taskDataObjSubmitted = {}
      let status = "SUBMITTED";
      const projectShow = project.length > 1 ? row.projectShow : project[0]

      const sortedArrayByProject = JSON.parse(
        row.tasks
      ).filter((data) => data.project === projectShow);

      sortedArrayByProject.map((data) => {
        taskDataObjSubmitted = { ...data, status: 'SUBMITTED' }
        updatedFormData.push(taskDataObjSubmitted)
      })

      const tasksNotShowinginTimesheet = JSON.parse(
        row?.tasks
      ).filter((data) => data.project !== projectShow)

      let existingTimesheetTasks = [...updatedFormData, ...tasksNotShowinginTimesheet]
      let allTasksApprovedbyManager = existingTimesheetTasks.filter((data) => data.status === "SUBMITTED").length

      if (allTasksApprovedbyManager === existingTimesheetTasks.length) {
        status = "APPROVED"
      }
      axiosInstance
        .put(`/timesheet/approvestatus?project=${projectShow}`, {
          timesheetId: row.timesheetId,
          tasks: JSON.stringify(existingTimesheetTasks),
          status: status
        })
        .then(() => {
          setLoading(false);
          setMessage("Timesheet is Approved from your side!");
          axiosInstance
            .put(
              `/timesheet/getbyUserbyProject?sortBy=${sortBy}&page=${page}&size=${pageSize}`,
              {
                projectTitles: project,
                startDate: startDate,
                endDate: endDate,
              }
            )
            .then((res) => {
              const sortedData = res.data.sort((a, b) => {
                return moment(b.startDate, "YYYY,MM,DD").diff(
                  moment(a.startDate, "YYYY,MM,DD")
                );
              });
              handleTimecards(res.data, sortedData);
              setSnackbarOpen(true);
              setMessage("Timesheet is Approved Successfully!");
              setSeverity("success");
              setLoading(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error:", err);
        })
    }
  };

  const handleReject = (row) => {
    if (row.status === "SUBMITTED") {
      setLoading(true);
      let updatedFormData = []
      let taskDataObjSubmitted = {}
      let status = "SUBMITTED";
      const projectShow = project.length > 1 ? row.projectShow : project[0]

      const sortedArrayByProject = JSON.parse(
        row.tasks
      ).filter((data) => data.project === projectShow);

      sortedArrayByProject.map((data) => {
        taskDataObjSubmitted = { ...data, status: 'PENDING' }
        updatedFormData.push(taskDataObjSubmitted)
      })

      const tasksNotShowinginTimesheet = JSON.parse(
        row?.tasks
      ).filter((data) => data.project !== projectShow)

      let existingTimesheetTasks = [...updatedFormData, ...tasksNotShowinginTimesheet]
      let allTasksRejectedbyManager = existingTimesheetTasks.filter((data) => data.status === "PENDING").length

      if (allTasksRejectedbyManager === existingTimesheetTasks.length) {
        status = "PENDING"
      }

      axiosInstance
        .put(`/timesheet/rejectstatus?project=${projectShow}`, {
          timesheetId: row.timesheetId,
          message: rejectmessage,
          tasks: JSON.stringify(existingTimesheetTasks),
          status: status
        })
        .then(() => {
          setLoading(false);
          setSnackbarOpen(true);
          setMessage("Timesheet is Rejected from your side!");
          setSeverity("success");
          axiosInstance
            .put(
              `/timesheet/getbyUserbyProject?sortBy=${sortBy}&page=${page}&size=${pageSize}`,
              {
                projectTitles: project,
                startDate: startDate,
                endDate: endDate,
              }
            )
            .then((res) => {
              const sortedData = res.data.sort((a, b) => {
                return moment(b.startDate, "YYYY,MM,DD").diff(
                  moment(a.startDate, "YYYY,MM,DD")
                );
              });
              handleTimecards(res.data, sortedData);
              // setRes(
              //   sortedData.filter((data) => data.associate !== user.associateId)
              // );
              // setFilteredElements(
              //   sortedData.filter((data) => data.associate !== user.associateId)
              // );
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error:", err);
        })
    }
  };

  const CustomNameComponent = (row) => {
    return (
      <Tooltip title="View Timesheet">
        <Link
          sx={{ cursor: "pointer" }}
          underline="hover"
          onClick={() => {
            navigate("/home/timecard/timesheet", {
              state: { timesheet: row.data, project: project.length > 1 ? [row.data.projectShow] : project },
            });
          }}
        >
          {row.data.associatename}
        </Link>
      </Tooltip>
    );
  };

  const CustomProjectComponent = (row) => {
    return (
      project.length > 1 ? <>
        {row.data.projectShow}
      </> : <>{project[0]}</>
    );
  }


  const colDefs = [
    {
      field: "associate",
      headerName: "Associate Id",
      filter: true,
      flex: 0.5,
    },
    {
      field: "associatename",
      headerName: "Associate Name",
      filter: true,
      flex: 1,
      cellRenderer: CustomNameComponent,
    },
    {
      headerName: "Project",
      filter: true,
      flex: 1,
      cellRenderer: CustomProjectComponent,
    },
    {
      field: "startDate",
      headerName: "Start date",
      filter: true,
      flex: 0.7,
      valueFormatter: (startDate) =>
        moment(startDate.value, "YYYY,MM,DD").format("DD-MM-YYYY"),
    },
    {
      field: "endDate",
      headerName: "End date",
      filter: true,
      flex: 0.7,
      valueFormatter: (endDate) =>
        moment(endDate.value, "YYYY,MM,DD").format("DD-MM-YYYY"),
    },
    {
      field: "totalWorkingHours",
      headerName: "Hours",
      filter: true,
      flex: 0.7,
      valueFormatter: (endDate) =>
        `${Math.floor(endDate.value / 3600)}:${Math.floor((endDate.value % 3600) / 60)}`,
    },
    {
      field: "status", headerName: "Status", filter: true, flex: 1, cellRenderer: CustomStatusComponent,
    },
    sortBy === "SUBMITTED" || sortBy === "ALL"
      ? {
        field: "Action",
        headerName: "Action",
        type: "text",
        cellRenderer: CustomButtonComponent,
        flex: 1,
      } : ""
    ,
  ];


  const handleSearchChange = (e) => {
    const searchItem = e.target.value;
    setSearchTerm(searchItem);
    const filteredRes = res.filter((item) =>
      `${item.associatename}`
        .toLowerCase()
        .includes(searchItem.toLowerCase())
    );
    setFilteredElements(filteredRes);

    if (filteredRes.length === 0) {
      setFilteredElements(
        res.filter((item) =>
          `${item.associate}`
            .toLowerCase()
            .includes(searchItem.toLowerCase())
        )
      );
    }
  };

  return (
    <Box>
      <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={12}>
        <Grid item xs={12}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#029a9c",
              },
              "& .Mui-selected": {
                color: "#000000  !important",
              },
            }}
          >

            <Tab
              label={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <EventNote fontSize="small" sx={{ color: "#437676" }} />
                  <Typography
                    style={{ color: "#016466", fontSize: '17px', fontFamily: "Lucida Grande" }}
                    sx={{ ml: 0.5 }}
                  >
                    Associate TimeCards
                  </Typography>
                </Box>
              }
              value={0}
            />
            <Tab
              label={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <AccountBalance fontSize="small" sx={{ color: "#437676" }} />
                  <Typography
                    sx={{ ml: 0.5, color: "#016466", fontSize: '17px', fontFamily: "Lucida Grande" }}
                  >
                    My TimeCards
                  </Typography>
                </Box>
              }
              value={1}
            />
          </Tabs>
          <Card
            elevation={0}
            style={{ backgroundColor: "#FFFFFF", padding: "16px" }}
          >
            <CardContent>
              {tabValue === 0 && (
                <>
                  {
                    <Grid container item xs={12}>
                      {user.roles[0] === "Project Manager" ? (
                        <Grid
                          spacing={2}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            // alignItems: "center",
                          }}
                        >
                          <Typography mt={1} sx={{ fontSize: '17px', fontFamily: "Lucida Grande", color: '#016466' }}>
                            Associate Timecards
                          </Typography>
                          &nbsp; &nbsp; &nbsp; &nbsp;


                          <TextField
                            select
                            size="small"
                            // value={sortBy}
                            defaultValue={projects[0]?.title}
                            InputProps={{ style: { font: "small-caption", fontFamily: "Lucida Grande" } }}
                            onChange={(e) => {
                              const selectedProject = projects.find(data => data.title === e.target.value);
                              setProject(selectedProject.title);
                              if (e.target.value === "All") {
                                const allProjects = user.projects.map(project => project.title);
                                setProject(allProjects);
                                setType("All");
                              } else {
                                setType("single");
                                setProject([e.target.value])
                              }
                            }}
                          >
                            {projects.map((item) => (
                              <MenuItem
                                sx={{ font: "small-caption", fontFamily: "Lucida Grande" }}
                                key={item}
                                value={item.title}
                              >
                                {item.title}
                              </MenuItem>
                            ))}
                          </TextField>
                          &nbsp; &nbsp;
                          <TextField
                            select
                            size="small"
                            value={sortBy}
                            InputProps={{
                              style: { font: "small-caption", fontFamily: "Lucida Grande" }


                            }}
                            onChange={(e) => {
                              setSortBy(e.target.value);
                              setFilteredElements(
                                res.filter((data) =>
                                  data["status"].includes(e.target.value)
                                )
                              );
                            }}
                          >
                            {SORT_TASKS.map((item) => (
                              <MenuItem
                                sx={{ font: "small-caption", fontFamily: "Lucida Grande" }}

                                key={item}
                                value={item.value}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </TextField>
                          &nbsp; &nbsp;
                          <TextField
                            value={searchTerm}
                            label="Search by name or Id"
                            size="small"
                            inputProps={{ style: { font: "small-caption", fontFamily: "Lucida Grande" } }}
                            placeholder="Type to search"
                            onChange={handleSearchChange}
                            InputLabelProps={{ font: "small-caption", style: { fontSize: 13, fontFamily: "Lucida Grande" } }}
                          />
                          &nbsp; &nbsp;
                          <Tooltip title="click to go to previous month">
                            <IconButton onClick={(e) => handleStartDateChange(e, "icon")}>
                              <ArrowBackIosIcon
                                fontSize="small"
                              />
                            </IconButton>
                          </Tooltip>
                          <TextField
                            type="date"
                            value={startDate}
                            label="Start Date"
                            size="small"
                            error={
                              startDate &&
                                new Date(startDate) > new Date(endDate)
                                ? "Start date cannot be after end date"
                                : ""
                            }
                            helperText={
                              startDate &&
                                new Date(startDate) > new Date(endDate)
                                ? "Start date cannot be after end date"
                                : ""
                            }
                            inputProps={{
                              style: { font: "small-caption", fontFamily: "Lucida Grande" },
                              max: moment(new Date()).endOf('month').format("YYYY-MM-DD")
                            }}
                            onChange={handleStartDateChange}
                            InputLabelProps={{
                              shrink: true,
                              style: { fontSize: '16px', fontFamily: "Lucida Grande" },
                            }}
                          />
                          &nbsp; &nbsp;
                          <TextField
                            type="date"
                            value={endDate}
                            label="End Date"
                            size="small"
                            error={
                              startDate &&
                                new Date(endDate) < new Date(startDate)
                                ? "End date cannot be before start date"
                                : ""
                            }
                            helperText={
                              startDate &&
                                new Date(endDate) < new Date(startDate)
                                ? "End date cannot be before start date"
                                : ""
                            }
                            inputProps={{
                              style: { font: "small-caption", fontFamily: "Luicda Grande" },
                              max: moment(new Date()).endOf('month').format("YYYY-MM-DD")
                            }}
                            onChange={handleEndDateChange}
                            InputLabelProps={{
                              shrink: true,
                              style: { fontSize: '16px', fontFamily: "Lucida Grande" },
                            }}
                          />
                          <Tooltip title="click to go to next month">
                            <IconButton onClick={(e) => handleEndDateChange(e, "icon")}>
                              <ArrowForwardIosIcon
                                fontSize="small"
                              />
                            </IconButton>
                          </Tooltip>
                          {/* <Link
                            sx={{ cursor: 'pointer' }}
                            onClick={() => { navigate("/home/task/UserAppraisalForm") }}>User Appraisal</Link> */}
                        </Grid>

                      ) : (
                        <Grid
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          My Timecards &nbsp; &nbsp;
                        </Grid>
                      )}
                    </Grid>
                  }

                  {loading ? (
                    <Grid sx={{ textAlignLast: "center" }} item xs={12}>
                      <Box
                        style={{ paddingTop: "50px", paddingBottom: "40px" }}
                      >
                        <CircularProgress />
                      </Box>
                    </Grid>
                  ) : res.length === 0 ? (
                    <Grid sx={{ textAlignLast: "center" }} item xs={12}>
                      <Box
                        style={{ paddingTop: "50px", paddingBottom: "40px" }}
                      >
                        <Typography

                          style={{ fontFamily: "Lucida Grande" }}
                        >
                          No Timecards to show.
                        </Typography>
                      </Box>
                    </Grid>
                  ) : (
                    <div
                      className="ag-theme-alpine" // applying the grid theme
                      style={{ height: 380, marginTop: "20px", fontFamily: "Lucida Grande" }} // the grid will fill the size of the parent container
                    >
                      <AgGridReact
                        rowData={filteredElements}
                        columnDefs={colDefs}
                        // domLayout="normal" // Set domLayout to "normal" for fixed headers
                        // style="width: 500px; height: 500px;"
                        defaultColDef={{
                          filter: true,
                          resizable: 'true',
                          width: 150,
                          headerClass: "custom-header",
                        }}
                      />
                    </div>
                  )}
                  {/* {res.length >= 5 ? (
                    <Link
                      // fullWidth
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                        fontFamily: "Lucida Grande",
                        marginTop: "5px",
                      }}
                      onClick={() => setPageSize(pageSize + 10)}
                    // style={{ color: "white" }}
                    >
                      Show More
                    </Link>
                  ) : null} */}
                </>
              )}
              {tabValue === 1 && <MyTimeCards />}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}

      >
        <DialogTitle
          sx={{
            backgroundColor: "teal",
            color: "white",
            textAlign: "center",
            marginBottom: "8px",
            padding: "8px",
          }}
        >
          Rejection Reason!!!
        </DialogTitle>
        <DialogContent>
          <TextField autoFocus
            margin="dense"
            id="rejectMessage"
            label="Rejection Reason"
            type="text"
            fullWidth
            onChange={(e) => setRejectMessage(e.target.value)}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <>
            <Button
              size="small"
              sx={{ color: "white" }}
              onClick={() => {
                setIsDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              sx={{ color: "white" }}
              onClick={() => {
                // setLoading(true);
                setIsDialogOpen(false);
                handleReject(timesheetToReject);
              }}
            >
              Submit
            </Button>
          </>

        </DialogActions>
      </Dialog>

      {/* </Card> */}
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(!snackbarOpen)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >

        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    </Box >
  );
};

export default TimeCards;
