import { associateFields } from "./Fields";

export const getAssociateFields = (keys = []) => {
    let fields = [];
    keys.forEach((key) => {
        const field = associateFields[key];
        if (!fields.includes(field)) {
            fields.push(field);
        }
    });
    return fields;
}