import { ChangeCircle, People } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { Alert, Avatar, Box, Grid, IconButton, LinearProgress, Menu, MenuList, Snackbar, Toolbar, Tooltip, Typography } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EdvLogo from "../../assets/edvenswa_logo.png";
import AuthLogo from "../../assets/emportal_logo.png";
import { axiosInstance } from "../../interceptors/AxiosInterceptor";
import { doLogout } from "../edvenswa.emportal.auth/actions/Action";
import { AUTH_SECURE_USER_DETAILS_LS_LEY } from "../edvenswa.emportal.auth/constants/Constants";
import BasicBreadcrumbs from "../edvenswa.emportal.common/breadcrumbs/BasicBreadcrumbs";
import { getResources } from "../edvenswa.emportal.sidebar/factory/ResourceFactory";


Layout.propTypes = {
  component: PropTypes.elementType.isRequired,
};

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));


const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const LOGOUT_ACTION = "LOGOUT";
const SETTTINGS_MENU_ITEMS = [
  {
    label: "Profile",
    navigateTo: "/profile",
    icon: <People />,
  },
  {
    label: "Change Password",
    navigateTo: "/changepassword",
    icon: <ChangeCircle />,
  },
  {
    label: "Logout",
    action: LOGOUT_ACTION,
    icon: <Logout />,
  },
];

export default function Layout(props) {
  const user = JSON.parse(sessionStorage.getItem(AUTH_SECURE_USER_DETAILS_LS_LEY));
  const { title, path } = props;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState("error");
  useEffect(() => {
    if (user.roles[0] === "Admin") {
      if (localStorage.getItem("companies") === null) {
        axiosInstance.get("/tenant/getAll").then(res => {
          localStorage.setItem("companies", JSON.stringify(res.data))
        })
          .catch(err => console.log(err))
      }
    }
    // if (user.roles[0] === "Project Manager") {
    //   if (localStorage.getItem("projects") === null) {
    //     axiosInstance.get("/user/findByprojectManager").then(res => {
    //       localStorage.setItem("projects", JSON.stringify(res.data))
    //     })
    //       .catch(err => console.log(err))
    //   }
    // }

  }, [])

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState();
  const [load, setLoad] = useState(false);
  const MainComponent = props.component;
  const Companies = JSON.parse(
    localStorage.getItem("companies")
  );
  const [image, setImage] = useState();
  const navigate = useNavigate();
  const [sidebarItems, setSidebarItems] = useState([]);
  const [closingMenu, setClosingMenu] = useState(false);
  const currentPath = window.location.hash.slice(window.location.hash.indexOf("#") + 1);

  useEffect(() => {
    if (sidebarItems.length === 0) {
      setSidebarItems(getResources(user?.roles));
    }
    if (user.logo && user.logo !== "null") {
      setImage(user?.logo);
    }
  }, [user, sidebarItems, image, user]);

  useEffect(() => {
    if (closingMenu && !Boolean(anchorEl)) {
      setClosingMenu(false);
    }
  }, [anchorEl, closingMenu]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };
  const handleLogoutClick = () => {
    doLogout();
    navigate("/");
  };

  const handleNavigation = (action, navigateTo) => {
    setAnchorEl(null);
    if (action === LOGOUT_ACTION) {
      handleLogoutClick();
      return;
    }
    navigate(navigateTo);
    setClosingMenu(true);
  };

  const handleError = (error, severity = "error") => {
    setSnackbarOpen(!snackbarOpen);
    setSeverity(severity);
    if (error?.response && error.response?.data) {
      // application specific error
      setMessage(error?.response?.data);
    } else {
      // generic axios error
      setMessage(error.message);
    }
  };

  const handleSuccess = (message, severity = "success") => {
    setSnackbarOpen(!snackbarOpen);
    setSeverity(severity);
    setMessage(message);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ display: "flex" }}>
          <AppBar
            position="fixed"
            open={open}
            sx={{ backgroundColor: "#FFFFFF", borderBottom: "0.5px solid #2998FF" }}
          >
            <Toolbar sx={{ justifyContent: "space-between" }}>
              <IconButton
                color="#2381D9"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Grid container item xs={11} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Grid item xs={7}>
                  <img src={EdvLogo} alt={AuthLogo} width={"12%"} loading='lazy'></img>
                </Grid>
                <Grid item xs={4} >
                  <img src={AuthLogo} alt={AuthLogo} width={"47%"} loading="lazy"></img>
                </Grid>
              </Grid>
              <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>              <Typography fontFamily='Lucida grande' style={{ color: "#016466" }} sx={{ fontSize: '16px' }}>
                {user?.firstname} {user?.lastname}
              </Typography>
                <IconButton sx={{ display: 'flex', alignItems: 'right', color: "#016466" }} onClick={handleOpenUserMenu} >
                  <KeyboardArrowDownIcon ></KeyboardArrowDownIcon>
                </IconButton>
              </Grid>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open Settings">
                  <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
                    <Avatar
                      imgProps={{ loading: 'lazy' }}
                      src={
                        image
                          ? image
                          : require("../../assets/emportal_default_user.png")
                      }
                      alt={user?.username}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseUserMenu}
              >
                <MenuList>
                  {SETTTINGS_MENU_ITEMS.map((SETTING_MENU_ITEM, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        handleNavigation(
                          SETTING_MENU_ITEM.action,
                          SETTING_MENU_ITEM.navigateTo
                        );
                      }}
                    >
                      <ListItemIcon>{SETTING_MENU_ITEM.icon}</ListItemIcon>
                      <ListItemText>{SETTING_MENU_ITEM.label}</ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Toolbar>
            {load ? <LinearProgress /> : <></>}
          </AppBar>
          <Drawer
            variant="permanent"
            hideBackdrop
            open={open}
            sx={{ backgroundColor: "#FFFFFF" }}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider sx={{ borderRight: "0.5px solid #2998FF" }} />
            <List>
              {sidebarItems.map((item, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() => navigate(item.path)}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.title}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>

          <Box component="main" sx={{ flexGrow: 1, marginTop: '50px', p: 2 }}>
            {title && path ? (
              <BasicBreadcrumbs title={title} path={path} currentPath={currentPath}></BasicBreadcrumbs>
            ) : (
              <></>
            )}
            <MainComponent
              {...props}
              Companies={Companies}
              title={title}
              path={path}
              user={user}
              projects={user?.projects}
              onError={handleError}
              onSuccess={handleSuccess}
              load={load}
              setLoad={setLoad}
            />
          </Box>
        </Box>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(!snackbarOpen)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    </Grid>
  );
}
