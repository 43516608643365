import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import { AUTH_SECURE_USER_DETAILS_LS_LEY } from "../../edvenswa.emportal.auth/constants/Constants";
import {
  DATE_FORMAT,
  END_DATE_FORMAT,
  START_DATE_FORMAT,
} from "../constants/Constants";
import TimeCards from "./Timecards";

const TimeCard = (props) => {
  const location = useLocation();
  const [res, setRes] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const companies = props.Companies;
  const [selectedCompany, setSelectedCompany] = useState(
    location?.state?.user?.tenant || ""
  );
  const user = JSON.parse(
    sessionStorage.getItem(AUTH_SECURE_USER_DETAILS_LS_LEY)
  );
  const [companyId, setCompanyId] = useState(user.tenantId);
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState("error");
  const date = DATE_FORMAT;
  const page = 1;
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const SORT_TASKS = [
    {
      name: "Approved",
      value: "APPROVED",
    },
    {
      name: "Pending At Associate",
      value: "PENDING",
    },
    {
      name: "Submitted",
      value: "SUBMITTED",
    },
  ];
  const [sortBy, setSortBy] = useState("SUBMITTED");
  const [filteredElements, setFilteredElements] = useState([]);

  const [startDate, setStartDate] = useState(START_DATE_FORMAT);
  const [endDate, setEndDate] = useState(END_DATE_FORMAT);
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [rejectmessage, setRejectMessage] = useState('')
  const [timesheetToReject, setTimesheetToReject] = useState(null);

  const role = user.roles[0];

  const handleStartDateChange = (e, type) => {
    if (type === "icon") {
      const newStartDate = moment(startDate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      const newEndDate = moment(endDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
    else {
      const value = e.target.value;
      setStartDate(value);
      setEndDateError("");
      if (value && endDate && value > endDate) {
        setStartDateError("Start date cannot be after end date");
      } else {
        setStartDateError("");
      }
    }
  };

  const handleEndDateChange = (e, type) => {
    if (type === "icon") {
      const newStartDate = moment(startDate).add(1, 'month').startOf('month').format('YYYY-MM-DD');
      const newEndDate = moment(endDate).add(1, 'month').endOf('month').format('YYYY-MM-DD');
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
    else {
      const value = e.target.value;
      setEndDate(value);
      setStartDateError("");
      if (value && startDate && value < startDate) {
        setEndDateError("End date cannot be before start date");
      } else {
        setEndDateError("");
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    if (startDateError === "" && endDateError === "") {
      if (role === "Admin") {
        // axiosInstance
        //   .put(`/timesheet/getAlltimesheetbydatecount`, {
        //     startDate: startOfWeek,
        //     endDate: endOfWeek,
        //   })
        //   .then((res) => {
        //     const totalCount = res.data;
        //     const totalPages = Math.ceil(totalCount / pageSize);
        //     setTotalPages(totalPages);
        //   });

        axiosInstance
          .put(
            `/timesheet/getAllbyDate?sortBy=${sortBy}&tenantId=${companyId}`,
            {
              startDate: startDate,
              endDate: endDate,
            }
          )
          .then((res) => {
            const sortedData = res.data.sort((a, b) => {
              return new Date(b.startDate) - new Date(a.startDate);
            });
            setRes(sortedData);
            setFilteredElements(sortedData);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      }
      else {
        axiosInstance
          .put(`/timesheet/getbyUser?page=${page}&size=${pageSize}`, {
            startDate: startDate,
            endDate: endDate,
          })
          .then((res) => {
            setRes(res.data);
            setFilteredElements(res.data);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      }
    }
    else {
      setFilteredElements([]);
      setRes([]);
      setLoading(false);
    }
  }, [date, pageSize, sortBy, startDate, endDate]);

  console.log(startDate, "startDate");
  console.log(endDate, "endDate")


  let obj = [];
  res?.length > 0 && res?.map((t) => obj.push(t.associate));

  const CustomButtonComponent = (props) => {
    console.log(props.data);
    return (
      <Stack
        direction={"row"}
        spacing={1}
        sx={{ display: "flex", alignItems: "center" }}
      >
        {role === "Admin" &&
          props.data.status !== "APPROVED" &&
          props.data.status !== "PENDING" ? (
          <>
            <Tooltip title="Approve">
              <IconButton
                onClick={() => {
                  handleApprove(props.data);
                }}
              >
                <CheckCircleOutlineIcon
                  sx={{ color: "#1dbb68" }}
                  fontSize="15"
                  color="#029a9c"
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reject">
              <IconButton
                onClick={() => {
                  setIsDialogOpen(true);
                  // handleReject(props.data);
                  setTimesheetToReject(props.data)
                }}
              >
                <CancelIcon
                  sx={{ color: "#e74c3c" }}
                  fontSize="15"
                  color="#e74c3c"
                />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <></>
        )}
      </Stack>
    );
  };



  console.log(props.data);

  const handleApprove = (row) => {
    if (role === "Admin" && row.status === "SUBMITTED") {
      setLoading(true);
      axiosInstance
        .put("/timesheet/approvestatus?role=Admin", {
          timesheetId: row.timesheetId,
        })
        .then((res) => {
          axiosInstance
            .put(
              `/timesheet/getAllbyDate?sortBy=${sortBy}&tenantId=${companyId}`,
              {
                startDate: startDate,
                endDate: endDate,
              }
            )
            .then((res) => {
              setRes(res.data);
              setSnackbarOpen(true);
              setMessage("Timesheet is Approved Successfully!");
              setSeverity("success");
              setFilteredElements(res.data);
            })
            .catch((err) => {
              console.error("Error:", err);
            })
            .finally(() => {
              setLoading(false);
            });
        });
    }
  };

  const handleReject = (row) => {
    console.log(row)
    if (role === "Admin" && row.status === "SUBMITTED") {
      setLoading(true);
      let updatedFormData = [];
      let taskDataObjSubmitted = {};
      JSON.parse(row.tasks).map((data) => {
        taskDataObjSubmitted = { ...data, status: 'PENDING' }
        updatedFormData.push(taskDataObjSubmitted)
      })
      console.log(updatedFormData);
      axiosInstance
        .put("/timesheet/rejectstatus?role=Admin", {
          timesheetId: row.timesheetId,
          tasks: JSON.stringify(updatedFormData),
          message: rejectmessage
        })
        .then((res) => {
          setRes(res.data);
          setSnackbarOpen(true);
          setMessage("Timesheet is Rejected Successfully!");
          setSeverity("success");
          if (res) {
            axiosInstance
              .put(
                `/timesheet/getAllbyDate?sortBy=${sortBy}&tenantId=${user.tenantId}`,
                {
                  startDate: startDate,
                  endDate: endDate,
                }
              )
              .then((res) => {
                setRes(res.data);
                console.log(res.data, "res.datata");
                setFilteredElements(res.data);
              });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const CustomNameComponent = (row) => {
    return (
      <Tooltip title="View Timesheet">
        <Link
          sx={{ cursor: "pointer" }}
          underline="hover"
          onClick={() => {
            navigate("/home/timecard/timesheet", {
              state: { timesheet: row.data },
            });
          }}
        >
          {row.data.associatename}
        </Link>
      </Tooltip>
    );
  };

  const CustomStatusComponent = (row) => {
    const partially_approved_partially_rejects = ((row.data.status !== "APPROVED" && JSON.parse(row.data.tasks).some((data) => data.status === "SUBMITTED")) &&
      (row.data.status !== "PENDING" && JSON.parse(row.data.tasks).some((data) => data.status === "PENDING")))
    if (partially_approved_partially_rejects) {
      return (
        <Typography mt={1.02}>
          PARTIALLY APPROVED AND PARTIALLY REJECTED
        </Typography >
      );
    }
    else if (row.data.status !== "APPROVED" && JSON.parse(row.data.tasks).some((data) => data.status === "SUBMITTED")) {
      return (
        <Typography mt={1.02}>
          PARTIALLY APPROVED
        </Typography >
      );
    }
    else if (row.data.status !== "PENDING" && JSON.parse(row.data.tasks).some((data) => data.status === "PENDING")) {
      return (
        <Typography mt={1.02}>
          PARTIALLY REJECTED
        </Typography>
      );
    }
    else {
      return (
        <Typography mt={1.02}>
          {row.data.status}
        </Typography >
      );
    }

  };

  const colDefs = [
    {
      field: "associate",
      headerName: "Associate Id",
      filter: true,
      flex: 0.5,
      // valueGetter: (params) =>
      //   params.data.associate ? params.data.associate.associateId : "",
    },
    {
      field: "associatename",
      headerName: "Associate Name",
      filter: true,
      flex: 1,
      cellRenderer: CustomNameComponent,
      // valueGetter: (params) =>
      //   params.data.associate ? params.data.associate.fullname : "",
    },
    // { field: "category", headerName: "Category", filter: true, flex: 1 },
    {
      field: "startDate",
      headerName: "Start date",
      filter: true,
      flex: 0.7,
      valueFormatter: (startDate) =>
        moment(startDate.value, "YYYY,MM,DD").format("DD-MM-YYYY"),
    },
    {
      field: "endDate",
      headerName: "End date",
      filter: true,
      flex: 0.7,
      valueFormatter: (endDate) =>
        moment(endDate.value, "YYYY,MM,DD").format("DD-MM-YYYY"),
    },
    {
      field: "totalWorkingHours",
      headerName: "Hours",
      filter: true,
      flex: 0.5,
      valueFormatter: (endDate) =>
        `${Math.floor(endDate.value / 3600)}:${Math.floor((endDate.value % 3600) / 60)}`,
    },

    { field: "status", headerName: "Status", filter: true, flex: 0.8, cellRenderer: CustomStatusComponent, },

  ];
  if (sortBy === "SUBMITTED" && role !== "User") {
    colDefs.push({
      field: "Action",
      headerName: "Action",
      type: "text",
      cellRenderer: CustomButtonComponent,
      flex: 1,
    });
  }

  const handleSearchChange = (e) => {
    const searchItem = e.target.value;
    setSearchTerm(searchItem);

    const filteredRes = res.filter((item) =>
      `${item.associatename}`
        .toLowerCase()
        .includes(searchItem.toLowerCase())
    );
    setFilteredElements(filteredRes);

    if (filteredRes.length === 0) {
      setFilteredElements(
        res.filter((item) =>
          `${item.associate}`
            .toLowerCase()
            .includes(searchItem.toLowerCase())
        )
      );
    }
  };


  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}

      >
        <DialogTitle
          sx={{
            backgroundColor: "teal",
            color: "white",
            textAlign: "center",
            marginBottom: "8px",
            padding: "8px",
          }}
        >
          Rejection Reason!!!
        </DialogTitle>
        <DialogContent>
          <TextField autoFocus
            margin="dense"
            id="rejectMessage"
            label="Rejection Reason"
            type="text"
            fullWidth
            onChange={(e) => setRejectMessage(e.target.value)}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <>
            <Button
              size="small"
              sx={{ color: "white" }}
              onClick={() => {
                setIsDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              sx={{ color: "white" }}
              onClick={() => {
                // setLoading(true);
                setIsDialogOpen(false);
                console.log(props.data)
                handleReject(timesheetToReject);
              }}
            >
              Submit
            </Button>
          </>

        </DialogActions>
      </Dialog>
      {role === "Project Manager" ? (
        <TimeCards {...props} />
      ) : (
        <Box >
          <Card
            elevation={0}
            style={{ backgroundColor: "#FFFFFF", padding: "34px" }}
          >
            <Grid container item xs={12}>
              {user.roles[0] === "Admin" ? (
                <Grid
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    // alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: '17px', fontFamily: "Lucida Grande", color: '#016466' }}>
                    Associate Timecards
                  </Typography>
                  &nbsp; &nbsp;
                  <TextField
                    size="small"
                    select
                    defaultValue={user.tenant}
                    value={selectedCompany.name}
                    InputProps={{
                      style: {
                        font: "small-caption",
                        fontFamily: "Lucida Grande",
                      },
                    }}
                    onChange={(e) => {
                      setFilteredElements([]);
                      const company = companies.find(
                        (data) => data.name === e.target.value
                      );
                      setCompanyId(company.id);
                      axiosInstance
                        .put(
                          `/timesheet/getAllbyDate?sortBy=${sortBy}&tenantId=${company.id}`,
                          {
                            startDate: startDate,
                            endDate: endDate,
                          }
                        )
                        .then((res) => {
                          console.log(res);
                          setFilteredElements(res.data);
                          setRes(res.data);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                      setSelectedCompany(company);
                      setSearchTerm("")
                      console.log(company);
                    }}
                  >
                    {companies &&
                      companies?.length > 0 &&
                      companies.map((val) => (
                        <MenuItem
                          style={{
                            font: "small-caption",
                            fontFamily: "Lucida Grande",
                          }}
                          key={val}
                          value={val.name}
                        >
                          {val.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  {/* &nbsp; &nbsp;
                  <TextField
                    size="small"
                    select
                    value={sortBy1}
                    InputProps={{
                      style: {
                        font: "small-caption",
                        fontFamily: "Lucida Grande",
                      },
                    }}
                    onChange={(e) => {
                      // setSortBy(e.target.value);
                      // if (type === "Projects") {
                      //   setProjects([]);
                      // }
                      // if (type === "Associates") {
                      //   setUsers([]);
                      // }
                    }}
                  >
                    {SORT_TYPES1 &&
                      SORT_TYPES1?.length > 0 &&
                      SORT_TYPES1.map((val) => (
                        <MenuItem
                          style={{
                            font: "small-caption",
                            fontFamily: "Lucida Grande",
                          }}
                          key={val}
                          value={val.value}
                        >
                          {val.name}
                        </MenuItem>
                      ))}
                  </TextField> */}
                  &nbsp; &nbsp;
                  <TextField
                    select
                    size="small"
                    value={sortBy}
                    InputProps={{ style: { font: "small-caption", fontFamily: "Lucida Grande" } }}
                    onChange={(e) => {
                      setSortBy(e.target.value);
                    }}
                  >
                    {SORT_TASKS.map((item) => (
                      <MenuItem
                        style={{
                          font: "small-caption",
                          fontFamily: "Lucida Grande",
                        }}
                        key={item}
                        value={item.value}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  &nbsp; &nbsp;
                  <TextField
                    value={searchTerm}
                    label="Search by name or Id"
                    size="small"
                    inputProps={{ style: { font: "small-caption", fontFamily: "Lucida Grande" } }}
                    placeholder="Type to search"
                    onChange={handleSearchChange}
                    InputLabelProps={{ font: "small-caption", style: { fontSize: 13, fontFamily: "Lucida Grande" } }}
                  />
                  &nbsp; &nbsp;
                  <Tooltip title="click to go to previous month">
                    <IconButton onClick={(e) => handleStartDateChange(e, "icon")}>
                      <ArrowBackIosIcon
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                  <TextField
                    type="date"
                    value={startDate}
                    label="Start Date"
                    size="small"
                    // error={!!startDateError}
                    // helperText={startDateError}
                    error={
                      startDate &&
                        new Date(startDate) > new Date(endDate)
                        ? "Start date cannot be after end date"
                        : ""
                    }
                    helperText={
                      startDate &&
                        new Date(startDate) > new Date(endDate)
                        ? "Start date cannot be after end date"
                        : ""
                    }
                    inputProps={{
                      style: { font: "small-caption", fontFamily: "Luicda Grande" },
                      max: moment(new Date()).endOf('month').format('yyyy-MM-DD')
                    }}
                    onChange={handleStartDateChange}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '16px', fontFamily: "Lucida Grande" }
                    }}
                  />
                  &nbsp; &nbsp;
                  <TextField
                    type="date"
                    value={endDate}
                    label="End Date"
                    size="small"
                    // error={!!endDateError}
                    // helperText={endDateError}
                    error={
                      startDate &&
                        new Date(endDate) < new Date(startDate)
                        ? "End date cannot be before start date"
                        : ""
                    }
                    helperText={
                      startDate &&
                        new Date(endDate) < new Date(startDate)
                        ? "End date cannot be before start date"
                        : ""
                    }
                    inputProps={{
                      style: { font: "small-caption", fontFamily: "Luicda Grande" },
                      max: moment(new Date()).endOf('month').format('yyyy-MM-DD')
                    }}
                    onChange={handleEndDateChange}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '16px', fontFamily: "Lucida Grande" },

                    }}
                  />
                  <Tooltip title="click to go to next month">
                    <IconButton onClick={(e) => handleEndDateChange(e, "icon")}>
                      <ArrowForwardIosIcon
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : (
                <Grid
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    // alignItems: "center",
                  }}
                >
                  <Typography mt={1} sx={{ color: "#016466", fontSize: '17px', fontFamily: "Lucida Grande" }}>
                    My Timecards
                  </Typography>
                  &nbsp; &nbsp;
                  <Tooltip title="click to go to previous month">
                    <IconButton onClick={(e) => handleStartDateChange(e, "icon")}>
                      <ArrowBackIosIcon
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                  <TextField
                    type="date"
                    value={startDate}
                    label="Start Date"
                    size="small"
                    // error={!!startDateError}
                    // helperText={startDateError}
                    error={
                      startDate &&
                        new Date(startDate) > new Date(endDate)
                        ? "Start date cannot be after end date"
                        : ""
                    }
                    helperText={
                      startDate &&
                        new Date(startDate) > new Date(endDate)
                        ? "Start date cannot be after end date"
                        : ""
                    }
                    inputProps={{
                      style: { font: "small-caption", fontFamily: "Lucida Grande" },
                      max: moment(new Date()).endOf('month').format("YYYY-MM-DD")
                    }}
                    onChange={handleStartDateChange}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '16px', fontFamily: "Lucida Grande" },

                    }}
                  />
                  &nbsp; &nbsp;
                  <TextField
                    type="date"
                    value={endDate}
                    label="End Date"
                    size="small"
                    // error={!!endDateError}
                    // helperText={endDateError}
                    error={
                      startDate &&
                        new Date(endDate) < new Date(startDate)
                        ? "End date cannot be before start date"
                        : ""
                    }
                    helperText={
                      startDate &&
                        new Date(endDate) < new Date(startDate)
                        ? "End date cannot be before start date"
                        : ""
                    }
                    inputProps={{
                      style: { font: "small-caption", fontFamily: "Lucida Grande" },
                      max: moment(new Date()).endOf('month').format("YYYY-MM-DD")
                    }}
                    onChange={handleEndDateChange}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '16px', fontFamily: "Lucida Grande" },
                    }}
                  />
                  <Tooltip title="click to go to next month">
                    <IconButton onClick={(e) => handleEndDateChange(e, "icon")}>
                      <ArrowForwardIosIcon
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            {loading ? (
              <Grid sx={{ textAlignLast: "center" }} item xs={12}>
                <Box style={{ paddingTop: "50px", paddingBottom: "40px" }}>
                  <CircularProgress />
                </Box>
              </Grid>
            ) : res.length === 0 ? (
              <Grid sx={{ textAlignLast: "center" }} item xs={12}>
                <Box style={{ paddingTop: "50px", paddingBottom: "40px" }}>
                  <Typography

                    style={{ fontFamily: "Lucida Grande" }}
                  >
                    No Timecards to show.
                  </Typography>
                </Box>
              </Grid>
            ) : (
              <div
                className="ag-theme-alpine" // applying the grid theme
                style={{ height: 420, marginTop: "20px", fontFamily: "Lucida Grande" }} // the grid will fill the size of the parent container
              >
                <AgGridReact rowData={filteredElements} columnDefs={colDefs} domLayout="normal" // Set domLayout to "normal" for fixed headers
                  // style="width: 500px; height: 500px;"
                  defaultColDef={{
                    filter: true,
                    resizable: 'true',
                    width: 150,
                    headerClass: "custom-header",
                  }} />
              </div>
            )
            }
            {/* {res.length >= 5 ? (
              <Link
                // fullWidth
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                  fontFamily: "Lucida Grande",
                  marginTop: "5px",
                }}
                onClick={() => setPageSize(pageSize + 10)}
              // style={{ color: "white" }}
              >
                Show More
              </Link>
            ) : null} */}
          </Card>

          <Snackbar
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(!snackbarOpen)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={3000}
          >
            <Alert severity={severity}>{message}</Alert>
          </Snackbar>
        </Box>
      )}

    </>
  );
};

export default TimeCard;
