import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EastIcon from "@mui/icons-material/East";
import SaveIcon from "@mui/icons-material/Save";
import SyncIcon from '@mui/icons-material/Sync';
import jiraImage from '../../../assets/jira3124.jpg'
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  setRef,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import dayjs from "dayjs";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import TaskPopover from "./TaskPopover";
import axios from "axios";
import { AUTH_SECURE_USER_DETAILS_LS_LEY, client_id, client_secret, FRONT_END_URL, redirect_uri } from "../../edvenswa.emportal.auth/constants/Constants";
import { DeleteOutline } from "@mui/icons-material";
const Timesheet = (props) => {



  const today = new Date();
  const location = useLocation();
  const [date, setDate] = useState(() => {
    if (location?.state?.startDate) {
      return moment(location?.state?.startDate, "YYYY,MM,DD").format(
        "YYYY-MM-DD"
      );
    } else if (location?.state?.timesheet) {
      return moment(location?.state?.timesheet.startDate, "YYYY,MM,DD").format(
        "YYYY-MM-DD"
      );
    } else {
      return moment(today).format("YYYY-MM-DD");
    }
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState("error");
  const [taskData, settaskData] = useState([]);
  const [timesheet, setTimesheet] = useState({});
  const [weekDates, setWeekDates] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false);
  const [isHolidayDialogOpen, setIsHolidayDialogOpen] = useState(false);
  const { user, projects } = props;
  const navigate = useNavigate("");
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [rejectmessage, setRejectMessage] = useState('')
  const [timesheetToReject, setTimesheetToReject] = useState(null);
  const [updatingMessage, setUpdatingMessage] = useState("");
  const [refreshButtonClicked, setRefreshButtonClicked] = useState(false);
  // let updatingMessage="";

  const selectedDate = moment(date);
  let startOfWeek;
  if (selectedDate.day() === 0) {
    // If selected date is Sunday, go to previous week's Sunday
    startOfWeek = selectedDate
      .subtract(1, "week")
      .startOf("week")
      .add(1, "day")
      .format("yyyy-MM-DD");
  } else {
    // For other days, start from the current week's Monday
    startOfWeek = selectedDate
      .startOf("week")
      .add(1, "day")
      .format("yyyy-MM-DD");
  }

  const startDate = moment(startOfWeek).format("yyyy-MM-DD");
  const endDate = moment(startOfWeek).add(6, "days").format("yyyy-MM-DD");
  const [formData, setFormData] = useState([]);
  const [errors, setErrors] = useState({});
  const [taskAnchorEl, setTaskAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  let submitted = null;
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(0);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [exceededDates, setExceededDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saveloading, setSaveLoading] = useState(false);
  const [submitloading, setSubmitLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const associateId = location?.state !== null ? location?.state?.timesheet?.associate : user.associateId;
  const [refresh, setRefresh] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [jiraDomain, setJiraDomain] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState("");
  const [tokenAPISuccess, setTokenAPISuccess] = useState(false);
  // const [maxWidth, setMaxwidth] = useState(0)

  // let selectedDomain = {}

  const [cloudId, setCloudId] = useState();

  const dates = {
    startDate: startDate,
    endDate: endDate,
  };


  const role = user.roles[0];

  useEffect(() => {
    props.setLoad(true);
    setLoading(true);
    // if (tokenAPISuccess) {
    //   console.log("follow the token api");
    // }
    // else {
    let cloud_Id = ""
    if (localStorage.getItem("token") !== null) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      };
      axios.get(
        'https://api.atlassian.com/oauth/token/accessible-resources',
        config
      ).then(res => {
        setJiraDomain(res.data)
        setSelectedDomain(res?.data?.[0]?.name)
        setCloudId(res?.data?.[0]?.id)
        cloud_Id = res?.data?.[0]?.id
        if (refresh) {
          axiosInstance.post(`/task/basicAuth?token=${localStorage.getItem("token")}&cloudId=${res?.data?.[0]?.id}`, { startDate: startDate, endDate: endDate }).then(res => {
            setRefresh(!refresh)
            handleWeeksAndHolidays()
            // handleTimesheetAPI()
          }).catch((err) => {
            console.log(err);
          })
        }
        else {
          handleWeeksAndHolidays()
          // handleTimesheetAPI()
        }
      }
      ).catch();
    }
    else {
      handleWeeksAndHolidays()
      // handleTimesheetAPI()
    }
    // }
  }, [date, submitted, refresh]);

  const handleWeeksAndHolidays = () => {
    axiosInstance
      .get(`/timesheet/getweekDays?date=${date}`)
      .then((res) => {
        setWeekDates(res.data);
        handleTimesheetAPI();
      })
      .catch((err) => {
        console.log(err);
      });
    axiosInstance
      .put("/holiday/duration", dates)
      .then((res) => {
        setHolidays(res.data);
        props.setLoad(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleTimesheetAPI() {
    if (date !== "") {
      if (location?.state == null) {//after clicking direct timesheet for users and project manager as user for filling timesheet
        axiosInstance
          .put("/timesheet/get", { startDate: startDate, endDate: endDate })
          .then((res) => {
            if (Object.keys(res.data).length === 0) {
              setTimesheet({});
              settaskData([]);
              setFormData([]);
            } else {
              setTimesheet(res.data);
              const sortedArray = JSON.parse(res.data.tasks).sort(
                (a, b) =>
                  moment(a.startDate, "YYYY,MM,DD").diff(
                    moment(b.startDate, "YYYY,MM,DD")
                  )
              );
              settaskData(sortedArray);
              setFormData(sortedArray);
            }
            props.setLoad(false);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      }

      else {
        //project manager for viewing timesheets of user
        if (
          role === "Project Manager" &&
          user.associateId !== associateId
        ) {
          axiosInstance
            .put("/timesheet/getTimesheetbyusers", {
              startDate: startDate,
              endDate: endDate,
              associate: associateId,
            })
            .then((res) => {
              if (Object.keys(res.data).length === 0) {
                setTimesheet({});
                settaskData([]);
                setFormData([]);
              } else {
                setTimesheet(res.data);
                const sortedArrayByProject = JSON.parse(
                  res.data.tasks
                ).filter((data) => data.project === location?.state?.project[0]);

                const sortedArray = sortedArrayByProject.sort(
                  (a, b) =>
                    moment(a.startDate, "YYYY,MM,DD").diff(
                      moment(b.startDate, "YYYY,MM,DD")
                    )
                );
                settaskData(sortedArray);
                setFormData(sortedArray);
              }
              setLoading(false);
              props.setLoad(false);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {// admin viewing timesheets of users and user viewing timesheets of self
          axiosInstance
            .put("/timesheet/getTimesheetbyusers", {
              startDate: startDate,
              endDate: endDate,
              associate: associateId,
            })
            .then((res) => {
              if (Object.keys(res.data).length === 0) {
                setTimesheet({});
                settaskData([]);
                setFormData([]);
              }
              else {
                setTimesheet(res.data);
                const sortedArray = JSON.parse(res.data.tasks).sort(
                  (a, b) =>
                    moment(a.startDate, "YYYY,MM,DD").diff(
                      moment(b.startDate, "YYYY,MM,DD")
                    )
                );
                settaskData(sortedArray);
                setFormData(sortedArray);
              }
              props.setLoad(false);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
    else {
      setTimesheet({});
      settaskData([]);
      setFormData([]);
      props.setLoad(false);
      setLoading(false);
    }

  }

  const URLcode = window.location.href;
  const code = URLcode.slice(URLcode.indexOf('eyJhb'), URLcode.indexOf('#/home'));
  if (URLcode.length > 600 && localStorage.getItem("token") === null) {
    sessionStorage.setItem(AUTH_SECURE_USER_DETAILS_LS_LEY, JSON.stringify(user));
    const tokenBody = {
      "grant_type": "authorization_code",
      "client_id": `${client_id}`,
      "client_secret": `${client_secret}`,
      "code": `${code}`,
      "redirect_uri": `${FRONT_END_URL}/timecard/timesheet`
    }
    axios.post("https://auth.atlassian.com/oauth/token", tokenBody).then(res => {
      const token = res.data.access_token;
      localStorage.setItem("token", token);
      setUpdatingMessage("Timesheet is Updating... Please wait for a while...")
      setLoading(true);
      setTokenAPISuccess(true);
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      axios.get(
        'https://api.atlassian.com/oauth/token/accessible-resources',
        config
      ).then(response => {
        setJiraDomain(response.data);
        setSelectedDomain(response?.data?.[0]?.name);
        let cloud_Id = response?.data?.[0]?.id;
        setCloudId(response?.data?.[0]?.id);
        axiosInstance.post(`/task/basicAuth?token=${token}&cloudId=${cloud_Id}`, { startDate: startDate, endDate: endDate }).then(res => {
          // handleTimesheetAPI()
          props.setLoad(true)
          setLoading(true)
          handleWeeksAndHolidays()
        }).catch((err) => {
          console.log(err);
        })
      }
      ).catch();
    }
    ).catch(err => {
      console.log(err.data);
    })
  }


  let FormDataObj = {};
  let invalidDates = [];
  const handleChange = (seconds, taskIndex, task, week) => {
    const updatedFormData = [...formData];

    if (formData[taskIndex].workedDayHours !== null) {
      FormDataObj = {
        ...FormDataObj,
        ...updatedFormData[taskIndex].workedDayHours,
      };
      FormDataObj = {
        ...FormDataObj,
        [moment(week, "YYYY,MM,DD").format("DD-MM-YYYY")]: seconds,
      };
      updatedFormData[taskIndex].workedDayHours = FormDataObj;
    } else {
      FormDataObj = {
        ...FormDataObj,
        [moment(week, "YYYY,MM,DD").format("DD-MM-YYYY")]: seconds,
      };
      updatedFormData[taskIndex].workedDayHours = FormDataObj;
    }
    updatedFormData[taskIndex].task = null;
    setFormData(updatedFormData);

    let ss = 0;
    formData.map((task) => {
      if (
        task?.workedDayHours &&
        task?.workedDayHours !== null &&
        task.workedDayHours[moment(week, "YYYY,MM,DD").format("DD-MM-YYYY")] !==
        undefined
      ) {
        ss +=
          task?.workedDayHours[moment(week, "YYYY,MM,DD").format("DD-MM-YYYY")];
      }
    });
    if (!isNaN(ss) && ss > 12 * 3600) {
      if (
        !exceededDates.find(
          (d) => d === moment(week, "YYYY,MM,DD").format("DD-MM-YYYY")
        )
      ) {
        console.log(exceededDates);

        setExceededDates([
          ...exceededDates,
          moment(week, "YYYY,MM,DD").format("DD-MM-YYYY"),
        ]);
      }
    }
    else {
      setExceededDates(
        exceededDates.filter(
          (d) => d !== moment(week, "YYYY,MM,DD").format("DD-MM-YYYY")
        )
      );
    }
  };

  // console.log(exceededDates);


  const handleApprove = (row) => {
    if (
      (role === "Admin") &&
      row.status === "SUBMITTED"
    ) {
      setLoading(true);
      axiosInstance
        .put(`/timesheet/approvestatus?role=Admin`, {
          timesheetId: row.timesheetId,
        })
        .then((res) => {
          setSnackbarOpen(true);
          setMessage("Timesheet is Approved Successfully!");
          setSeverity("success");
          setIsDisabled(true);
          setTimeout(() => {
            navigate("/home/timecard");
          }, 1300);
        })
        .catch((err) => {
          console.error("Error:", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    else {
      setLoading(true);
      let updatedFormData = []
      let taskDataObjSubmitted = {}
      let status = "SUBMITTED";
      formData.map((data) => {
        taskDataObjSubmitted = { ...data, status: 'SUBMITTED' }
        updatedFormData.push(taskDataObjSubmitted)
      })
      const tasksNotShowinginTimesheet = JSON.parse(
        location?.state?.timesheet?.tasks
      ).filter((data) => data.project !== location?.state?.timesheet?.projectShow)
      let existingTimesheetTasks = [...updatedFormData, ...tasksNotShowinginTimesheet]
      let allTasksApprovedbyManager = existingTimesheetTasks.filter((data) => data.status === "SUBMITTED").length
      console.log(allTasksApprovedbyManager, existingTimesheetTasks);
      if (allTasksApprovedbyManager === existingTimesheetTasks.length) {
        status = "APPROVED"
      }
      axiosInstance
        .put(`/timesheet/approvestatus?project=${location?.state?.timesheet?.projectShow}`, {
          timesheetId: row.timesheetId,
          tasks: JSON.stringify(existingTimesheetTasks),
          status: status
        })
        .then((res) => {
          setSnackbarOpen(true);
          setSeverity("success");
          setIsDisabled(true);
          setTimeout(() => {
            navigate("/home/timecard");
          }, 1300);
          setLoading(false);
          setMessage("Timesheet is Approved from your side!");
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error:", err);
        })
    }
  };
  console.log(taskData, (taskData.filter((data) => data.status === "SAVED").length >= 1));

  const handleReject = (row) => {
    if (
      (role === "Admin") &&
      row.status === "SUBMITTED"
    ) {
      setLoading(true);
      let updatedFormData = [];
      let taskDataObjSubmitted = {};
      formData.map((data) => {
        taskDataObjSubmitted = { ...data, status: 'PENDING' }
        updatedFormData.push(taskDataObjSubmitted)
      })
      console.log(updatedFormData);
      axiosInstance
        .put("/timesheet/rejectstatus?role=Admin", {
          timesheetId: row.timesheetId,
          tasks: JSON.stringify(updatedFormData),
          message: rejectmessage
        })
        .then((res) => {
          setSnackbarOpen(true);
          setMessage("Timesheet is Rejected Successfully!");
          setSeverity("success");
          setIsDisabled(true);
          setTimeout(() => {
            navigate("/home/timecard");
          }, 1300);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    else {
      setLoading(true);
      let updatedFormData = []
      let taskDataObjSubmitted = {}
      let status = "SUBMITTED";

      formData.map((data) => {
        taskDataObjSubmitted = { ...data, status: 'PENDING' }
        updatedFormData.push(taskDataObjSubmitted)
      })
      const tasksNotShowinginTimesheet = JSON.parse(
        location?.state?.timesheet?.tasks
      ).filter((data) => data.project !== location?.state?.timesheet?.projectShow)
      console.log(location?.state?.timesheet?.projectShow, "projectShow");

      let existingTimesheetTasks = [...updatedFormData, ...tasksNotShowinginTimesheet]
      console.log(existingTimesheetTasks);
      let allTasksRejectedbyManager = existingTimesheetTasks.filter((data) => data.status === "PENDING").length
      console.log(allTasksRejectedbyManager, existingTimesheetTasks);
      if (allTasksRejectedbyManager === existingTimesheetTasks.length) {
        status = "PENDING"
      }
      const data = {
        timesheetId: row.timesheetId,
        tasks: existingTimesheetTasks,
        status: status
      };
      console.log(data);
      axiosInstance
        .put(`/timesheet/rejectstatus?project=${location?.state?.timesheet?.projectShow}`, {
          timesheetId: row.timesheetId,
          message: rejectmessage,
          tasks: JSON.stringify(existingTimesheetTasks),
          status: status
        })
        .then((res) => {
          setLoading(false);
          setSnackbarOpen(true);
          setMessage("Timesheet is Rejected from your side!");
          setSeverity("success");
          setIsDisabled(true);
          setTimeout(() => {
            navigate("/home/timecard");
          }, 1300);
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error:", err);
        })
    }
  };

  const showDefaultValue = (taskIndex, week) => {
    const formattedDate = moment(week, "YYYY,MM,DD").format("DD-MM-YYYY");
    const seconds = taskData[taskIndex]?.workedDayHours[formattedDate];
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    if (seconds === undefined) {
      return dayjs().hour(0).minute(0).second(0);
    }
    return dayjs().hour(hours).minute(minutes).second(0);
  };

  const partially_approved = timesheet.status !== "APPROVED" && taskData.some((data) => data.status === "SUBMITTED")
  const partially_rejected = timesheet.status !== "PENDING" && taskData.some((data) => data.status === "PENDING")
  const partially_approved_partially_rejects = partially_approved && partially_rejected

  const showDefaultValueTotal = (week) => {
    const formattedDate = moment(week, "YYYY,MM,DD").format("DD-MM-YYYY");
    // const seconds = taskData[taskIndex]?.workedDayHours[formattedDate];
    let ss = 0;
    formData.map((task) => {
      if (
        task?.workedDayHours &&
        task?.workedDayHours !== null &&
        task.workedDayHours[moment(week, "YYYY,MM,DD").format("DD-MM-YYYY")] !==
        undefined
      ) {
        ss +=
          task?.workedDayHours[moment(week, "YYYY,MM,DD").format("DD-MM-YYYY")];
      }
    });
    if (ss === undefined) {
      return dayjs().hour(0).minute(0).second(0);
    }
    const hours = Math.floor(ss / 3600);
    const minutes = Math.floor((ss % 3600) / 60);
    return dayjs().hour(hours).minute(minutes).second(0);
  };

  const fun = () => {
    const updatedFormData = [...formData];
    updatedFormData[selectedTaskIndex].workedDayHours[
      moment(selectedWeek, "YYYY,MM,DD").format("YYYY-MM-DD")
    ] = 0;
    setFormData(updatedFormData);
  };

  const hol = (week) => {
    // Use Array.prototype.some() to check if any date in holidays matches the week
    const isHoliday = holidays.some((holiday) => {
      return moment(holiday.date, "YYYY,MM,DD").isSame(
        moment(week, "YYYY,MM,DD"),
        "day"
      );
    });

    // Return the result
    return isHoliday;
  };

  let enteredHoursSum = 0;
  formData &&
    formData.map((task) => {
      if (task.workedDayHours != null) {
        Object.keys(task.workedDayHours).map((taskDate) => {
          const sD = moment(startDate)
          const eD = moment(endDate)
          const tD = moment(taskDate, 'DD-MM-YYYY')
          if (tD.isSameOrAfter(sD) && tD.isSameOrBefore(eD)) {
            enteredHoursSum += task.workedDayHours[taskDate]
          }
        }
        );
      }
    });

  const isTaskSum = (task) => {
    let sum = 0;
    if (task.workedDayHours) {
      Object.keys(task.workedDayHours).map((taskDate) => {
        const sD = moment(startDate)
        const eD = moment(endDate)
        const tD = moment(taskDate, 'DD-MM-YYYY')
        if (tD.isSameOrAfter(sD) && tD.isSameOrBefore(eD)) {
          sum += task.workedDayHours[taskDate]
        }
      }
      );
    }
    const hours = Math.floor(sum / 3600);
    const minutes = Math.floor((sum % 3600) / 60);

    return `${hours}:${minutes}`;
  };

  const isSubmitted = () => {
    return (
      (role !== "Admin" &&
        taskData.length !== 0 &&
        (Object.keys(timesheet).length === 0 ||
          (
            (
              timesheet.status !== "SUBMITTED"
              ||
              taskData.some((data) => data.status === "PENDING")
            )
            &&
            timesheet.status !== "APPROVED")) &&
        ((role === "Project Manager" &&
          taskData[0].associate === user.associateId) ||
          role === "User")
      )
    );
  };

  const isMatches = (task, week) => {
    if (
      (moment(task.startDate, "YYYY,MM,DD").format("YYYY-MM-DD") ===
        moment(task.endDate, "YYYY,MM,DD").format("YYYY-MM-DD") &&
        moment(task.endDate, "YYYY,MM,DD").format("YYYY-MM-DD") ===
        moment(week, "YYYY,MM,DD").format("YYYY-MM-DD"))
    ) {
      return true;
    } else if (
      (moment(task.endDate, "YYYY,MM,DD").diff(
        moment(task.startDate, "YYYY,MM,DD"),
        "days"
      ) >= 1 &&
        moment(task.startDate, "YYYY,MM,DD").format("YYYY-MM-DD") <=
        moment(week, "YYYY,MM,DD").format("YYYY-MM-DD") &&
        moment(task.endDate, "YYYY,MM,DD").format("YYYY-MM-DD") >=
        moment(week, "YYYY,MM,DD").format("YYYY-MM-DD"))
    ) {
      return true;
    } else {
      return null;
    }
  };

  const isMatchesTask = (task, week) => {
    if (
      (moment(task.startDate, "YYYY,MM,DD").format("YYYY-MM-DD") ===
        moment(task.endDate, "YYYY,MM,DD").format("YYYY-MM-DD") &&
        moment(task.endDate, "YYYY,MM,DD").format("YYYY-MM-DD") ===
        moment(week, "YYYY,MM,DD").format("YYYY-MM-DD")) && (task.status === "PENDING")
    ) {
      return true;
    } else if (
      (moment(task.endDate, "YYYY,MM,DD").diff(
        moment(task.startDate, "YYYY,MM,DD"),
        "days"
      ) >= 1 &&
        moment(task.startDate, "YYYY,MM,DD").format("YYYY-MM-DD") <=
        moment(week, "YYYY,MM,DD").format("YYYY-MM-DD") &&
        moment(task.endDate, "YYYY,MM,DD").format("YYYY-MM-DD") >=
        moment(week, "YYYY,MM,DD").format("YYYY-MM-DD")) && (task.status === "PENDING")
    ) {
      return true;
    } else {
      return null;
    }
  };

  let projectTitles = []
  formData.map((data) => {
    if (!projectTitles.find((title) => title === data.project)) {
      projectTitles.push(data.project)
    }
  })

  let Task_status_Should_be_SAVED_while_user_is_Submitting = []
  let Task_status_Should_be_PENDING_while_user_is_saving = []
  let taskDataObjSubmitted = {}
  let taskDataObjSaved = {}
  // let updatedFormData = []

  formData.map((data) => {
    if (data.status !== "SUBMITTED" && data.category!=="JIRA") {
      taskDataObjSubmitted = { ...data, status: 'SAVED' }
      taskDataObjSaved = { ...data, status: 'PENDING' }
    }
    else {
      taskDataObjSubmitted = { ...data }
      taskDataObjSaved = { ...data }
    }
    Task_status_Should_be_SAVED_while_user_is_Submitting.push(taskDataObjSubmitted)
    Task_status_Should_be_PENDING_while_user_is_saving.push(taskDataObjSaved)
  })

  const save_data = {
    startDate: startDate,
    endDate: endDate,
    tasks: JSON.stringify(Task_status_Should_be_PENDING_while_user_is_saving),
    totalWorkingHours: enteredHoursSum,
    projectTitles: projectTitles
  };
  const submit_data = {
    startDate: startDate,
    endDate: endDate,
    tasks: JSON.stringify(Task_status_Should_be_SAVED_while_user_is_Submitting),
    totalWorkingHours: enteredHoursSum,
    projectTitles: projectTitles
  };

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (exceededDates.length !== 0) {
      setSnackbarOpen(true);
      setMessage(
        `Worked hours exceed 12 hours on the following dates: ${exceededDates.join(
          ", "
        )}`
      );
      setSeverity("error");
      return;
    }
    if ((enteredHoursSum / 3600).toFixed(2) > 60) {
      setSnackbarOpen(!snackbarOpen);
      setMessage("Entered Hours are more than 60 Hours.");
      setSeverity("error");
    } else {
      axiosInstance
        .post("/timesheet/save", save_data)
        .then((res) => {
          setTimesheet(res.data);
          setSnackbarOpen(!snackbarOpen);
          setMessage("Timesheet saved succesfully");
          setSeverity("success");
          setSaveLoading(false);
        })
        .catch((err) => {
          setSnackbarOpen(!snackbarOpen);
          setMessage("Error Saving Timesheet");
          setSeverity("error");
          setSaveLoading(false);
        });
    }
  };

  const openTaskPopOver = (event, taskIndex) => {
    setTaskAnchorEl(event.currentTarget);
    setSelectedIndex(taskIndex);
  };
  const handleTaskPopOverClose = () => {
    setTaskAnchorEl(null);
  };

  const handleOpenSubmitDialog = () => {
    if (invalidDates.length !== 0) {
      return;
    }
    setIsSubmitDialogOpen(true);
  };

  const handleCloseSubmitDialog = () => {
    setIsSubmitDialogOpen(false);
  };

  const handleSubmit = () => {
    setSubmitLoading(true);
    setLoading(true);
    if ((enteredHoursSum / 3600).toFixed(2) > 60) {
      setSnackbarOpen(!snackbarOpen);
      setMessage("Entered Hours are more than 60 Hours.");
      setSeverity("error");
    } else if (Object.keys(errors).length === 0) {
      axiosInstance
        .post("/timesheet/submitstatus", submit_data)
        .then((res) => {
          setTimesheet(res.data);
          settaskData(JSON.parse(res.data.tasks))
          setFormData(JSON.parse(res.data.tasks))
          setSnackbarOpen(!snackbarOpen);
          setLoading(false)
          setMessage("Timesheet saved and submitted successfully");
          setSeverity("success");
          setSubmitLoading(false);
        })
        .catch((err) => {
          setSnackbarOpen(!snackbarOpen);
          setMessage("Error Submitting Timesheet");
          setSeverity("error");
          setSubmitLoading(false);
        });
    } else {
      setSnackbarOpen(!snackbarOpen);
      setMessage("Error Saving Timesheet");
      setSeverity("error");
      setLoading(false)
      setSubmitLoading(false);
    }
  };

  const handleSubmitButton = () => {
    return !(
      moment(new Date()).isSameOrAfter(moment(startDate)) &&
      moment(new Date()).isSameOrBefore(moment(endDate)) &&
      !(
        moment(new Date()).format("dddd").toLowerCase() ===
        "friday" ||
        moment(new Date()).format("dddd").toLowerCase() ===
        "saturday" ||
        moment(new Date()).format("dddd").toLowerCase() ===
        "sunday"
      )
    ) &&
      !(
        moment(new Date()).isSameOrBefore(moment(startDate)) &&
        moment(new Date()).isSameOrBefore(moment(endDate))
      );
  }


  return (
    <Box>
      <Card sx={{ mt: "12px" }}>
        <CardContent sx={{ paddingBottom: "12px !important", padding: "12px" }}>
          <Grid
            container
            xs={12}
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={6}>
              <Typography
                sx={{
                  color: "#016466",
                  fontSize: "17px",
                  fontFamily: "Lucida Grande",
                }}
              >
                {location?.state
                  ? `Timesheet of  ${location?.state?.timesheet?.associatename}`
                  : `Timesheet for  ${user.firstname} ${user.lastname} `}
              </Typography>
            </Grid>

            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="click to go to previous">
                <IconButton
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setFormData([]);
                    setUpdatingMessage("");
                    setDate(
                      moment(date).subtract(7, "day").format("YYYY-MM-DD")
                    );
                  }}
                  disabled={loading ? true : false}
                >
                  <ArrowBackIosIcon fontSize="small" />
                </IconButton>
              </Tooltip>

              <TextField
                label="Select Week"
                type="date"
                size="small"
                value={date}
                inputProps={{
                  style: { font: "small-caption" },
                  max: moment(new Date()).endOf('month').format('yyyy-MM-DD')
                }}
                error={date === "" ? true : false}
                helperText={date === "" ? "Please select the date" : ""}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: '16px', fontFamily: "Lucida Grande" }
                }}
                disabled={loading ? true : false}
                onChange={(e) => {
                  setFormData([]);
                  setDate(e.target.value);
                }}
              >

              </TextField>
              <Tooltip title="click to go to next">
                <IconButton
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setFormData([]);
                    setDate(moment(date).add(7, "day").format("YYYY-MM-DD"));
                  }}
                  disabled={loading ? true : false}
                >
                  <ArrowForwardIosIcon fontSize="small" />

                </IconButton>
              </Tooltip>

            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: "12px" }}>
        <CardContent sx={{ paddingBottom: "12px !important", padding: "12px" }}>
          <Grid container spacing={1}>
            <Grid item xs={2.4}>
              <Typography
                component="h6"
                style={{
                  textAlignLast: "left",
                  position: "relative",
                  fontWeight: "500",
                }}
              >
                Timesheet Week:{" "}
              </Typography>
              <Typography style={{ textAlignLast: "left" }} component="h6">
                {moment(startOfWeek).format("MMMM DD")} -{" "}
                {moment(startOfWeek).add(4, "days").format("MMMM DD")}{" "}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography component="h6" style={{ fontWeight: "500" }}>
                Total Tasks Found:
              </Typography>
              <Typography component="h6">{taskData.length}</Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography component="h6" style={{ fontWeight: "500" }}>
                Timesheet Estimated Hours:
              </Typography>
              <Typography component="h6">{40}:00</Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography component="userh6" style={{ fontWeight: "500" }}>
                Total Hours:
              </Typography>
              <Typography component="h6">
                {/* {(enteredHoursSum / 3600).toFixed(2)} */}
                {Math.floor(enteredHoursSum / 3600)}:
                {Math.floor((enteredHoursSum % 3600) / 60)}
              </Typography>
            </Grid>
            {(role === "Project Manager" && user.associateId !== associateId) || (role === "Admin") ? <></> : <Grid item xs={2.4} sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              {localStorage.getItem("token") === null ?
                <Chip
                  onClick={() => {
                    window.location.replace(redirect_uri);
                  }}
                  avatar={<Avatar src={jiraImage}></Avatar>}
                  sx={{
                    marginLeft: 1,
                    fontSize: 12,
                    color: "white",
                    // maxWidth: `${maxWidth}%`,
                    fontFamily: 'Lucida Grande',
                    backgroundColor: "#029a9c",
                  }}
                  label="Authenticate with JIRA" />
                // <Button
                //   variant="contained"
                //   // type="submit"
                //   onClick={() => { window.location.replace("https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=s4Edoc9vBQk85DiSZ8TATzr4n5fqNCGR&scope=read%3Ajira-work%20read%3Ajira-user%20write%3Ajira-work&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F%23%2Fhome%2Ftimecard%2Ftimesheet&state=${YOUR_USER_BOUND_VALUE}&response_type=code&prompt=consent") }}
                //   fullWidth
                //   startIcon={<Avatar src={jiraImage} sx={{ width: '24px', height: '24px' }} />}
                // >
                //   Authenticate with JIRA
                // </Button> 
                :
                <>
                  <TextField
                    size="small"
                    label="Jira Account"
                    select
                    InputProps={{ style: { font: 'small-caption' } }}
                    InputLabelProps={{ style: { font: 'small-caption' }, shrink: true }}
                    value={selectedDomain || ''}
                    defaultValue={selectedDomain || ''}
                    onChange={(e) => {
                      let selectedDomain = {};
                      selectedDomain = jiraDomain.find((data) => data.name === e.target.value)
                      setSelectedDomain(selectedDomain.name)
                      setCloudId(selectedDomain.id)
                    }}
                  >
                    {
                      jiraDomain.map((data) =>
                        <MenuItem value={data.name} sx={{ font: 'small-caption' }}>{data.name}</MenuItem>
                      )
                    }
                  </TextField>

                  {refreshLoading ? <CircularProgress /> : <Tooltip title="click to get the logged hours from jira">
                    <IconButton
                      disabled={loading}
                      onClick={() => {
                        props.setLoad(true);
                        setRefreshLoading(true);
                        setLoading(true);
                        setUpdatingMessage("Tasks are fetching from JIRA...");

                        axiosInstance.post(`/task/basicAuth?token=${localStorage.getItem("token")}&cloudId=${cloudId}`, { startDate: startDate, endDate: endDate }).then(res => {
                          // props.setLoad(false);
                          setUpdatingMessage("Tasks are fetched Succesfully. Timesheet is Updating...");
                          setRefreshLoading(false)
                          handleWeeksAndHolidays()
                          // handleTimesheetAPI()

                        }).catch((err) => {
                          console.log(err);
                        })
                      }}>
                      <SyncIcon></SyncIcon>
                    </IconButton>
                  </Tooltip>
                  }{/* <IconButton onClick={() => {
                    localStorage.removeItem("token")
                    window.location.reload();
                  }}>
                    <DeleteOutline></DeleteOutline>
                  </IconButton> */}
                </>
              }
            </Grid>
            }

          </Grid>
        </CardContent>
      </Card>
      <Box
        component="form"
        onSubmit={handleSave}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <Card sx={{ mt: "12px" }}>
          <CardContent>
            <Dialog
              open={isRejectDialogOpen}
              onClose={() => {
                setIsRejectDialogOpen(false);
              }}

            >
              <DialogTitle
                sx={{
                  backgroundColor: "teal",
                  color: "white",
                  textAlign: "center",
                  marginBottom: "8px",
                  padding: "8px",
                }}
              >
                Rejection Reason!!!
              </DialogTitle>
              <DialogContent>
                <TextField autoFocus
                  placeholder="Enter Rejection Reason"
                  margin="dense"
                  id="rejectMessage"
                  label="Rejection Reason"
                  type="text"
                  fullWidth
                  onChange={(e) => setRejectMessage(e.target.value)}
                ></TextField>
              </DialogContent>
              <DialogActions>
                <>
                  <Button
                    size="small"
                    sx={{ color: "white" }}
                    onClick={() => {
                      setIsRejectDialogOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    sx={{ color: "white" }}
                    onClick={() => {
                      // setLoading(true);
                      setIsRejectDialogOpen(false);
                      console.log(props.data)
                      handleReject(timesheetToReject);
                    }}
                  >
                    Submit
                  </Button>
                </>

              </DialogActions>
            </Dialog>
            {!loading ? (
              <Table aria-label="customized table">
                {taskData.length !== 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          width: "100px",
                          backgroundColor: "#d5ecec",
                          // border: "1px solid #1b231b",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Typography sx={{ fontWeight: "550" }}>
                          Project
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "200px",
                          backgroundColor: "#d5ecec",
                          // border: "1px solid #1b231b",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Typography sx={{ fontWeight: "550" }}>Task Name</Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          // width: "100px",
                          width: "80px",
                          backgroundColor: "#d5ecec",
                          // border: "1px solid #1b231b",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Typography sx={{ fontWeight: "550" }}>Estimated Hours</Typography>

                      </TableCell>
                      {
                        <React.Fragment>
                          {weekDates.map((week, weekIndex) => (
                            <React.Fragment key={weekIndex}>
                              <TableCell
                                sx={{
                                  padding: "05px",
                                  width: "100px",
                                  backgroundColor: hol(week)
                                    ? "#f6e3c1"
                                    : moment(week, "YYYY,MM,DD")
                                      .format("dddd")
                                      .toLowerCase() === "saturday" ||
                                      moment(week, "YYYY,MM,DD")
                                        .format("dddd")
                                        .toLowerCase() === "sunday"
                                      ? "#adebb3"
                                      : "#d5ecec",
                                  // border: "1px solid #1b231b",
                                  // borderRadius: '15px 50px 30px',
                                  color: "black",
                                }}
                                align="right"
                              >
                                <Typography
                                  variant="body1"
                                  style={{ textAlignLast: "center" }}
                                >
                                  {moment(week, "YYYY,MM,DD")
                                    .format("dddd")
                                    .substring(0, 3)
                                    .toUpperCase()}
                                  <br />
                                  {moment(week, "YYYY,MM,DD").format("DD/MMM")}
                                </Typography>
                              </TableCell>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      }
                      <TableCell
                        sx={{
                          width: "70px",
                          padding: "3px",
                          backgroundColor: "#d5ecec",
                          // border: "1px solid #1b231b",
                        }}
                      >
                        <Typography sx={{ fontWeight: "550" }}>
                          Total
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {formData.map((task, taskIndex) => (
                      <>
                        <TableRow key={taskIndex}>
                          <TableCell
                            sx={{
                              backgroundColor: "#d5ecec",
                              // border: "1px solid #1b231b",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                              padding: "4px",
                            }}
                          >
                            <Typography variant={"body2"}>
                              {task.project}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "#d5ecec",
                              // border: "1px solid #1b231b",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                              padding: "4px",
                            }}
                          >
                            {taskAnchorEl ? (
                              <TaskPopover
                                el={taskAnchorEl}
                                task={taskData[selectedIndex]}
                                handleClose={handleTaskPopOverClose}
                              />
                            ) : (
                              <></>
                            )}
                            {/* <Grid
                              container
                              item
                              xs={12}
                            // rowSpacing={1}
                            // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            // sx={{
                            //   justifyContent: "center",
                            //   display: "flex",
                            //   alignItems: "center",
                            // }}
                            > */}
                            {/* <Grid
                                item
                                xs={10}
                              // sx={{
                              //   display: "flex",
                              //   justifyContent: "center",
                              //   alignItems:"center",
                              //   textAlign: "justify",
                              //   width: "250px",
                              // }}
                              > */}
                            <Typography variant="body2">
                              <Tooltip title="Click here for Task Info.">
                                <Link
                                  sx={{
                                    cursor: "pointer", display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "justify",
                                  }}
                                  underline="hover"
                                  onClick={(event) => {
                                    openTaskPopOver(event, taskIndex);
                                  }}
                                >
                                  {task.taskName}
                                </Link>
                              </Tooltip>
                            </Typography>
                            {/* </Grid>
                            </Grid> */}
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "#d5ecec",
                              // border: "1px solid #1b231b",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                              padding: "4px",
                            }}
                          >
                            <Typography variant={"body2"}>
                              {Math.floor(task.estimatedHours / 3600)}:
                              {Math.floor((task.estimatedHours % 3600) / 60)}
                            </Typography>
                          </TableCell>

                          {weekDates.map((week, index) => (
                            <>

                              <TableCell
                                sx={{
                                  padding: "0px",
                                  color: "black",
                                  // border: "1px solid #1b231b",
                                }}
                                align="center"
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimeField
                                    size="small"
                                    label={
                                      isMatches(task, week) === "Holiday"
                                        ? "Holiday"
                                        : isMatches(task, week)
                                          ? "hh:mm"
                                          : ""
                                    }
                                    style={{
                                      width: "100px",
                                      font: "small-caption",
                                    }}
                                    disabled={
                                      ((role === "Admin" ||
                                        ((role === "Project Manager") && (user.associateId !== timesheet?.associate)) ||
                                        (timesheet.status === "SUBMITTED") ||
                                        (timesheet.status === "APPROVED")) &&
                                        !((user.associateId === timesheet?.associate) && (timesheet.status !== "PENDING" && formData.some((data) => data.status === "PENDING")))
                                      )
                                        ? true
                                        : isMatchesTask(task, week) === "Holiday"
                                          ? "Holiday"
                                          :
                                          isMatchesTask(task, week)
                                            ? false
                                            : true
                                    }
                                    defaultValue={
                                      taskData?.[taskIndex]?.workedDayHours &&
                                        taskData?.[taskIndex]?.workedDayHours
                                        ? showDefaultValue(taskIndex, week)
                                        : dayjs().hour(0).minute(0)
                                    }
                                    sx={{
                                      minWidth: "0px",
                                      font: "small-caption",
                                      backgroundColor:
                                        isMatches(task, week) === "Holiday"
                                          ? "Holiday"
                                          : isMatches(task, week)
                                            ? "aliceblue"
                                            : "",
                                    }}
                                    inputProps={{
                                      style: {
                                        minWidth: "0px",
                                        font: "small-caption",
                                        backgroundColor:
                                          isMatches(task, week) === "Holiday"
                                            ? "Holiday"
                                            : isMatches(task, week)
                                              ? "aliceblue"
                                              : "",
                                      },
                                    }}
                                    onChange={(e) => {
                                      const seconds =
                                        dayjs(e).hour() * 3600 +
                                        dayjs(e).minute() * 60;
                                      handleChange(
                                        seconds,
                                        taskIndex,
                                        task,
                                        week,
                                        index
                                      );
                                    }}
                                    format="HH:mm"
                                  />
                                </LocalizationProvider>
                              </TableCell>
                              {isHolidayDialogOpen ? (
                                <Dialog
                                  open={isHolidayDialogOpen}
                                  onClose={false}
                                >
                                  <DialogTitle>Holiday Alert</DialogTitle>
                                  <DialogContent>
                                    You are trying to enter hours on a holiday.
                                    Click 'OK' to confirm or Cancel the alert to
                                    clear the entry
                                  </DialogContent>
                                  <DialogActions>
                                    <Button
                                      sx={{ color: "white" }}
                                      onClick={() => {
                                        setIsHolidayDialogOpen(false);
                                        handleChange(
                                          moment()
                                            .startOf("day")
                                            .seconds(0)
                                            .format("HH:mm"),
                                          selectedTaskIndex,
                                          taskData[selectedTaskIndex],
                                          selectedWeek
                                        );
                                        fun();
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      sx={{ color: "white" }}
                                      onClick={() => {
                                        setIsHolidayDialogOpen(false);
                                      }}
                                    >
                                      Ok
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                          <TableCell
                            sx={{
                              backgroundColor: "#d5ecec",
                              // border: "1px solid #1b231b",
                            }}
                          >
                            <Typography variant={"caption"}>
                              {isTaskSum(task)}
                            </Typography>
                          </TableCell>
                        </TableRow>

                        {isDialogOpen ? (
                          <Dialog open={isDialogOpen} onClose={false}>
                            <DialogTitle>Hours Exceeded Alert</DialogTitle>
                            <DialogContent>
                              Your recorded hours for the day exceed the
                              standard 8-hour limit
                            </DialogContent>
                            <DialogActions>
                              <Button
                                sx={{ color: "white" }}
                                onClick={() => {
                                  setIsDialogOpen(false);
                                }}
                              >
                                Close
                              </Button>
                            </DialogActions>
                          </Dialog>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}

                    {
                      <React.Fragment>
                        <TableCell
                          sx={{
                            padding: "05px",
                            width: "100px",
                            color: "black",
                          }}
                          align="right"
                        >

                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "05px",
                            width: "100px",
                            color: "black",
                          }}
                          align="right"
                        >

                        </TableCell>
                        <TableCell
                          sx={{
                            color: "black",
                          }}
                          align="center"
                        >
                          <Typography sx={{ fontWeight: "550" }}>Total</Typography>
                        </TableCell>
                        {weekDates.map((week, weekIndex) => (
                          < React.Fragment key={weekIndex}>
                            <TableCell
                              sx={{
                                padding: "05px",
                                width: "100px",
                                color: "black",
                              }}
                              align="center"
                            >
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                              >
                                <TimeField
                                  size="small"
                                  style={{
                                    width: "100px",
                                    font: "small-caption",
                                  }}
                                  inputProps={{
                                    style: {
                                      minWidth: "0px",
                                      font: "small-caption",
                                    },
                                  }}
                                  disabled={true}
                                  value={showDefaultValueTotal(week)}
                                  defaultValue={
                                    showDefaultValueTotal(week)
                                  }
                                  format="HH:mm"
                                />
                              </LocalizationProvider>
                            </TableCell>
                          </React.Fragment>


                        ))}
                        <TableCell
                          sx={{
                            backgroundColor: "#d5ecec",
                            // align:"center",
                            // display: 'flex',
                            // justifyContent: 'center',
                            // padding: "05px",
                            // width: "100px",
                            // borderRadius: '15px 50px 30px',
                            color: "black",
                          }}
                        // align="right"
                        >
                          <Typography variant="caption">
                            {/* {(enteredHoursSum / 3600).toFixed(2)} */}
                            {Math.floor(enteredHoursSum / 3600)}:
                            {Math.floor((enteredHoursSum % 3600) / 60)}
                          </Typography>
                        </TableCell>
                      </React.Fragment>
                    }

                  </TableBody>
                ) : (
                  <Container sx={{ mt: 4 }}>
                    {role === "Admin" || role === "Project Manager" ? <Typography sx={{ textAlignLast: "center" }}>
                      Associate has No tasks found for the selected week.{" "}
                    </Typography> :
                      <Typography sx={{ textAlignLast: "center" }}>
                        No tasks found for the selected week.{" "}
                        <Link
                          component="button"
                          underline="hover"
                          onClick={() => {
                            navigate("/home/task");
                          }}
                        >
                          Add tasks
                        </Link>{" "}
                        to update your timesheet.
                      </Typography>}
                  </Container>
                )}
              </Table>
            ) : (
              <Grid sx={{ textAlignLast: "center" }} item xs={12}>
                <Box style={{ paddingTop: "50px", paddingBottom: "10px" }}>
                  <CircularProgress />
                </Box>
                {updatingMessage}
              </Grid>
            )}
          </CardContent>
        </Card>
        {
          <Card sx={{ mt: "12px" }}>
            {isSubmitted() && (
              <CardContent
                sx={{ paddingBottom: "12px !important", padding: "12px" }}
              >
                <Grid
                  style={{ justifyContent: "flex-end", marginTop: "10px" }}
                  container
                  xs={12}
                  spacing={2}
                >
                  <Stack direction="row" spacing={2}>

                    {saveloading ? (
                      <CircularProgress />
                    ) : (
                      <Tooltip title={!handleSubmitButton() ? "The submit button will be visible on Fridays for the current week, but users can also submit timesheets for previous weeks." : null}>
                        <Button
                          type="submit"
                          variant="outlined"
                          style={{ color: "white" }}
                          startIcon={<SaveIcon />}
                        >
                          Save
                        </Button>
                      </Tooltip>
                    )}
                    <>
                      {handleSubmitButton()
                        ?
                        (
                          submitloading ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              variant="outlined"
                              style={{ color: "white" }}
                              onClick={handleOpenSubmitDialog}
                              startIcon={<EastIcon />}
                            >
                              Submit
                            </Button>
                          )
                        ) : (
                          <></>
                        )}
                    </>
                    {isSubmitDialogOpen ? (
                      <Dialog
                        open={isSubmitDialogOpen}
                        onClose={handleCloseSubmitDialog}
                      >
                        <DialogTitle>Confirm Submission</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Are you sure you want to submit the timesheet for
                            this week? After confirmation, you will not be able
                            to fill the timesheet for this week.
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            variant="contained"
                            onClick={handleCloseSubmitDialog}
                            color="primary"
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleSubmit();
                              handleCloseSubmitDialog();
                            }}
                            color="primary"
                            autoFocus
                          >
                            Confirm
                          </Button>
                        </DialogActions>
                      </Dialog>
                    ) : (
                      <> </>
                    )}
                  </Stack>
                </Grid>
              </CardContent>
            )}
            {
              (user.associateId !== location?.state?.timesheet?.associate && taskData.length === 0) ||
              // ((role === "Project Manager" && taskData.filter((data) => data.status === "SUBMITTED").length > 1)) ||
              (role === "Project Manager" && !(taskData.filter((data) => data.status === "SAVED").length >= 1)) ||
              (taskData.length !== 0 &&
                user.associateId !== taskData[0].associate &&
                (role === "Admin" || role === "Project Manager") &&
                timesheet.status !== "APPROVED" &&
                timesheet.status !== "PENDING" && (
                  <CardContent>
                    <Grid
                      style={{ justifyContent: "flex-end", marginTop: "10px" }}
                      container
                      xs={12}
                      spacing={2}
                    >
                      <Stack direction="row" spacing={2}>
                        <Button
                          onClick={() => handleApprove(timesheet)}
                          variant="outlined"
                          style={{ color: "white" }}
                          disabled={isDisabled}
                        >
                          Approve
                        </Button>
                        <Button
                          className="RejectBTN"

                          onClick={() => {
                            setIsRejectDialogOpen(true);
                            setTimesheetToReject(timesheet)
                          }}
                          variant="outlined"
                          style={{ color: "white" }}
                          disabled={isDisabled}
                        >
                          Reject
                        </Button>
                      </Stack>
                    </Grid>
                  </CardContent>
                ))}
          </Card>
        }
        <Snackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(!snackbarOpen)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={3000}
        >
          <Alert severity={severity}>{message}</Alert>
        </Snackbar>
      </Box>
    </Box >
  );
};

export default Timesheet;
