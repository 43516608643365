import { Description, Image, Language, LocationCityOutlined, PhoneInTalkOutlined, PriorityHigh } from "@mui/icons-material";
import { InputAdornment, } from "@mui/material";
import { COMPANY_DESCRIPTION_FIELD_ID, COMPANY_IMAGE_FIELD_ID, COMPANY_LOCATION_FIELD_ID, COMPANY_NAME_FIELD_ID, COMPANY_PHONE_FIELD_ID, COMPANY_STATUS_FIELD_ID, COMPANY_WEBSITE_FIELD_ID } from "../constants/Constants";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';

export const CompanyFields = {
    [COMPANY_NAME_FIELD_ID]: {
        id: COMPANY_NAME_FIELD_ID,
        name: COMPANY_NAME_FIELD_ID,
        type: "text",
        label: "Company Name",
        placeholder: "Enter Company Name",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <AddBusinessIcon fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [COMPANY_DESCRIPTION_FIELD_ID]: {
        id: COMPANY_DESCRIPTION_FIELD_ID,
        sx: 12,
        multiline: true,
        rows:4,
        name: COMPANY_DESCRIPTION_FIELD_ID,
        type: "textarea",
        label: "Description",
        placeholder: "Enter Description",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <Description fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [COMPANY_WEBSITE_FIELD_ID]: {
        id: COMPANY_WEBSITE_FIELD_ID,
        name: COMPANY_WEBSITE_FIELD_ID,
        type: "text",
        label: "Company Website",
        placeholder: "Enter Company Website",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <Language fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [COMPANY_LOCATION_FIELD_ID]: {
        id: COMPANY_LOCATION_FIELD_ID,
        sx: 12,
        name: COMPANY_LOCATION_FIELD_ID,
        type: "text",
        label: "Company Location",
        placeholder: "Enter Company location",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <LocationCityOutlined fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [COMPANY_PHONE_FIELD_ID]: {
        id: COMPANY_PHONE_FIELD_ID,
        name: COMPANY_PHONE_FIELD_ID,
        type: "number",
        label: "Phone Number",
        placeholder: "Enter Phone Number",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <PhoneInTalkOutlined fontSize="small" />&nbsp;
                    +91
                </InputAdornment>
            )
        }
    },
    [COMPANY_IMAGE_FIELD_ID]: {
        id: COMPANY_IMAGE_FIELD_ID,
        name: COMPANY_IMAGE_FIELD_ID,
        type: "File",
        label: 'Upload Logo',
        variant: "standard",
        autoFocus: false,
        required: false,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <Image fontSize="small" />
                </InputAdornment>
            )
        }
    },
    [COMPANY_STATUS_FIELD_ID]: {
        id: COMPANY_STATUS_FIELD_ID,
        name: COMPANY_STATUS_FIELD_ID,
        select: true,
        label: "Company Status",
        placeholder: "Enter Status",
        variant: "outlined",
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            style: { font: 'small-caption' },
            startAdornment: (
                <InputAdornment position="start">
                    <PriorityHigh fontSize="small" />
                </InputAdornment>
            )
        }
    },
}