import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import moment from "moment";
import React, { useEffect, useState } from "react";
import GenericForm from "../../../GenericForm";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import GenericDialog from "../../edvenswa.emportal.common/GenericDialog";
import {
  isValidDescription,
  isValidTask,
} from "../../edvenswa.emportal.common/validation/Validation";
import {
  DIALOG_FIELD_NAME,
  DIALOG_TYPE,
  TASK_CATEGORY_FIELD_ID,
  TASK_CATEGORY_MAPPING,
  TASK_DESCRIPTION_FIELD_ID,
  TASK_END_DATE_FIELD_ID,
  TASK_ESTIMATEDHOURS_FIELD_ID,
  TASK_PROJECTS_FIELD_ID,
  TASK_START_DATE_FIELD_ID,
  TASK_TITLE_FIELD_ID,
} from "../constants/Constants";
import { getTaskFields } from "../fields/GetFields";

const AddTask = (props) => {
  const { setIsDrawerOpen, setFilteredElements, onSuccess, onError, holidays } =
    props;
  const [formData, setFormData] = useState({ status: "PENDING" });
  const [projects, setProjects] = useState([]);
  const [timesheetstatus, setTimesheetStatus]=useState();
  const fields = [
    TASK_TITLE_FIELD_ID,
    TASK_ESTIMATEDHOURS_FIELD_ID,
    TASK_DESCRIPTION_FIELD_ID,
    TASK_START_DATE_FIELD_ID,
    TASK_END_DATE_FIELD_ID,
    TASK_PROJECTS_FIELD_ID,
    TASK_CATEGORY_FIELD_ID,
  ];
  const [isHolidayDialogOpen, setIsHolidayDialogOpen] = useState(false);
  const formFields = getTaskFields(fields);
  const [errors, setErrors] = useState({});
  const [taskCategories, setTaskCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  
  const [date, setDate] = useState(() => {
    if (formData.startDate) {
      return moment(formData.startDate, "YYYY,MM,DD").format(
        "YYYY-MM-DD"
      );
    } 
  });

  const selectedDate = moment(date);
  let startOfWeek;

if (selectedDate.day() === 0 ){
  startOfWeek = selectedDate
    .subtract(1, "week")
    .startOf("week")
    .add(1, "day")
    .format("yyyy-MM-DD");
} else {
  startOfWeek = selectedDate
    .startOf("week")
    .add(1, "day")
    .format("yyyy-MM-DD");
}


  const startDate1 = moment(startOfWeek).format("yyyy-MM-DD");
  const endDate1 = moment(startOfWeek).add(6, "days").format("yyyy-MM-DD");


  useEffect(() => {
    axiosInstance
      .get("/user/associateProjects")
      .then((res) => {
        setProjects(res.data);
      })
      .catch((err) => {
        setProjects([]);
      });
    axiosInstance.get("/task/category").then((res) => {
      setTaskCategories([...res.data]);
    });
  }, [taskCategories.length]);

  const handleChange = (field_name, value) => {
    switch (field_name) {
      case TASK_TITLE_FIELD_ID: {
        if (!isValidTask(value) && value !== "") {
          setErrors({
            ...errors,
            [field_name]: {
              message:
                "The task name must consist of at least two characters and first letter should be capital.",
            },
          });
        } else {
          setErrors((prevErrors) => {
            delete prevErrors[field_name];
            return { ...prevErrors };
          });
          setFormData({ ...formData, [field_name]: value });
        }
        break;
      }
      case TASK_PROJECTS_FIELD_ID: {
        if (value === "Training") {
          setErrors({
            [field_name]: {
              message: "You are not assigned to any project yet",
            },
          });
        }
        setFormData({ ...formData, [field_name]: value });
        break;
      }
      case TASK_DESCRIPTION_FIELD_ID: {
        if (!isValidDescription(value) && value !== "") {
          setErrors({
            ...errors,
            [field_name]: {
              message:
                "The task Description must consist of at least ten characters and first letter should be capital.",
            },
          });
        } else {
          setErrors((prevErrors) => {
            delete prevErrors[field_name];
            return { ...prevErrors };
          });
          setFormData({ ...formData, [field_name]: value });
        }
        break;
      }
      case TASK_CATEGORY_FIELD_ID: {
        setFormData({ ...formData, [field_name]: value });
        break;
      }
      case TASK_ESTIMATEDHOURS_FIELD_ID: {
        const isValid = /^\d{1,2}:[0-5][0-9]$/.test(value);
        if (!isValid) {
          setErrors({
            ...errors,
            [field_name]: {
              message: "The value must be the HH:mm format where HH can be any number and mm is 00-59",
            },
          });
        }
        else {
          const [hours, minutes] = value.split(':').map(Number);
          const seconds = (hours * 3600) + (minutes * 60);
          setErrors((prevErrors) => {
            delete prevErrors[field_name];
            return { ...prevErrors };
          });
          setFormData({ ...formData, [field_name]: seconds });
        }
        break;
      }
      case TASK_START_DATE_FIELD_ID: {
        setStartDate(value);
        setDate(value);
        const startOfWeek = moment(value).startOf('week');
        const endOfWeek = moment(value).endOf('week');
  
        setErrors((prevErrors) => {
          if (moment(value).isSameOrBefore(moment(endDate)) && moment(endDate).isBetween(startOfWeek, endOfWeek, null, '[]')) {
            delete prevErrors[field_name];
            delete prevErrors[TASK_END_DATE_FIELD_ID];
            return { ...prevErrors };
          
          } else {
            const errors = {
              ...prevErrors,
              [field_name]: {
                message: "Start Date must be before or equal to End Date.",
              },
              [TASK_END_DATE_FIELD_ID]: {
                message: "End Date must be after or equal to Start Date and within the same week.",
              },
            };
  
            if (!moment(endDate).isBetween(startOfWeek, endOfWeek, null, '[]')) {
              errors[TASK_END_DATE_FIELD_ID] = {
                message: "End Date must be within the same week as Start Date.",
              };
            }
  
            return errors;
          }
        });

        setFormData({ ...formData, [field_name]: value });
        break;
      }
      case TASK_END_DATE_FIELD_ID: {
        setEndDate(value);
  
        const startOfWeek = moment(startDate).startOf('week');
        const endOfWeek = moment(startDate).endOf('week');
  
        setErrors((prevErrors) => {
          if (moment(value).isSameOrAfter(moment(startDate)) && moment(value).isBetween(startOfWeek, endOfWeek, null, '[]')) {
            delete prevErrors[field_name];
            delete prevErrors[TASK_START_DATE_FIELD_ID];
            return { ...prevErrors };
          } else {
            const errors = {
              ...prevErrors,
              [field_name]: {
                message: "End Date must be after or equal to Start Date.",
              },
              [TASK_START_DATE_FIELD_ID]: {
                message: "Start Date must be before or equal to End Date and within the same week.",
              },
            };
  
            if (!moment(value).isBetween(startOfWeek, endOfWeek, null, '[]')) {
              errors[field_name] = {
                message: "End Date must be within the same week as Start Date.",
              };
            }
  
            return errors;
          }
        });
        setFormData({ ...formData, [field_name]: value });
        break;
      }
      default: {
        setFormData({ ...formData, [field_name]: value });
        break;
      }
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const isWeekendChecked = (startDate, endDate) => {
    for (
      let i = moment(startDate);
      moment(i).isSameOrBefore(endDate);
      i = moment(i).add(1, "day")
    ) {
      if (
        moment(i).format("dddd").toLowerCase() === "saturday" ||
        moment(i).format("dddd").toLowerCase() === "sunday"
      ) {
        return true;
      }
    }
  };
  // const fetchTimesheetStatus=(startDate, endDate)=>{
    // axiosInstance
    // .put("/timesheet/get", { startDate: startDate, endDate: endDate }).then((res)=>{
    //   setTimesheetStatus(res.data.status);
    // }) 
  // }
  // 
  useEffect(() => {
    if (startDate1 && endDate1) {
      axiosInstance
        .put("/timesheet/get", { startDate: startDate1, endDate: endDate1 })
        .then((res) => {
          setTimesheetStatus(res.data.status);
          console.log(res.data.status)
        })
        .catch((error) => {
          console.error("Error fetching timesheet status:", error);
        });
    }
  
  }, [startDate1, endDate1]);
  


  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();
  
    // Check for mandatory fields
    const requiredFields = [TASK_TITLE_FIELD_ID, TASK_DESCRIPTION_FIELD_ID, TASK_CATEGORY_FIELD_ID, TASK_START_DATE_FIELD_ID, TASK_END_DATE_FIELD_ID, TASK_PROJECTS_FIELD_ID, TASK_ESTIMATEDHOURS_FIELD_ID];
    let hasErrors = false;
  
    requiredFields.forEach(field => {
      if (!formData[field]) {
        setErrors(prevErrors => ({
          ...prevErrors,
          [field]: { message: "This field is required" }
        }));
        hasErrors = true;
      }
    });
  
    if (hasErrors) {
      onError("Please fill out all mandatory fields.");
      return;
    }
  
    if (timesheetstatus !== "SUBMITTED" && timesheetstatus !== "APPROVED") {
      const hli = holidays.some(
        (holiday) =>
          moment(holiday.date, "YYYY,MM,DD").isSameOrAfter(moment(formData.startDate)) &&
          moment(holiday.date, "YYYY,MM,DD").isSameOrBefore(moment(formData.endDate))
      );
      if (isWeekendChecked(formData.startDate, formData.endDate)) {
        setIsHolidayDialogOpen(true);
      } else if (hli) {
        setIsHolidayDialogOpen(true);
      } else {
        if (Object.keys(errors).length === 0) {
          setLoading(!loading);
          axiosInstance
            .post("/task/save", formData)
            .then(() => {
              setFormData({});
              console.log(formData)
              setLoading(!loading);
              axiosInstance
                .put("/task/getAllByUser", { startDate: props.startDate, endDate: props.endDate })
                .then((res) => setFilteredElements(res.data))
                .catch((err) => console.log(err));
              setIsDrawerOpen(false);
              onSuccess("Task added successfully");
            })
            .catch((error) => {
              setIsDrawerOpen(false);
              onError("An error occurred while saving the task");
            });
        }
      }
    } else {
      onError({ response: { data: " The Timesheet is already submitted for this week" } });
    }
  };
  
  const renderCustomInput = (field, key) => {
    if (field.type !== "radio") {
      return (
        <>
          {field.id === TASK_CATEGORY_FIELD_ID ? (
            <Autocomplete
            sx={{
              display: "inline-block",
              "& .MuiAutocomplete-inputRoot": {
                fontSize: "0.75rem",
                paddingTop: "4px",
                paddingBottom: "4px",
              },
              "& .MuiAutocomplete-input": {
                paddingTop: "0",
              },
              "& .MuiInputBase-root": {
                minWidth: "0",
              },
              "& .MuiIconButton-root": {
                padding: "4px",
              },
              "& .MuiInputLabel-root": {
                fontSize: "0.75rem",
              },
            }}
            size="small"
            fullWidth
            onChange={(event, newValue) => {
              if (newValue != null && newValue !== "Create new") {
                handleChange(field.name, newValue);
              }
            }}
            InputLabelProps={{ shrink: true }}
            options={taskCategories}
            renderOption={(props, option) => (
              <li {...props} style={{ fontFamily: 'Lucida Grande' }}>
                {option === "Create new" ? (
                  <Button size="small" style={{ color: 'white' }} onClick={handleOpenDialog}>
                    {option}
                  </Button>
                ) : (
                  option
                )}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Task Categories"
                placeholder="Select Task Category"
                required
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    const value = params.inputProps.value.trim();
                    if (value && !taskCategories.includes(value) && value !== "Create new") {
                      handleChange(field.name, value);
                      setTaskCategories([...taskCategories, value]);
                    }
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { fontFamily: 'Lucida Grande' },
                }}
                InputLabelProps={{
                  shrink: 'true',
                  style: { fontFamily: 'Lucida Grande' },
                }}
              />
            )}
            noOptionsText={
              <Button
                size="small"
                style={{ color: "white" }}
                onClick={handleOpenDialog}
              >
                create new
              </Button>
            }
          />
          
          ) : (
            <TextField
              size="small"
              {...field}
              fullWidth
              key={key}
              label={field.label}
              error={errors[field.name] ? true : false}
              helperText={errors?.[field.name]?.message}
              defaultValue={formData[field.name]}
              onChange={(event) => handleChange(field.name, event.target.value) }
              
              InputProps={{
                style: {
                  font: "small-caption",
                  fontFamily: "Lucida Grande",
                  fontSize: 13,
                },
              }}
              InputLabelProps={{
                style: {
                  font: "small-caption",
                  fontFamily: "Lucida Grande",
                  fontSize: 13,
                },
                shrink: true,
              }}
            >
              {field.select && field.id === TASK_PROJECTS_FIELD_ID ? (
                projects.length !== 0 ? (
                  projects.map((status, idx1) => (
                    <MenuItem
                      sx={{
                        font: "small-caption",
                        fontFamily: "Lucida Grande",
                      }}

                      value={status}
                      key={idx1}
                    >
                      {status}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={"Training"}>
                    {"No Projects Assigned"}
                  </MenuItem>
                )
              ) : (
                <React.Fragment />
              )}
            </TextField>
          )}
        </>
      );
    } else {
      return <React.Fragment />;
    }
  };
  return (
    <>
      <GenericForm
        type={props.type}
        formData={formData}
        formFields={formFields}
        errors={errors}
        handleChange={handleChange}
        handleSubmit={handleSave}
        renderCustomInput={renderCustomInput}
        setIsDrawerOpen={setIsDrawerOpen}
        setLoading={setLoading}
        loading={loading}
      />
      <GenericDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        mapping={TASK_CATEGORY_MAPPING}
        type={DIALOG_TYPE}
        onSuccess={onSuccess}
        onError={onError}
        fieldName={DIALOG_FIELD_NAME}
        setItems={setTaskCategories}
        items={taskCategories}
      />
      <Dialog
        open={isHolidayDialogOpen}
        onClose={() => {
          setIsHolidayDialogOpen(false);
        }}
      >
        <DialogTitle>Holiday Alert</DialogTitle>
        <DialogContent>
          You are creating task on Holiday or Weekend on the dates which is
          selected for this Task startDate and Task endDate. Still Do you want
          to create tasks on these dates. Click Ok to continue or Click cancel
          to Abort.
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ color: "white" }}
            onClick={() => {
              setIsHolidayDialogOpen(false);
              setIsDrawerOpen(false);
            }}
          >
            cancel
          </Button>
          <Button
            sx={{ color: "white" }}
            onClick={() => {
              setIsHolidayDialogOpen(false);
              setLoading(!loading);
              axiosInstance
                .post("/task/save", formData)
                .then(() => {
                  setFormData({});
                  setLoading(!loading);
                  axiosInstance.put("/task/getAllByUser", { startDate: props.startDate, endDate: props.endDate }).then(res =>
                    setFilteredElements(res.data)
                  ).catch(err => console.log(err))
                  setIsDrawerOpen(false);
                  onSuccess("Task added successfully");
                })
                .catch((error) => {
                  setIsDrawerOpen(false);
                  onError(error);
                });
            }}
          >
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddTask;

