import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../../../guards/PrivateRoute";
import Layout from "../../edvenswa.emportal.layout/Layout";
import { ALL_REPORTS_PATH, DOWNLOAD_REPORTS_PATH, REPORTS_PATH } from "../constants/Constants";
//import { DownloadReport } from "../pages/DownloadReport";
import  ReportComponent  from "../pages/ReportComponent";

// const ReportComponent = React.lazy(() => import("../pages/ReportComponent"));
const DownloadReport = React.lazy(() => import("../pages/DownloadReport"));
const AllReportView = React.lazy(() => import("../pages/AllReportView"));

export const REPORT_ROUTES = [
    <Route path={REPORTS_PATH} element={
        <PrivateRoute>
            <Layout component={ReportComponent} path={REPORTS_PATH} title="Report"></Layout>
        </PrivateRoute>}
        key={"Report"}>
    </Route>,
    <Route path={DOWNLOAD_REPORTS_PATH} element={
        <PrivateRoute>
            <Layout component={DownloadReport} path={DOWNLOAD_REPORTS_PATH} title="Download Report" ></Layout>
        </PrivateRoute>}
        key={"Report"}>
    </Route>,
    <Route path={ALL_REPORTS_PATH} element={
        <PrivateRoute>
            <Layout component={AllReportView} path={ALL_REPORTS_PATH} title="View All Reports" ></Layout>
        </PrivateRoute>}
        key={"Report"}>
    </Route>,
]
