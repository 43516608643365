import { Add } from "@mui/icons-material";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import moment from "moment";
import React, { useEffect, useState } from "react";
import GenericForm from "../../../GenericForm";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import GenericDialog from "../../edvenswa.emportal.common/GenericDialog";
import {
  isValidEmail,
  isValidFirstAndLastName,
  isValidMobileNo,
  isValidPersonalEmail,
} from "../../edvenswa.emportal.common/validation/Validation";
import {
  ASSOCIATE_COMPANY_FIELD_ID,
  ASSOCIATE_DATE_OF_BIRTH_FIELD_ID,
  ASSOCIATE_DATE_OF_JOINING_FIELD_ID,
  ASSOCIATE_DEFAULT_IMAGE,
  ASSOCIATE_DESIGNATION_FIELD_ID,
  ASSOCIATE_EMAIL_FIELD_ID,
  ASSOCIATE_FIRSTNAME_FIELD_ID,
  ASSOCIATE_ID_FIELD_ID,
  ASSOCIATE_LASTNAME_FIELD_ID,
  ASSOCIATE_MOBILE_FIELD_ID,
  ASSOCIATE_USERNAME_FIELD_ID,
  DESIGNATION_MAPPING,
  DIALOG_FIELD_NAME,
  DIALOG_TYPE,
} from "../constants/Constants";
import { getAssociateFields } from "../forms/GetFields";
import { addYears } from "date-fns";
import { isAfter } from "date-fns";

const Adduser = (props) => {
  const {
    setIsDrawerOpen,
    setFilteredElements,
    setElements,
    onError,
    onSuccess,
    users,
    companies,
    tenantId,
  } = props;
  const [formData, setFormData] = useState({});
  const [image, setImage] = useState("");
  const [userStatus, setUserStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [designations, setDesignations] = useState([]);
  const [Companies, setCompanies] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const fields = [
    ASSOCIATE_ID_FIELD_ID,
    ASSOCIATE_FIRSTNAME_FIELD_ID,
    ASSOCIATE_LASTNAME_FIELD_ID,
    ASSOCIATE_USERNAME_FIELD_ID,
    ASSOCIATE_DATE_OF_BIRTH_FIELD_ID,
    ASSOCIATE_DATE_OF_JOINING_FIELD_ID,
    ASSOCIATE_DESIGNATION_FIELD_ID,
    ASSOCIATE_COMPANY_FIELD_ID,
    ASSOCIATE_EMAIL_FIELD_ID,
    ASSOCIATE_MOBILE_FIELD_ID,
  ];

  const formFields = getAssociateFields(fields);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (userStatus.length === 0) {
      axiosInstance.get("/project/status").then((res) => {
        setUserStatus(res.data);
      });
    }
    axiosInstance.get("/user/designation").then((res) => {
      setDesignations([...res?.data]);
    });
    axiosInstance.get("/tenant/getAll").then((res) => {
      setCompanies([...res?.data]);
    });
  }, [userStatus, designations.length]);

  const handleChange = (field_name, value) => {
    switch (field_name) {
      case ASSOCIATE_ID_FIELD_ID: {
        if (value !== "" && (value.length !== 4 || parseInt(value) <= 0)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field_name]: { message: "Value must be 4 digits" },
          }));
        } else if (parseInt(value) > 0) {
          const searchedUsers = users.filter((data) =>
            `${data.associateId}`.toLowerCase().includes(value.toLowerCase())
          );
          if (searchedUsers.length !== 0) {
            setErrors({
              ...errors,
              [field_name]: { message: "Associate ID already exists" },
            });
          } else {
            setErrors((prevErrors) => {
              const updatedErrors = { ...prevErrors };
              delete updatedErrors[field_name]; // Clear the error message here
              return updatedErrors;
            });
          }
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[field_name]; // Clear the error message here as well
            return updatedErrors;
          });
        }
        setFormData({ ...formData, [field_name]: value });
        break;
      }
      case ASSOCIATE_USERNAME_FIELD_ID: {
        if (!isValidEmail(value) && value !== "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field_name]: { message: "Invalid Email" },
          }));
          setFormData({ ...formData, [field_name]: value });
        } else if (isValidEmail(value)) {
          const searchedUsers = users.filter((data) =>
            `${data.username}`.toLowerCase().includes(value.toLowerCase())
          );
          if (searchedUsers.length !== 0) {
            setErrors({
              ...errors,
              [field_name]: {
                message: "Associate with given email is already exists",
              },
            });
          } else {
            setErrors((prevErrors) => {
              const updatedErrors = { ...prevErrors };
              delete updatedErrors[field_name];
              return updatedErrors;
            });
          }
          setFormData({ ...formData, [field_name]: value });
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[field_name];
            return updatedErrors;
          });
          setFormData({ ...formData, [field_name]: value });
        }
        break;
      }
      case ASSOCIATE_FIRSTNAME_FIELD_ID: {
        if (!isValidFirstAndLastName(value) && value !== "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field_name]: {
              message:
                "Firstname must consist of at least three characters and first letter should be capital.",
            },
          }));
          setFormData({ ...formData, [field_name]: value });
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[field_name];
            return updatedErrors;
          });
          setFormData({ ...formData, [field_name]: value });
        }
        break;
      }
      case ASSOCIATE_LASTNAME_FIELD_ID: {
        if (!isValidFirstAndLastName(value) && value !== "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field_name]: {
              message:
                "Lastname must consist of at least three characters and first letter should be capital.",
            },
          }));
          setFormData({ ...formData, [field_name]: value });
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[field_name];
            return updatedErrors;
          });
          setFormData({ ...formData, [field_name]: value });
        }
        break;
      }
      case ASSOCIATE_DESIGNATION_FIELD_ID: {
        console.log(value);
        setFormData({
          ...formData,
          [field_name]: value === "Create" ? "" : value,
        });
        break;
      }
      case ASSOCIATE_COMPANY_FIELD_ID: {
        break;
      }
      case ASSOCIATE_DATE_OF_JOINING_FIELD_ID: {
        const dateOfJoining = new Date(value);
        if (
          dateOfJoining instanceof Date &&
          !isNaN(dateOfJoining) &&
          dateOfJoining.getFullYear() < 2007
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field_name]: {
              message: "Date of joining must be in or after 2007",
            },
          }));
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[field_name];
            return updatedErrors;
          });
          setFormData({ ...formData, [field_name]: value });
        }
        break;
      }
      case ASSOCIATE_DATE_OF_BIRTH_FIELD_ID: {
        const date = value;
        const eighteenYearsAgo = addYears(new Date(), -18);
        if (isAfter(new Date(date), eighteenYearsAgo)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field_name]: {
              message: "Date of birth must be at least 18 years and should not be in future dates",
            },
          }));
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[field_name];
            return updatedErrors;
          });
          setFormData({ ...formData, [field_name]: value });
        }
        break;
      }
      case ASSOCIATE_EMAIL_FIELD_ID: {
        if (!isValidPersonalEmail(value) && value !== "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field_name]: { message: "Invalid Email" },
          }));
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[field_name];
            return updatedErrors;
          });
          setFormData({ ...formData, [field_name]: value });
        }
        break;
      }
      case ASSOCIATE_MOBILE_FIELD_ID: {
        if (!isValidMobileNo(value) && value !== "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field_name]: { message: "Invalid Mobile Number" },
          }));
          setFormData({ ...formData, [field_name]: value });
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[field_name];
            return updatedErrors;
          });
          setFormData({ ...formData, [field_name]: value });
        }
        break;
      }
      default: {
        console.error("Invalid field name: ", field_name);
      }
    }
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const updatedFormData = {
      ...formData,
      logo: image ? image : ASSOCIATE_DEFAULT_IMAGE,
      tenant: company[0],
    };
    console.log(updatedFormData);
    if (Object.keys(errors).length === 0) {
      setLoading(!loading);
      axiosInstance
        .post("/user/create", updatedFormData)
        .then((response) => {
          console.log("user created successfully:", response.data);
          setImage("");
          setFormData({});
          setFilteredElements((prevElements) => [
            ...prevElements,
            response?.data,
          ]);
          setElements((prevElements) => [...prevElements, updatedFormData]);
          setIsDrawerOpen(false);
          onSuccess("User created successfully");
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          onError(error);
          console.error("Error creating user:", error);
        });
    }
  };

  const company = companies.filter((data) => data.id === tenantId);
  const renderCustomInput = (field, key) => {
    if (field.type !== "radio") {
      return (
        <>
          {field.select && field.id === ASSOCIATE_DESIGNATION_FIELD_ID ? (
            <Autocomplete
              size="small"
              sx={{
                display: "inline-block",
                "& .MuiAutocomplete-inputRoot": {
                  fontSize: "0.75rem",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                },
                "& .MuiAutocomplete-input": {
                  paddingTop: "0",
                },
                "& .MuiInputBase-root": {
                  minWidth: "0",
                },
                "& .MuiIconButton-root": {
                  padding: "4px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "0.75rem",
                },
              }}
              fullWidth
              onChange={(event, newValue) => {
                handleChange(field.name, newValue);
              }}
              InputLabelProps={{ shrink: true, style: { fontSize: "0.75rem" } }}
              InputProps={{ style: { font: "small-caption" } }}
              options={["Create new", ...designations]}
              // getOptionLabel={(option) => {
              //   if (option === "Create new") {
              //     return (
              //       <Button
              //         size="small"
              //         style={{ color: "white" }}
              //         onClick={handleOpenDialog}
              //       >
              //         {option}
              //       </Button>
              //     );
              //   }
              //   return option;
              // }}
              renderOption={(props, option) => (
                <li {...props} style={{ fontFamily: 'Lucida Grande' }}
                >
                  {option === "Create new" ? (
                    <Button size="small" style={{ color: 'white' }} onClick={handleOpenDialog}>
                      {option}
                    </Button>
                  ) : (
                    option
                  )}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Designation" required
                placeholder="Select Designation"
                 InputProps={{
                  ...params.InputProps,
                  style: { fontFamily: 'Lucida Grande' },
                }}
                  InputLabelProps={{shrink:'true',
                    style: { fontFamily: 'Lucida Grande' },
                  }} />
              )}
              noOptionsText={
                <Button
                  size="small"
                  style={{ color: "white" }}
                  onClick={handleOpenDialog}
                >
                  create new
                </Button>
              }
            />
          ) : field.select && field.id === ASSOCIATE_COMPANY_FIELD_ID ? (
            <Autocomplete
              size="small"
              sx={{
                display: "inline-block",
                "& .MuiAutocomplete-inputRoot": {
                  fontSize: "0.75rem",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                },
                "& .MuiAutocomplete-input": {
                  paddingTop: "0",
                },
                "& .MuiInputBase-root": {
                  minWidth: "0",
                },
                "& .MuiIconButton-root": {
                  padding: "4px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "0.75rem",
                },
              }}
              fullWidth
              disabled
              onChange={(event, newValue) => {
                handleChange(field.name, company[0]);
              }}
              readOnly
              defaultValue={company[0]}
              InputLabelProps={{ shrink: true, style: { fontSize: "0.75rem" } }}
              InputProps={{ style: { font: "small-caption" } }}
              options={Companies}
              // getOptionLabel={(option) => option.name}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Company" InputProps={{
                  ...params.InputProps,
                  style: { fontFamily: 'Lucida Grande' },
                }}
                  InputLabelProps={{
                    style: { fontFamily: 'Lucida Grande' },
                  }}

                />
              )}
              noOptionsText={
                <IconButton onClick={handleOpenDialog}>
                  <Add /> Create New
                </IconButton>
              }
            />
          ) : (
            <TextField
              {...field}
              fullWidth
              key={key}
              required
              size="small"
              label={field.label}
              error={errors[field.name] ? true : false}
              helperText={errors?.[field.name]?.message}
              InputLabelProps={{ style: { font: 'small-caption', fontFamily: "Lucida Grande" } }}
              inputProps={{ style: { font: 'small-caption', fontFamily: "Lucida Grande" } }}
              onInput={(e) => {
                field.name === ASSOCIATE_MOBILE_FIELD_ID
                  ? (e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10))
                  : console.log("null");
              }}
              // InputProps={field.InputProps}
              defaultValue={
                formData && formData[field.name]
                  ? field.name === ASSOCIATE_DATE_OF_JOINING_FIELD_ID
                    ? moment(formData[field.name], "YYYY,MM,DD").format(
                      "YYYY-MM-DD"
                    )
                    : formData[field.name]
                  : ""
              }
              onChange={(event) => handleChange(field.name, event.target.value)}
            ></TextField>
          )}
        </>
      );
    } else {
      return <React.Fragment />;
    }
  };

  return (
    <>
      <GenericForm
        formData={formData}
        formFields={formFields}
        errors={errors}
        handleChange={handleChange}
        handleSubmit={handleSave}
        renderCustomInput={renderCustomInput}
        setIsDrawerOpen={setIsDrawerOpen}
        image={image}
        setImage={setImage}
        setLoading={setLoading}
        loading={loading}
        onError={onError}
      />
      <GenericDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        mapping={DESIGNATION_MAPPING}
        type={DIALOG_TYPE}
        onSuccess={onSuccess}
        onError={onError}
        fieldName={DIALOG_FIELD_NAME}
        setItems={setDesignations}
        items={designations}
      />
    </>
  );
};

export default Adduser;
