import { axiosInstance } from "../../../interceptors/AxiosInterceptor";

export function doGetTenants(page, sortBy, pageSize, handleSuccess, handleFailure, handleLoading) {
    handleLoading(true);
    axiosInstance.get(`/tenant/list?page=${page}&size=${pageSize}&sortBy=${sortBy}`)
        .then(res => {
            handleLoading(false);
            handleSuccess(res.data);
        })
        .catch(err => {
            handleLoading(false);
            if (err.response && err.response.data) {
                // application specific error                    
                handleFailure(err.response?.data);
            } else {
                // generic axios error
                handleFailure(err.message);
            }
        });
}
export function doAddProjects(data, handleSuccess, handleFailure, handleLoading) {
    handleLoading(true);
    axiosInstance.post(`/tenant/add/projects`, data)
        .then(res => {
            handleLoading(false);
            
            handleSuccess(res);
        })
        .catch(err => {
            handleLoading(false);
            if (err.response && err.response.data) {
                // application specific error                    
                handleFailure(err);
            } else {
                // generic axios error
                handleFailure(err);
            }
        });
}

