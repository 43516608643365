import { Box, CircularProgress, Grid, Link, Tooltip } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../../interceptors/AxiosInterceptor';

const UserScreenAppraisal = () => {

    const [filteredElements, setFilteredElements] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const CustomNameComponent = (props) => {
        const { firstname, lastname } = props.data.user;
        return (
            <Tooltip title="User Overview">
                <Link
                    sx={{ cursor: "pointer" }}
                    underline="hover"
                    onClick={() => {
                        navigate("/home/ratings/UserAppraisalForm", {
                            state: { type: "User", fullname: `${props.data.user.firstname} ${props.data.user.lastname}`, username: `${props.data.username}`, project: props.data.project, ratings: JSON.parse(props.data.ratings), comments: props.data.comments, startDate: moment(props.data.startDate).format("YYYY-MM-DD"), endDate: moment(props.data.endDate).format("YYYY-MM-DD") },
                        });
                    }}
                >
                    {firstname} {lastname}
                </Link>
            </Tooltip>
        );
    };

    const calculateRatingAverage = (props) => {
        const ratings = JSON.parse(props.data.ratings);
        let sum = 0;
        ratings.map(r => sum += r.rating)
        const ratingAvg = sum / ratings.length;
        return <>{ratingAvg.toFixed(2)}</>
    }


    const columnDefs = [
        {
            field: "user",
            headerName: "Associate Id",
            type: "number",
            valueGetter: (params) =>
                params?.data?.user ? params?.data?.user?.associateId : "",
            flex: 0.5
        },
        {
            headerName: "Associate Name",
            filter: true,
            cellRenderer: CustomNameComponent,
            width: 200
        },
        {
            field: "startDate",
            headerName: "Start Date",
            type: "text",
            valueFormatter: (startDate) =>
                moment(startDate.value, "YYYY,MM,DD").format("DD-MMM-YYYY"),
            flex: 0.5
        },
        {
            field: "endDate",
            headerName: "End Date",
            type: "text",
            valueFormatter: (startDate) =>
                moment(startDate.value, "YYYY,MM,DD").format("DD-MMM-YYYY"),
            flex: 0.5
        },
        {
            field: "comments",
            headerName: "Comments",
            type: "text",
            flex: 1,
            valueGetter: (params) => params.data.comments ? params.data.comments : "No Feedback Available"
        },
        {
            headerName: "Rating Avg.",
            cellEditorPopup: true,
            filter: true,
            cellRenderer: calculateRatingAverage,
            flex: 0.5
        },
    ];


    useEffect(() => {
        setLoading(true);
        if (location?.state?.type) {
            setFilteredElements(location.state.userRatings)
            setLoading(false)
        }
        else {
            axiosInstance.get("/user/getRatingsByUser").then(res => {
                setFilteredElements(res.data)
                setLoading(false)
            }).catch(() => {
                setLoading(false);
            })
        }

    }, [])
    return (
        loading ? (
            <Grid sx={{ textAlignLast: "center" }} item xs={12}>
                <Box style={{ paddingTop: "50px", paddingBottom: "40px" }}>
                    <CircularProgress />
                </Box>
            </Grid>) :
            <div
                className="ag-theme-alpine" // applying the grid theme
                style={{
                    height: 419,
                    width: "100%",
                    fontFamily: "Lucida Grande",
                }}
            >
                <AgGridReact
                    rowData={filteredElements}
                    columnDefs={columnDefs}
                    domLayout="normal"
                    defaultColDef={{
                        filter: true,
                        width: 120,
                        headerClass: "custom-header",
                    }}
                />
            </div>
    )
}

export default UserScreenAppraisal
