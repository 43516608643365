import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GenericTable from "../../../GenericTable";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import AddTask from "./AddTask";

import { Delete, ModeEdit } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { END_DATE_FORMAT, START_DATE_FORMAT } from "../constants/Constants";

const Task = (props) => {
  const { onSuccess, onError } = props;
  const navigate = useNavigate("");
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredTask, setFilteredTask] = useState([]);
  const [date, setDate] = useState(moment(new Date()).format("yyyy-MM-DD"));
  const [page, setPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const pageSize = 10;
  const totalPages = 0;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const type = "Tasks";
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [startDate, setStartDate] = useState(START_DATE_FORMAT)
  const [endDate, setEndDate] = useState(END_DATE_FORMAT)
  const [holidays, setHolidays] = useState([]);
  var hrs = 0;
  const displayAdd = true;

  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");

  console.log(startDateError, endDateError);


  // const handleStartDateChange = (e, type) => {
  //   if (type === "icon") {
  //     const newStartDate = moment(startDate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
  //     const newEndDate = moment(endDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
  //     setStartDate(newStartDate);
  //     setEndDate(newEndDate);
  //   }
  //   else {
  //     const value = e.target.value;
  //     setStartDate(value);
  //     setEndDateError("");
  //     if (value && endDate && value > endDate) {
  //       setStartDateError("Start date cannot be after end date");
  //     } else {
  //       setStartDateError("");
  //     }
  //   }
  // };

  // const handleEndDateChange = (e, type) => {
  //   if (type === "icon") {
  //     const newStartDate = moment(startDate).add(1, 'month').startOf('month').format('YYYY-MM-DD');
  //     const newEndDate = moment(endDate).add(1, 'month').endOf('month').format('YYYY-MM-DD');
  //     setStartDate(newStartDate);
  //     setEndDate(newEndDate);
  //   }
  //   else {
  //     const value = e.target.value;
  //     setEndDate(value);
  //     setStartDateError("");
  //     if (value && startDate && value < startDate) {
  //       setEndDateError("End date cannot be before start date");
  //     } else {
  //       setEndDateError("");
  //     }
  //   }
  // };

  const CustomButtonComponent = (row) => {
    return row?.data.status !== "SAVED" ? (
      <>
        <Tooltip title="Click To Edit">
          <IconButton
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "blue",
              },
            }}
            onClick={() => {
              if (row?.data.status === "SAVED") {
                setSelectedRow(row?.data);
                setIsDialogOpen(true);
              } else {
                navigate("/home/task/taskUpdate", {
                  state: { formData: row?.data },
                });
              }
            }}
          >
            <ModeEdit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Click to Delete">
          <IconButton
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "#e74c3c",
              },
            }}
            onClick={() => {
              setSelectedRow(row?.data);
              setIsDialogOpen(true);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </>
    ) : (
      <></>
    );
  };

  const header = [
    { id: "title", label: " Project name", type: "text" },
    { id: "taskName", label: "task name", type: "text" },
    { id: "description", label: "description", type: "text" },
    { id: "category", label: "category", type: "text" },
    { id: "startDate", label: "start date", type: "date" },
    { id: "endDate", label: "end date", type: "date" },
    { id: "estimatedHours", label: "Planned Hours", type: "text" },
  ];
  const colDefs = [
    // { field: "project", headerName: " Project name", filter: true, flex: 1, valueGetter: params => params.data.project ? params.data.project.title : '' },
    { field: "project", headerName: " Project Name", filter: true, width: 220 },
    { field: "taskName", headerName: "Task Name", filter: true, width: 220 },
    {
      field: "description",
      headerName: "Description",
      filter: true,
      width: 250,
    },
    { field: "category", headerName: "Category", filter: true, width: 180 },
    {
      field: "startDate",
      headerName: "Start Date",
      filter: true,
      valueFormatter: (startDate) =>
        moment(startDate.value, "YYYY,MM,DD").format("DD-MM-YYYY"),
    },
    {
      field: "endDate",
      headerName: "End Date",
      filter: true,
      valueFormatter: (endDate) =>
        moment(endDate.value, "YYYY,MM,DD").format("DD-MM-YYYY"),
    },
    {
      field: "estimatedHours",
      headerName: "Estimated Hours",
      filter: true,
      width: 150,
      valueFormatter: (p) => {
        if (p.value !== null) {
          return `${Math.floor(p.value / 3600)}:${Math.floor((p.value % 3600) / 60)}`
        }
        else {
          return `0`
        }
      }
    },
    {
      field: "Action",
      headerName: "Action",
      type: "text",
      cellRenderer: CustomButtonComponent,
      width: 120,
    },
  ];

  const dates = {
    startDate: startDate,
    endDate: endDate,
  };
  console.log(startDate, endDate);

  useEffect(() => {
    setLoading(true); 
    if ((startDateError === "" && endDateError === "") && (startDate !== "" && endDate !== "")) {
      // Check if searchTerm contains only spaces or is empty
      if (searchTerm.trim().length === 0) {
        if (searchTerm.length > 0) {
          // If searchTerm is only spaces, clear the filtered task list
          setFilteredTask([]);
          setLoading(false);
        } else {
          // Otherwise, fetch tasks normally
          axiosInstance
            .put(`/task/getAllByUser`, { startDate, endDate })
            .then((res) => {
              setTasks(res.data);
              setFilteredTask(res.data);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
  
          if (holidays.length === 0) {
            axiosInstance
              .put("/holiday/duration", dates)
              .then((res) => {
                setHolidays(res.data);
                props.setLoad(false);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      } else {
        // If tasks are already loaded, perform the search
        setFilteredTask(
          tasks.filter(
            (data) =>
              data?.["taskName"].toLowerCase().includes(searchTerm.toLowerCase()) ||
              data?.project?.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
        setLoading(false); // Ensure loading is stopped after filtering
      }
    } else {
      setLoading(false);
      setFilteredTask([]);
      setTasks([]);
    }
    
  }, [date, hrs, page, pageSize, searchTerm, startDate, endDate]);
  


  const toggleData = () => {
    navigate("/home/timecard/timesheet");
  };
  return (
    <>
      <GenericTable
        type={type}
        colDefs={colDefs}
        hrs={hrs}
        loading={loading}
        setLoading={setLoading}
        elements={tasks}
        filteredElements={filteredTask}
        setFilteredElements={setFilteredTask}
        fields={header}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        errorMessage={errorMessage}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        searchTerm={searchTerm}
        startDate={startDate}
        endDate={endDate}
        setSearchTerm={setSearchTerm}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setStartDateError={setStartDateError}
        setEndDateError={setEndDateError}
        // handleStartDateChange={handleStartDateChange}
        // handleEndDateChange={handleEndDateChange}
        drawerContent={
          <AddTask
            onError={onError}
            onSuccess={onSuccess}
            holidays={holidays}
            type={type}
            startDate={startDate}
            endDate={endDate}
            setErrorMessage={setErrorMessage}
            setIsDrawerOpen={setIsDrawerOpen}
            setElements={setTasks}
            setFilteredElements={setFilteredTask}
          />
        }
        totalWorkingHours={hrs}
        setDate={setDate}
        displayAdd={displayAdd}
        displayHeader={displayAdd}
        // displayPagination={displayAdd}
        toggleData={toggleData}
      />
      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "teal",
            color: "white",
            textAlign: "center",
            marginBottom: "8px",
            padding: "8px",
          }}
        >
          Warning!!!
        </DialogTitle>
        <DialogContent>
          {selectedRow.status === "SAVED"
            ? "Unable to edit/delete the task because you have already saved or submitted hours against the task in the timesheet."
            : "Are you sure you want to delete this task?"}
        </DialogContent>
        <DialogActions>
          {selectedRow.status === "SAVED" ? (
            <Button
              sx={{ color: "white" }}
              onClick={() => {
                setIsDialogOpen(false);
              }}
            >
              OK
            </Button>
          ) : (
            <>
              <Button
                size="small"
                sx={{ color: "white" }}
                onClick={() => {
                  setIsDialogOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                size="small"
                sx={{ color: "white" }}
                onClick={() => {
                  setLoading(true);
                  axiosInstance
                    .delete(`/task/delete?id=${selectedRow.taskId}`)
                    .then(() => {
                      axiosInstance.put("/task/getAllByUser", { startDate, endDate }).then(res => {
                        setTasks(res.data);
                        setFilteredTask(res.data);
                      }
                      ).catch(err => console.log(err))

                      setLoading(false);
                      onSuccess("Task deleted succesfully");
                    })
                    .catch((err) => {
                      console.log(err);
                      setLoading(false);
                    });
                  setIsDialogOpen(false);
                }}
              >
                Delete
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Task;
