import { AccountBalance, Edit, EventNote, FiberManualRecordRounded, ModeEdit, WorkOutline } from "@mui/icons-material";
import { Avatar, Box, Button, Card, CardContent, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, MenuItem, Stack, Tab, Tabs, TextField, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import ProjectOverview from "./ProjectOverview";
import ProjectResources from "./ProjectResources";

const ProjectDetails = (props) => {
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const { onError, onSuccess, user } = props;
  const totalProjects = location.state.projects;
  const totalAssociates = location.state.associates;
  const [projectStatus, setProjectStatus] = useState([]);
  const [projectCategories, setProjectCategories] = useState([]);
  const [errors, setErrors] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedAssociates, setSelectedAssociates] = useState(
    location?.state?.project?.projectManager || " "
  );
  const [open, setOpen] = useState(false);


  // const [resourcesCount, setResourcesCount] = useState(0);
  const inputRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let requests = [];

        if (
          location &&
          location.state &&
          location.state.project &&
          Object.keys(formData).length === 0
        ) {
          if (location.state?.project?.title?.length > 0) {
            requests.push(
              axiosInstance
                .get(`/project/title?title=${location.state.project.title}`)
                .then((projectData) => {
                  setFormData(projectData.data);
                })
                .catch((error) => {
                  console.error("Error fetching project data:", error);
                  throw error;
                })
            );
          }
        }

        if (projectStatus.length === 0) {
          requests.push(
            axiosInstance
              .get("/project/status")
              .then((statusData) => {
                setProjectStatus(statusData.data);
              })
              .catch((error) => {
                console.error("Error fetching project status:", error);
                throw error;
              })
          );
        }

        if (projectCategories.length === 0) {
          requests.push(
            axiosInstance
              .get("/project/category")
              .then((categoryData) => {
                setProjectCategories(categoryData.data);
              })
              .catch((error) => {
                console.error("Error fetching project categories:", error);
                throw error;
              })
          );
        }

        await Promise.all(requests);
      } catch (error) {
        console.error("Error in fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleEditClick = () => {
    inputRef.current.click();
  };

  function getBase64(file) {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file && file.size < 1024 * 1024) {
      const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!acceptedImageTypes.includes(file.type)) {
        props?.onError({ response: { data: 'Please upload a valid image file (jpg, png, gif)' } });
        event.target.value = '';
        return;
      }
      getBase64(file)
        .then((base64Version) => {
          axiosInstance
            .post("/project/change/logo", {
              title: formData?.title,
              logo: base64Version,
            })
            .then((res) => {
              setFormData({ ...formData, logo: base64Version });
              onSuccess(res?.data);
            });
        })
        .catch((err) => {
          onError(err);
          console.error("An error occured during base64 conversion.", err);
        });
    } else {
      const error = {
        message: "File size exceeds the 1MB limit",
      };
      onError(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAssociates(location?.state?.project?.projectManager);
  };
  return (
    <Card>
      <CardContent>
        {formData && (
          <Grid container spacing={1}>
            <Grid item xs={4} mt={6}>
              {loading ? (
                <Grid sx={{ textAlignLast: "center" }} item xs={12}>
                  <CircularProgress />
                </Grid>
              ) : (
                <Card>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        // marginBottom: "1rem",
                      }}
                    >
                      <Avatar
                        src={formData?.logo}
                        sx={{ width: 80, height: 80 }}
                      />
                      <Tooltip title="Edit Image">
                        <ModeEdit
                          style={{ cursor: "pointer" }}
                          onClick={handleEditClick}
                        />
                        <input
                          type="file"
                          id="profile-image-input"
                          hidden
                          ref={inputRef}
                          onChange={handleFileChange}
                        />
                      </Tooltip>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#008080",
                          fontFamily: "Luicda Grande"
                        }}
                      >
                        {formData?.title}
                      </Typography>
                      <Chip
                        key={formData.id}
                        label={formData.status}
                        size="medium"
                        sx={{
                          marginTop: "1rem",
                          backgroundColor: "F0F0F0",
                          fontFamily: "Luicda Grande",
                          color:
                            formData.status === "ACTIVE"
                              ? "#03C04A"
                              : "#e3242b",
                        }}
                      />
                      &nbsp; &nbsp;
                      <Chip
                        key={formData.id}
                        label={formData?.tenant?.name}
                        size="medium"
                        sx={{ fontFamily: "Luicda Grande" }}
                      />
                      &nbsp; &nbsp;
                      <Stack direction="row" width={'100%'} alignItems={'center'} justifyContent={'center'} marginLeft={'1.875rem'}>
                        <Chip
                          key={formData.id}
                          label={formData?.projectManager !== null ? formData?.projectManager?.fullname : "No Project Manager"}
                          size="medium"
                        />
                        <IconButton size="small" onClick={() => { setOpen(true); setIsDialogOpen(true) }}>
                          <Tooltip title="Edit Project Manager">
                          <Edit/>
                          </Tooltip>
                        </IconButton>
                      </Stack>
                      {isDialogOpen ? (
                        <Dialog open={open} onClose={handleClose}>
                          <DialogTitle>Edit Project</DialogTitle>
                          <Divider />
                          <DialogContent>
                            <>
                              <Grid item xs={12}>
                                <Typography variant="body1">{location?.state?.project?.tenant?.name}</Typography>
                              </Grid>
                              &nbsp;
                              <Grid>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  select
                                  size="small"
                                  label="Project Manager"
                                  value={selectedAssociates?.fullname}
                                  defaultValue={location?.state?.project?.projectManager}
                                  onChange={(e) => {
                                    const associate = location.state.associates.find(
                                      (associate) => associate.fullname === e.target.value
                                    );
                                    setSelectedAssociates(associate);
                                  }}
                                >
                                  {location.state.associates.map((associate) => (
                                    <MenuItem key={associate} value={associate.fullname}>
                                      {associate.fullname}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                            </>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={handleClose}
                              variant="contained"
                              size="small"
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => {
                                axiosInstance
                                  .post("/user/updateProject", {
                                    ...formData,
                                    projectManager: selectedAssociates
                                  })
                                  .then((response) => {
                                    // setEditMode(false);
                                    setFormData(response.data);
                                    onSuccess("Project updated successfully");
                                    setIsDialogOpen(false)
                                  })
                                  .catch((error) => {
                                    onError(error);
                                  });
                              }}
                            // disabled={roleChange && selectedAssociates.name === "Project Manager"}
                            >
                              Update
                            </Button>
                          </DialogActions>
                        </Dialog>
                      ) : (
                        <></>
                      )}
                      <Divider variant="fullWidth" color="#008080" />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                          // marginTop: "1rem",
                        }}
                      >
                        <Typography
                          onClick={() => setTabValue(1)}
                          sx={{
                            cursor: "pointer",
                            fontSize: "40px",
                            marginTop: "1rem",
                            onhover: "#",
                            fontFamily: "Luicda Grande"
                          }}
                        >
                          {formData?.users?.length}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FiberManualRecordRounded
                            sx={{ color: "#2832C2", marginRight: "0.5rem" }}
                          />
                          <Typography sx={{ color: "#546E7A", fontFamily: "Luicda Grande" }}>
                            Associates
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Grid item xs={8}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth"
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#029a9c",
                  },
                  "& .Mui-selected": {
                    color: "#000000  !important",
                  },
                }}
              >
                <Tab
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <EventNote sx={{ color: "#437676" }} />
                      <Typography sx={{ ml: 0.5 }}>Overview</Typography>
                    </Box>
                  }
                />
                <Tab
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <WorkOutline sx={{ color: "#437676" }} />
                      <Typography sx={{ ml: 0.5 }}>Associates</Typography>
                    </Box>
                  }
                />
                <Tab
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <AccountBalance sx={{ color: "#437676" }} />
                      <Typography sx={{ ml: 0.5 }}> Budget & Plan</Typography>
                    </Box>
                  }
                />
              </Tabs>
              <Card>
                <CardContent>
                  {tabValue === 0 && (
                    <ProjectOverview
                      associates={location.state.associates}
                      formData={formData}
                      setFormData={setFormData}
                      errors={errors}
                      setErrors={setErrors}
                      onError={onError}
                      onSuccess={onSuccess}
                      user={user}
                    />
                  )}
                  {tabValue === 1 && (
                    <ProjectResources
                      formData={formData}
                      setFormData={setFormData}
                      onError={onError}
                      onSuccess={onSuccess}
                      user={user}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default ProjectDetails;
