import { Grid } from "@mui/material";

import React from "react";
import ConsolidateTimesheetReport from "../../edvenswa.emportal.timesheet/pages/ConsolidateTimesheetReport";
import TimeCard from "../../edvenswa.emportal.timesheet/pages/TimeCard";

const Greet = React.lazy(() => import("./Greet"));

export default function UserHome(props) {

    const { user } = props;
    // const user = JSON.parse(
    //     sessionStorage.getItem(AUTH_SECURE_USER_DETAILS_LS_LEY)
    // );

    // const handleSkipClick = () => {
    //     setOpenDialog(false);
    //     user["jiraapitoken"] = "res.data.jiraapitoken";
    //     sessionStorage.setItem(AUTH_SECURE_USER_DETAILS_LS_LEY, JSON.stringify(user));
    //     window.location.replace("http://localhost:3000/#/home");

    // };
    return (
        <React.Fragment>
            {/* {user.roles[0] === "Admin" || ((user.roles[0] === "Project Manager" || user.roles[0] === "User") && user.jiraapitoken !== null) ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Greet {...props}></Greet>
                    </Grid>
                    <Grid item xs={12}>
                        <TimeCard />
                    </Grid>
                </Grid>
                :
                <Dialog open={openDialog} maxWidth="md" component={"form"} onSubmit={() => {
                    axiosInstance.post("/user/updatejiraapitoken", { jiraapitoken: value })
                        .then(res => {
                            user["jiraapitoken"] = res.data.jiraapitoken;
                            sessionStorage.setItem(AUTH_SECURE_USER_DETAILS_LS_LEY, JSON.stringify(user));
                            window.location.replace("http://dev.emportal.me:3000/#/home");
                        })
                }}>
                    <DialogTitle sx={{ fontWeight: 'bold', fontSize: '20px', color: "#008080" }}> JIRA API Token ALERT</DialogTitle>
                    <DialogContent>
                        <Grid container item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap gutterBottom >
                                    Get your jira api token by clicking on the following link...
                                </Typography>
                                <Typography variant="h6" noWrap gutterBottom>
                                    <Link href="https://id.atlassian.com/manage-profile/security/api-tokens" target="_blank" rel="noopener noreferrer" sx={{ cursor: 'pointer' }}>https://id.atlassian.com/manage-profile/security/api-tokens</Link>
                                </Typography>
                                <Typography variant="h6" noWrap>
                                    After redirection, click on "Create API Token" Provide the label  "Emportal"

                                </Typography>
                                <Typography variant="h6" noWrap>
                                    and paste the token below.
                                </Typography>

                            </Grid>
                            <Grid item xs={10} mt={2}>
                                <TextField fullWidth label="Paste your api token here..." required={true} onChange={(e) => {
                                    setValue(e.target.value)
                                }} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>

                        <Button
                            size="small"
                            variant="contained"
                            onClick={handleSkipClick}

                        >
                            Skip
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            type="submit"
                        >
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>} */}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Greet {...props} user={user}></Greet>
                </Grid>
                <Grid item xs={12}>
                    {user.roles[0] === "Admin" ? <ConsolidateTimesheetReport {...props} /> : <TimeCard />}
                </Grid>
            </Grid>

        </React.Fragment>
    )
}
