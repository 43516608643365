import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../../../guards/PrivateRoute";
import Layout from "../../edvenswa.emportal.layout/Layout";
import { COMPANY_DETAILS_PATH, COMPANY_PATH } from "../constants/Constants";
import TenantComponent from "../pages/Tenant";
// const TenantComponent = React.lazy(() => import("../pages/Tenant"));
const TenantDetailsComponent = React.lazy(() => import("../pages/TenantDetails"));


export const TENANT_ROUTES = [
    <Route path={COMPANY_PATH} element={
        <PrivateRoute>
            <Layout component={TenantComponent} path={COMPANY_PATH} title="Company"></Layout>
        </PrivateRoute>
    } key={"TENANT_ROUTE"}></Route>,
    <Route path={COMPANY_DETAILS_PATH} element={
        <PrivateRoute>
            <Layout component={TenantDetailsComponent}  path={COMPANY_DETAILS_PATH} title="Company Details"></Layout>
        </PrivateRoute>}
        key={"TENANT_DETAILS_ROUTE"}>
    </Route>
];