import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { AUTH_SECURE_USER_DETAILS_LS_LEY } from "../../edvenswa.emportal.auth/constants/Constants";
import { END_DATE_FORMAT, START_DATE_FORMAT } from "../constants/Constants";

const MyTimeCards = (props) => {
  console.log(props);
  const [res, setRes] = useState([]);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const navigate = useNavigate();
  const page = 1
  const pageSize = 10;
  const startOfWeek = moment(date)
    .startOf("week")
    .add(1, "day")
    .format("YYYY-MM-DD");
  const endOfWeek = moment(date)
    .endOf("week")
    .add(1, "day")
    .format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(START_DATE_FORMAT);
  const [endDate, setEndDate] = useState(END_DATE_FORMAT);
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const user = JSON.parse(
    sessionStorage.getItem(AUTH_SECURE_USER_DETAILS_LS_LEY)
  );
  const role = user.roles[0];
  const [loading, setLoading] = useState(false);

  const handleStartDateChange = (e, type) => {
    if (type === "icon") {
      const newStartDate = moment(startDate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      const newEndDate = moment(endDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
    else {
      const value = e.target.value;
      setStartDate(value);
      setEndDateError("");
      if (value && endDate && value > endDate) {
        setStartDateError("Start date cannot be after end date");
      } else {
        setStartDateError("");
      }
    }
  };

  const handleEndDateChange = (e, type) => {
    if (type === "icon") {
      const newStartDate = moment(startDate).add(1, 'month').startOf('month').format('YYYY-MM-DD');
      const newEndDate = moment(endDate).add(1, 'month').endOf('month').format('YYYY-MM-DD');
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
    else {
      const value = e.target.value;
      setEndDate(value);
      setStartDateError("");
      if (value && startDate && value < startDate) {
        setEndDateError("End date cannot be before start date");
      } else {
        setEndDateError("");
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .put(`/timesheet/getbyUser?page=${page}&size=${pageSize}`, {
        startDate,
        endDate,
      })
      .then((res) => {
        const sortedData = res.data.sort((a, b) => {
          return new Date(b.startDate) - new Date(a.startDate);
        });
        setRes(sortedData);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [date, page, startDate, endDate]);


  // const CustomButtonComponent = (props) => {
  //   console.log(props.data);
  //   return (
  //     <Stack
  //       direction={"row"}
  //       mt={1}
  //       sx={{ display: "flex", alignItems: "center" }}
  //     >
  //       {/* <Chip
  //         sx={{
  //           fontSize: 17,
  //           color: "white",
  //           backgroundColor: "#029a9c",
  //         }}
  //         label={
  //           <Typography
  //             variant="body2"
  //             style={{ fontSize: "0.8rem", color: "white" }}
  //           >
  //             {"View"}
  //           </Typography>
  //         }
  //         variant="contained"
  //         size="small"
  //         onClick={() => {
  //           handleClick(props.data);
  //         }}
  //       /> */}

  //       {role === "Admin" ? (
  //         <Chip
  //           sx={{
  //             fontSize: 17,
  //             color: "white",
  //             backgroundColor: "#029a9c",
  //           }}
  //           label={
  //             <Typography
  //               variant="body2"
  //               style={{ fontSize: "0.8rem", color: "white" }}
  //             >
  //               {"Approve"}
  //             </Typography>
  //           }
  //           variant="contained"
  //           size="small"
  //           onClick={() => {
  //             handleClick1(props.data);
  //           }}
  //         />
  //       ) : (
  //         <></>
  //       )}
  //     </Stack>
  //   );
  // };

  const CustomStatusComponent = (row) => {
    const partially_approved_partially_rejects = ((row.data.status !== "APPROVED" && JSON.parse(row.data.tasks).some((data) => data.status === "SUBMITTED")) &&
      (row.data.status !== "PENDING" && JSON.parse(row.data.tasks).some((data) => data.status === "PENDING")))
    if (partially_approved_partially_rejects) {
      return "PARTIALLY APPROVED AND PARTIALLY REJECTED";
    }
    else if (row.data.status !== "APPROVED" && JSON.parse(row.data.tasks).some((data) => data.status === "SUBMITTED")) {
      return "PARTIALLY APPROVED";
    }
    else if (row.data.status !== "PENDING" && JSON.parse(row.data.tasks).some((data) => data.status === "PENDING")) {
      return "PARTIALLY REJECTED";
    }
    else {
      return `${row.data.status}`
    }
  };


  const CustomNameComponent = (row) => {
    return (
      <Tooltip title="View Timesheet">
        <Link
          sx={{ cursor: "pointer" }}
          underline="hover"
          onClick={() => {
            navigate("/home/timecard/timesheet", {
              state: { timesheet: row.data },
            });
          }}
        >
          {row.data.associatename}
        </Link>
      </Tooltip>
    );
  };


  const colDefs = [
    {
      field: "associate",
      headerName: "Associate Id",
      filter: true,
      flex: 0.5,


      // valueGetter: (params) =>
      //   params.data.associate ? params.data.associate.associateId : "",
    },
    {
      field: "associatename",
      headerName: "Associate Name",
      filter: true,
      flex: 1,
      cellRenderer: CustomNameComponent,
    },
    // { field: "category", headerName: "Category", filter: true, flex: 1 },
    {
      field: "startDate",
      headerName: "Start date",
      filter: true,
      flex: 0.7,
      valueFormatter: (startDate) =>
        moment(startDate.value, "YYYY,MM,DD").format("DD-MM-YYYY"),
    },
    {
      field: "endDate",
      headerName: "End date",
      filter: true,
      flex: 0.7,
      valueFormatter: (endDate) =>
        moment(endDate.value, "YYYY,MM,DD").format("DD-MM-YYYY"),
    },
    {
      field: "totalWorkingHours",
      headerName: "Hours",
      filter: true,
      flex: 0.5,
      valueFormatter: (endDate) =>
        endDate.value / 3600,
    },
    {
      field: "status",
      headerName: "Status",
      filter: true,
      flex: 1,
      cellRenderer: CustomStatusComponent
    }
  ];

  return (
    <>
      {
        <Box>
          <Grid container item xs={12} spacing={2}>
            {role === "Admin" ? (
              <Grid item xs={6}>
                <TextField
                  type="date"
                  value={date}
                  size="small"
                  onChange={(e) => setDate(e.target.value)}
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          {
            <Grid container item xs={12}>
              &nbsp; &nbsp;
              {user.roles[0] === "Project Manager" ? (
                <Grid
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    // alignItems: "center",
                  }}
                >
                  <Typography mt={1} sx={{ color: "#016466", fontSize: '17px', fontFamily: "Lucida Grande" }}>
                    My Timecards
                  </Typography>
                  &nbsp; &nbsp;
                  <Tooltip title="click to go to previous month">
                    <IconButton onClick={(e) => handleStartDateChange(e, "icon")}>
                      <ArrowBackIosIcon
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                  <TextField
                    type="date"
                    value={startDate}
                    label="Start Date"
                    size="small"
                    // error={!!startDateError}
                    // helperText={startDateError}
                    error={
                      startDate &&
                        new Date(startDate) > new Date(endDate)
                        ? "Start date cannot be after end date"
                        : ""
                    }
                    helperText={
                      startDate &&
                        new Date(startDate) > new Date(endDate)
                        ? "Start date cannot be after end date"
                        : ""
                    }
                    inputProps={{
                      style: { font: "small-caption", fontFamily: "Lucida Grande" },
                      max: moment(new Date()).endOf('month').format("YYYY-MM-DD")
                    }}
                    onChange={handleStartDateChange}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '16px', fontFamily: "Lucida Grande" },
                    }}
                  />
                  &nbsp; &nbsp;
                  <TextField
                    type="date"
                    value={endDate}
                    label="End Date"
                    size="small"
                    // error={!!endDateError}
                    // helperText={endDateError}
                    error={
                      startDate &&
                        new Date(endDate) < new Date(startDate)
                        ? "End date cannot be before start date"
                        : ""
                    }
                    helperText={
                      startDate &&
                        new Date(endDate) < new Date(startDate)
                        ? "End date cannot be before start date"
                        : ""
                    }
                    inputProps={{
                      style: { font: "small-caption", fontFamily: "Lucida Grande" },
                      max: moment(new Date()).endOf('month').format("YYYY-MM-DD")
                    }}
                    onChange={handleEndDateChange}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: '16px', fontFamily: "Lucida Grande" },
                    }}
                  />
                  <Tooltip title="click to go to next month">
                    <IconButton onClick={(e) => handleEndDateChange(e, "icon")}>
                      <ArrowForwardIosIcon
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  My Timecards &nbsp; &nbsp;
                </Grid>
              )}
            </Grid>
          }

          {loading ? (
            <Grid sx={{ textAlignLast: "center" }} item xs={12}>
              <Box style={{ paddingTop: "50px", paddingBottom: "40px" }}>
                <CircularProgress />
              </Box>
            </Grid>
          ) : res.length === 0 ? (
            <Grid sx={{ textAlignLast: "center" }} item xs={12}>
              <Box style={{ paddingTop: "50px", paddingBottom: "40px" }}>
                <Typography

                  style={{ fontFamily: "Lucida Grande" }}
                >
                  No Timecards to show.
                </Typography>
              </Box>
            </Grid>
          ) : (
            <div
              className="ag-theme-alpine" // applying the grid theme
              style={{ height: 380, marginTop: "20px", fontFamily: "Lucida Grande" }} // the grid will fill the size of the parent container
            >
              <AgGridReact rowData={res} columnDefs={colDefs} domLayout="normal" // Set domLayout to "normal" for fixed headers
                // style="width: 500px; height: 500px;"
                defaultColDef={{
                  filter: true,
                  resizable: true,
                  width: 150,
                  headerClass: "custom-header",
                }} />
            </div>
          )}
          {/* <Grid
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
            />
          </Grid> */}
          {/* <Snackbar
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(!snackbarOpen)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={3000}
          >
            <Alert severity={severity}>{message}</Alert>
          </Snackbar> */}
        </Box>
      }
    </>
  );
};

export default MyTimeCards;
