import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import { AUTH_SECURE_USER_DETAILS_LS_LEY, FRONT_END_URL } from "../constants/Constants";

export function doLogin(data, token, handleSuccess, handleFailure, handleLoading) {
    handleLoading(true);
    axiosInstance.post('/auth/signin', data)
        .then(res => {
            handleLoading(false);
            sessionStorage.setItem(AUTH_SECURE_USER_DETAILS_LS_LEY, JSON.stringify(res.data));
            if (token) {
                localStorage.setItem("jiratoken", token)
            }

            handleSuccess(res.data);
        })
        .catch(err => {
            handleLoading(false);
            if (err.response && err.response.data) {
                handleFailure(err.response?.data);
            } else {
                handleFailure(err.message);
            }
        });
}

export function doGenerateOTP(data) {
    return axiosInstance.post('/auth/generate/otp?channel=email', data);
}

export function doSignup(data, handleSuccess, handleFailure, handleLoading) {
    handleLoading(true);
    axiosInstance.post('/auth/signup', data)
        .then(res => {
            handleLoading(false);
            handleSuccess(res.data);
        })
        .catch(err => {
            handleLoading(false);
            if (err.response && err.response.data) {
                handleFailure(err.response?.data);
            } else {
                handleFailure(err.message);
            }
        });
}

export function doResetPassword(data, handleSuccess, handleFailure, handleLoading) {
    handleLoading(true);
    axiosInstance.post('/auth/reset', data)
        .then(res => {
            handleLoading(false);
            handleSuccess(res.data);
        })
        .catch(err => {
            handleLoading(false);
            if (err.response && err.response.data) {
                handleFailure(err.response?.data);
            } else {
                handleFailure(err.message);
            }
        });
}


export function doLogout() {
    sessionStorage.removeItem(AUTH_SECURE_USER_DETAILS_LS_LEY);
    localStorage.removeItem("companies")
    localStorage.removeItem("projects")
    localStorage.removeItem("token")
    window.location.reload(FRONT_END_URL)
}