import React from "react";
import { Route } from "react-router-dom";
import Layout from "../../edvenswa.emportal.layout/Layout";
import PrivateRoute from "../../../guards/PrivateRoute";

const AnalyticsComponent = React.lazy(() => import("../pages/Analytics"));

export const ANALAYTICS_ROUTES = [
    <Route path="/home/analytics"
        element={
            <PrivateRoute>
                <Layout component={AnalyticsComponent}></Layout>
            </PrivateRoute>
        }
        key={"ANALYTICS_ROUTE"}
    />
];
